import React from 'react';

const OrderSafetyRegulations = () => {
	const style = {
		fontSize: '0.75rem',
		width: '100%',
		lineHeight: '1.25',
		backgroundColor: '#f4f4f4',
		padding: '.25rem .5rem',
		color: '#868889',
		borderRadius: '2px',
		marginTop: '8px',
	};

	return <div style={style}>Bitte die gegebenenfalls abweichenden Arbeitsschutzbestimmungen vor Ort beachten.</div>;
};

export default OrderSafetyRegulations;
