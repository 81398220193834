import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, FormFeedback, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrder } from '../../../../reducers/OrderOverviewSlice';
import { toast, Bounce } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Loader } from 'react-loaders';
import { fetchAllDocumentsForOrder, uploadDocumentForOrder, deleteDocumentForOrder } from '../../../Services/DocumentHandler';
import DataTable from 'react-data-table-component';
import TooltipItem from '../../Common/TooltipItem';
import NoDataComponent from '../../Common/NoDataComponent';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { API_DOWNLOAD_ORDER_DOCUMENT } from '../../API/endpoints';
import LoadingDataComponent from '../../Common/LoadingDataComponent';
import { useNavigate, useLocation } from 'react-router-dom';

const OrderDocuments = () => {
	const dispatch = useDispatch();
	const selectedOrder = useSelector(getSelectedOrder);
	const [loading, setLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState('');
	const [selectedFileError, setSelectedFileError] = useState('');
	const [caption, setCaption] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [documents, setDocuments] = useState([]);
	const [file, setFile] = useState(null);
	const [showConfirm, setShowConfirm] = useState(false);
	const axios = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();
	const [isUploading, setIsUploading] = useState(false);

	const loadDocuments = () => {
		setLoading(true);
		dispatch(fetchAllDocumentsForOrder({ axios, selectedOrder: selectedOrder })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}
			setDocuments(response.payload.data);
			setLoading(false);
		});
	};
	useEffect(() => {
		//loadAllOrderDocuments()
		loadDocuments();
	}, []);

	const handleFileSelection = (e) => {
		const selectedFile = e.target.files[0];

		if (!!selectedFile) {
			if (selectedFile.size > 4194304) {
				setSelectedFileError('Die ausgewählte Datei ist zu groß. (max. 4MB)');
				return;
			}
			setSelectedFile(e.target.files[0]);
			setSelectedFileError('');
		} else {
			setSelectedFileError('');
			setSelectedFile(null);
		}
	};

	const resetForm = () => {
		setShowModal(false);
		setSelectedFile(null);
		setCaption('');
	};

	const uploadDocument = () => {
		if (isUploading) {
			return;
		}
		setIsUploading(true);
		const formData = new FormData();

		formData.append('dokument', selectedFile, selectedFile.name);
		formData.append('bemerkung', caption);

		dispatch(uploadDocumentForOrder({ axios, selectedOrder: selectedOrder, form: formData }))
			.then((response) => {
				// Hier könnten auch META Daten überprüft werden um den Erfolg des Requests sicherzustellen
				if (response.payload?.status === 'ok') {
					// Erfolgsmeldung anzeigen (toast) / Modal schließen / Thumbs zum Auftrag neu laden!
					toast(response.payload?.message, {
						transition: Bounce,
						closeButton: true,
						autoClose: 5000,
						position: 'top-right',
						type: 'success',
					});
					resetForm();
					loadDocuments();
				} else if (response.payload?.status === 'error') {
					// Fehlermeldung aus message lesen
					toast(response.payload?.message, {
						transition: Bounce,
						closeButton: true,
						autoClose: 5000,
						position: 'top-right',
						type: 'error',
					});
				}

				if (!response.payload) {
					if (response.error.message.indexOf('413')) {
						toast('Die Datei ist zu groß und kann nicht hochgeladen werden.', {
							transition: Bounce,
							closeButton: true,
							autoClose: 5000,
							position: 'top-right',
							type: 'warning',
						});
					}
				}
				setIsUploading(false);
			})
			.catch((reason) => {
				toast('Die Datei konnte nicht hochgeladen werden', {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
				setIsUploading(false);
			});
	};

	const handleModalShow = () => {
		setShowModal(true);
	};

	const showConfirmDialog = (doc) => {
		setFile(doc);
		setShowConfirm(true);
	};

	const filename = (file) => {
		const fileNamePart = file.substring(0, file.lastIndexOf('.'));
		const extension = file.substring(file.lastIndexOf('.'));
		const fileName = fileNamePart.substring(0, fileNamePart.lastIndexOf('-'));

		return fileName + extension;
	};

	const downloadDocument = async (doc) => {
		const url = API_DOWNLOAD_ORDER_DOCUMENT + doc.id;
		await axios({
			url: url,
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename(doc.dateiname));
			document.body.appendChild(link);
			link.click();
		});
	};

	const hashTag = '#';
	const columns = [
		{
			name: 'Dateiname',
			id: 'filename',
			selector: (row) => {
				return (
					<TooltipItem
						key={'k' + row.id}
						id={'i' + row.id}
						placement='top'
						tooltip={<Fragment>Dokument herunterladen</Fragment>}
						element={
							<a
								href={hashTag}
								onClick={(e) => {
									e.preventDefault();
									downloadDocument(row);
								}}
								style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}
							>
								<i className='me-2 lnr-download' style={{ fontSize: '1rem' }} />
								{row.dateiname.substring(0, row.dateiname.lastIndexOf('-'))}
							</a>
						}
					/>
				);
			},
			sortable: false,
			compact: false,
		},
		{
			name: 'Datum',
			id: 'date',
			selector: (row) => row.datum,
			sortable: false,
			compact: false,
		},
		{
			name: 'Hochgeladen von',
			id: 'erstelltVon',
			selector: (row) => row.erstelltVon,
			sortable: false,
			compact: false,
		},
		{
			name: 'Aktionen',
			id: 'aktionen',
			selector: (row) => (
				<TooltipItem
					id={'doc-' + row.id}
					placement='top'
					tooltip={'Dokument löschen'}
					element={
						<Button id={'doc-' + row.id} className='btn-icon btn-icon-only' color='link' onClick={() => showConfirmDialog(row)}>
							<i className={`lnr-trash btn-icon-wrapper`} />
						</Button>
					}
				/>
			),
			sortable: false,
			compact: false,
		},
	];

	const resetConfirm = () => {
		setFile(null);
		setShowConfirm(false);
	};

	const deleteDocument = () => {
		dispatch(deleteDocumentForOrder({ axios, selectedOrder: selectedOrder, document: file.id })).then((response) => {
			if (response.payload.status === 'ok') {
				// Erfolgsmeldung anzeigen (toast) / Modal schließen / Thumbs zum Auftrag neu laden!
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				resetConfirm();
				loadDocuments();
			} else if (response.payload.status === 'error') {
				// Fehlermeldung aus message lesen
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	return (
		<LoadingOverlay
			tag='div'
			active={loading}
			styles={{
				overlay: (base) => ({
					...base,
					background: '#fff',
					opacity: 0.5,
				}),
			}}
			spinner={<Loader color='#ffffff' active type={'line-scale-pulse-out'} />}
		>
			<div>
				<h4>Dokumente</h4>
				<hr />
				<Row>
					<Col sm='12' xs='12' md='auto'>
						<Button className='mb-3 me-2 btn-icon-vertical btn-transition' color='primary' onClick={() => handleModalShow()}>
							<i className='pe-7s-file btn-icon-wrapper'> </i>
							Dokument <br />
							hochladen
						</Button>
					</Col>
					<Col>
						<DataTable
							data={documents}
							columns={columns}
							responsive
							noDataComponent={<NoDataComponent />}
							progressPending={loading}
							progressComponent={<LoadingDataComponent />}
						/>
					</Col>
				</Row>
			</div>
			{!!showModal && (
				<Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
					<ModalHeader toggle={() => setShowModal(!showModal)}>Dokument hochladen</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label for='foto'>Dokument hochladen</Label>
							<Input
								type='file'
								name='dokument'
								id='dokument'
								onChange={(e) => handleFileSelection(e)}
								accept='.pdf,.zip,.bin'
								invalid={selectedFileError !== ''}
							/>
							{selectedFileError && <FormFeedback>{selectedFileError}</FormFeedback>}
						</FormGroup>
						<FormGroup>
							<Label for='bemerkung'>Bezeichnung</Label>
							<Input type='text' name='bemerkung' value={caption} onChange={(e) => setCaption(e.target.value)} accept='image/png, image/jpeg' />
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color='primary' disabled={!!selectedFileError || !selectedFile} onClick={() => uploadDocument()}>
							Hochladen
						</Button>{' '}
						<Button color='link' onClick={() => resetForm()}>
							abbrechen
						</Button>
					</ModalFooter>
				</Modal>
			)}
			{!!showConfirm && (
				<Modal isOpen={showConfirm} toggle={() => setShowConfirm(!showConfirm)}>
					<ModalHeader toggle={() => setShowConfirm(!showConfirm)}>Dokument löschen</ModalHeader>
					<ModalBody>
						Soll das Dokument "{!!file ? file.dateiname.substring(0, file.dateiname.lastIndexOf('-')) : ''}" wirklich gelöscht werden?
					</ModalBody>
					<ModalFooter>
						<Button color='danger' onClick={() => deleteDocument()}>
							Löschen
						</Button>
						<Button color='link' onClick={() => resetConfirm()}>
							abbrechen
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</LoadingOverlay>
	);
};

export default OrderDocuments;
