import './Common.scss';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from '../Hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { API_INVALIDATE_ROUTE } from '../API/endpoints';
import { resetTechnicianFilter } from '../../../reducers/TechnicianOverviewSlice';
import { resetOrderFilter } from '../../../reducers/OrderOverviewSlice';
import { resetBuilderFilter } from '../../../reducers/BuilderOverviewSlice';

const Logout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const axios = useAxiosPrivate();
	const { setAuth, persist } = useAuth();

	const logout = async () => {
		dispatch(resetTechnicianFilter());
		dispatch(resetBuilderFilter());
		dispatch(resetOrderFilter());
		const response = await axios.get(API_INVALIDATE_ROUTE, {
			withCredentials: true,
		});

		return response;
	};

	useEffect(() => {
		setAuth({});
		try {
			if (persist) {
				dispatch(logout()).then((response) => {
					navigate('/login');
				});
			} else {
				navigate('/login');
			}
		} catch (error) {
			navigate('/login');
		}
	}, []);

	return <div>Logging out...</div>;
};

export default Logout;
