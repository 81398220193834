export const validateInputInTwoTimeFields = (from, to, errorSetter, message) => {
	if (from !== '' && to === '') {
		errorSetter(message);
		return message;
	} else if (from === '' && to !== '') {
		errorSetter(message);
		return message;
	} else if (from === '' && to === '') {
		errorSetter('');
		return null;
	}
};

export const validateTimeIntegrityForFields = (from, to, errorSetter, msg, gte = false) => {
	// Wenn beide Felder angegeben sind, dann muss die Endzeit größer sein als die Anfangszeit
	if (from !== '' && to !== '') {
		const beginn = getValueOfTimeField(from);
		const ende = getValueOfTimeField(to);
		if (!gte) {
			if (ende < beginn) {
				errorSetter(msg);
				return msg;
			}
		} else {
			if (ende <= beginn) {
				errorSetter(msg);
				return msg;
			}
		}
	}
	return null;
};

export const validateFollowUpTimeIntegrityForFields = (from, to, errorSetter, msg) => {
	// Hier muss TO vor FROM liegen
	// Wenn beide Felder angegeben sind, dann muss die Endzeit größer sein als die Anfangszeit
	if (from !== '' && to !== '') {
		const beginn = getValueOfTimeField(from);
		const ende = getValueOfTimeField(to);
		if (beginn < ende) {
			errorSetter(msg);
			return msg;
		}
	}
	return null;
};

export const isInputValid = (timestring) => {
	if (!timestring) {
		return false;
	}

	const parts = timestring.split(':');

	if (Number.parseInt(parts[0]) <= 23 && Number.parseInt(parts[1]) <= 59) {
		return true;
	}
	return false;
};

export const getValueOfTimeField = (timestring) => {
	const parts = timestring.split(':');

	return parts[0] * 60 + parts[1] * 1;
};

export const scrollSmoothlyToElement = (elementID) => {
	const element = document.getElementById(elementID);
	const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
	const yOffset = -120;
	window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

export const smallDisplay = window.innerWidth < 1080;

export const sortiereStundenbuchungNachDatum = (a, b) => {
	let dateA = !!a.aD ? a.aD.split('.').reverse().join('/') : !!a.rD ? a.rD.split('.').reverse().join('/') : null;
	let dateB = !!b.aD ? b.aD.split('.').reverse().join('/') : !!b.rD ? b.rD.split('.').reverse().join('/') : null;

	if (!dateA || !dateB) {
		return 0;
	}

	if (!!a.aD && !!a.aV) {
		dateA = dateA + ' ' + a.aV;
	} else if (!!a.rD && !!a.rV) {
		dateA = dateA + ' ' + a.rV;
	}

	if (!!b.aD && !!b.aV) {
		dateB = dateB + ' ' + b.aV;
	} else if (!!b.rD && !!b.rV) {
		dateB = dateB + ' ' + b.rV;
	}

	dateA = new Date(dateA);
	dateB = new Date(dateB);

	return dateA.getTime() - dateB.getTime();
};

export const sortiereAuftragspositionenNachDatum = (a, b) => {
	let dateA = a.datum.split('.').reverse().join('/');
	let dateB = b.datum.split('.').reverse().join('/');

	if (!!a.hinfahrtBeginn) {
		dateA = dateA + ' ' + a.hinfahrtBeginn;
	} else if (!!a.arbeitsbeginn) {
		dateA = dateA + ' ' + a.arbeitsbeginn;
	} else if (!!a.rueckfahrtBeginn) {
		dateA = dateA + ' ' + a.rueckfahrtBeginn;
	}

	if (!!b.hinfahrtBeginn) {
		dateB = dateB + ' ' + b.hinfahrtBeginn;
	} else if (!!a.arbeitsbeginn) {
		dateB = dateB + ' ' + b.arbeitsbeginn;
	} else if (!!a.rueckfahrtBeginn) {
		dateB = dateB + ' ' + b.rueckfahrtBeginn;
	}

	dateA = new Date(dateA);
	dateB = new Date(dateB);

	return dateA.getTime() - dateB.getTime();
};
