import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_DOCUMENT_ROUTE, API_ORDER_DOCUMENT_ROUTE } from '../Components/API/endpoints';

export const fetchAllDocumentsForOrder = createAsyncThunk('orders/fetchAllDocumentsForOrder', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}

	if (!!arg.document) {
		params += '/' + arg.document;
	}

	const response = await axios.get(API_DOCUMENT_ROUTE + params);
	return response.data;
});

export const uploadDocumentForOrder = createAsyncThunk('orders/uploadDocumentForOrder', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}

	if (!!arg.document) {
		params += '/' + arg.document;
	}
	const formData = arg.form;
	const response = await axios.post(API_DOCUMENT_ROUTE + params, formData);

	return response.data;
});

export const deleteDocumentForOrder = createAsyncThunk('orders/deleteDocumentForOrder', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}

	if (!!arg.document) {
		params += '/' + arg.document;
	}
	const response = await axios.delete(API_DOCUMENT_ROUTE + params);

	return response.data;
});

export const fetchAllOrderDocumentsForOrder = createAsyncThunk('orders/fetchAllOrderDocumentsForOrder', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}

	const response = await axios.get(API_ORDER_DOCUMENT_ROUTE + params);
	return response.data;
});
