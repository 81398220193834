import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_SEND_ORDER_ASSIGNMENTS_CHANGED, API_SEND_ORDER_DOCUMENTS_ROUTE, API_SEND_TECHNICIAN_ASSIGNMENTS_CHANGED } from '../Components/API/endpoints';

export const sendOrderDocuments = createAsyncThunk('orders/sendOrderDocuments', async (arg) => {
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}
	const axios = arg.axios;
	const form = arg.form;

	const response = await axios.post(API_SEND_ORDER_DOCUMENTS_ROUTE + params, form);
	return response.data;
});

export const sendTechnicianAssignmentsChangedMail = createAsyncThunk('mail/sendTechnicianAssignmentsChangedMail', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SEND_TECHNICIAN_ASSIGNMENTS_CHANGED, arg);
	return response.data;
});

export const sendOrderAssignmentsChangedMail = createAsyncThunk('mail/sendOrderAssignmentsChangedMail', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SEND_ORDER_ASSIGNMENTS_CHANGED, arg);
	return response.data;
});
