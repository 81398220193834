import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert, Card, CardHeader, CardBody, Button, Row, Col, ListGroup, ListGroupItem, Input, InputGroup, InputGroupText } from 'reactstrap';
import { fetchOrdersForAssignment, switchOrderAssignmentsForTechnicians } from '../../Services/AssignmentHandler';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import TechnicianAssignOrdersRow from './TechnicianAssignOrdersRow';
import { loadTechnician } from '../../../reducers/TechnicianOverviewSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Loader } from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { toast, Bounce } from 'react-toastify';
import { sendTechnicianAssignmentsChangedMail } from '../../Services/MailHandler';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import HeaderBacklink from '../Common/HeaderBacklink';
import LoadingDataComponent from '../Common/LoadingDataComponent';

const TechnicianAssignOrders = (props) => {
	const dispatch = useDispatch();
	const { technikerID } = useParams();
	const [availableOrders, setAvailableOrders] = useState([]);
	const [changedAssignments, setChangedAssignments] = useState([]);
	const [technikerDetails, setTechnikerDetails] = useState(null);
	const [loadingTechnician, setLoadingTechnician] = useState(false);
	const [loadingOrders, setLoadingOrders] = useState(false);
	const [search, setSearch] = useState('');
	const axios = useAxiosPrivate();

	const reloadOrders = () => {
		setLoadingOrders(true);
		dispatch(fetchOrdersForAssignment({ axios, technician: technikerID })).then((thunk) => {
			if (thunk.payload.status === 'ok') {
				setAvailableOrders(thunk.payload.data);
				setChangedAssignments([]);
			}
			setLoadingOrders(false);
		});
	};

	useEffect(() => {
		setLoadingTechnician(true);
		reloadOrders();
		dispatch(loadTechnician({ axios, technician: technikerID })).then((response) => {
			if (response.payload.status === 'ok') {
				const data = response.payload.data;
				setTechnikerDetails(data);
			} else {
				setTechnikerDetails(null);
			}
			setLoadingTechnician(false);
		});
	}, []);

	const handleChange = (checked, order) => {
		order.zugewiesen = checked;

		const alreadyAssigned = changedAssignments.find((t) => t.id === order.id);

		dispatch(switchOrderAssignmentsForTechnicians({ axios, technician: technikerID, order: order.id, type: checked ? 1 : 0 })).then((response) => {
			if (response.payload.data.status === 'ok') {
				let assignments = changedAssignments.slice(0);
				if (!alreadyAssigned) {
					assignments.push(order);
				} else {
					assignments = changedAssignments.filter((a) => a.id !== order.id);
				}
				setChangedAssignments(assignments);

				toast(response.payload.data.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
			} else {
				toast(response.payload.data.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	const assignmentChangesText = () => {
		if (changedAssignments.length === 0) {
			return 'Keine Änderungen vorgenommen';
		} else if (changedAssignments.length === 1) {
			return 'Eine Zuweisung wurde geändert';
		}

		return `${changedAssignments.length} Zuweisungen wurden geändert`;
	};

	const handleSendEMail = () => {
		const submitData = [];
		changedAssignments.forEach((order) => {
			submitData.push({ order: order.id, assigned: order.zugewiesen });
		});

		dispatch(sendTechnicianAssignmentsChangedMail({ axios, technician: technikerID, changes: submitData })).then((response) => {
			if (response.payload.status === 'ok') {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				reloadOrders();
			} else {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}
						<PageTitle
							heading='Auftragszuweisung'
							subheading='Weisen Sie dem Techniker eine beliebige Anzahl an Aufträgen zu'
							icon='pe-7s-user icon-gradient bg-tempting-azure'
							headerActions={<HeaderBacklink backlinkTooltipContent={'Zurück zur Technikerübersicht'} backlinkTarget={'/errichter/techniker'} />}
						/>
						<LoadingOverlay
							tag='div'
							active={loadingTechnician}
							styles={{
								overlay: (base) => ({
									...base,
									background: '#fff',
									opacity: 0.5,
								}),
							}}
							spinner={<Loader color='#ffffff' active type={'line-scale-pulse-out'} />}
						>
							<Row>
								<Col xs={12} sm={12} lg={4} xl={4}>
									<Card className='mb-3'>
										<CardHeader>Techniker</CardHeader>
										<CardBody>
											{!technikerDetails && <Alert color='danger'>Technikerdaten konnten nicht geladen werden</Alert>}
											{!!technikerDetails && (
												<Fragment>
													<h4>
														{technikerDetails.vorname} {technikerDetails.nachname}
													</h4>
													<span className='d-flex align-items-center'>
														<FontAwesomeIcon icon={faPhone} className='me-2 mb-1' />
														{technikerDetails.telefon}
													</span>
													<span className='d-flex align-items-center'>
														<FontAwesomeIcon icon={faEnvelope} className='me-2' />
														<a href={'mailto:' + technikerDetails.email}>{technikerDetails.email}</a>
													</span>
												</Fragment>
											)}
										</CardBody>
									</Card>
									{!!technikerDetails && !!technikerDetails.zuweisungen && (
										<Card className='mb-3'>
											<CardHeader>Aufträge</CardHeader>
											<CardBody>
												<ListGroup>
													<ListGroupItem className='d-flex justify-content-between ps-0 pe-0' style={{ border: '0' }}>
														<span>Offene Aufträge</span>
														<span className='badge bg-primary me-1' style={{ width: '40px', textAlign: 'center' }}>
															{technikerDetails.zuweisungen.offen}
														</span>
													</ListGroupItem>
													<ListGroupItem className='d-flex justify-content-between ps-0 pe-0' style={{ border: '0' }}>
														<span>In Arbeit</span>
														<span className='badge bg-info me-1' style={{ width: '40px', textAlign: 'center' }}>
															{technikerDetails.zuweisungen.inArbeit}
														</span>
													</ListGroupItem>
													<hr />
													<ListGroupItem className='d-flex justify-content-between ps-0 pe-0' style={{ border: '0' }}>
														<span>Offene Aufträge (extern)</span>
														<span className='badge bg-alternate me-1' style={{ width: '40px', textAlign: 'center' }}>
															{technikerDetails.zuweisungen.offenExtern}
														</span>
													</ListGroupItem>
													<ListGroupItem className='d-flex justify-content-between ps-0 pe-0' style={{ border: '0' }}>
														<span>In Arbeit (extern)</span>
														<span className='badge bg-success me-1' style={{ width: '40px', textAlign: 'center' }}>
															{technikerDetails.zuweisungen.inArbeitExtern}
														</span>
													</ListGroupItem>
												</ListGroup>
											</CardBody>
										</Card>
									)}
								</Col>
								<Col xs={12} sm={12} lg={8} xl={8}>
									<Card>
										<CardHeader>
											<Col>Auftragszuweisung</Col>
											<Col className='d-flex align-items-center justify-content-center text-alternate me-2' xs='auto'>
												{assignmentChangesText()}
											</Col>
											{!!changedAssignments.length && (
												<Col xs='auto'>
													<Button color='alternate' size='sm' onClick={(e) => handleSendEMail()}>
														Techniker per E-Mail informieren
													</Button>
												</Col>
											)}
										</CardHeader>
										<CardBody>
											{!loadingOrders && (
												<Fragment>
													<Row>
														<Col xs={12}>
															<InputGroup style={{ marginBottom: '2rem' }}>
																<Input
																	onChange={(e) => {
																		setSearch(e.target.value);
																	}}
																	value={search}
																	maxLength={30}
																	placeholder={'Filtern ...'}
																/>
																<InputGroupText>
																	<span style={{ cursor: 'pointer' }} onClick={(e) => setSearch('')}>
																		Filter löschen
																	</span>
																</InputGroupText>
															</InputGroup>
														</Col>
													</Row>
													<Row className='mb-3'>
														<Col xs='auto'>
															<strong>Zuweisung</strong>
														</Col>
														<Col>
															<strong>Auftrag</strong>
														</Col>
													</Row>
													<div className='scroll-area-lg mb-3'>
														<PerfectScrollbar>
															{!!availableOrders &&
																availableOrders
																	.filter((t) => {
																		if (t.auftragsnummer.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
																			return true;
																		}
																		if (t.ort.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
																			return true;
																		}
																		if (t.ort.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
																			return true;
																		}
																		return false;
																	})
																	.map((order, index) => {
																		return (
																			<TechnicianAssignOrdersRow
																				key={order.id}
																				changeable={order.aenderbar}
																				order={order}
																				handleChange={handleChange}
																			/>
																		);
																	})}
														</PerfectScrollbar>
													</div>
												</Fragment>
											)}

											{loadingOrders && <LoadingDataComponent />}
										</CardBody>
									</Card>
								</Col>
							</Row>
						</LoadingOverlay>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default TechnicianAssignOrders;
