import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	API_BUILDER_ASSIGNMENT,
	API_ORDER_ASSIGNMENT,
	API_SAVE_ASSIGNMENT,
	API_SAVE_BUILDER_ASSIGNMENT,
	API_TECHNICIAN_ASSIGNMENT,
} from '../Components/API/endpoints';

export const fetchTechniciansForAssignment = createAsyncThunk('order/fetchTechniciansForAssignment', async (arg) => {
	const axios = arg.axios;
	const params = '/' + arg.order;
	const response = await axios.get(API_TECHNICIAN_ASSIGNMENT + params);
	return response.data;
});

export const fetchOrdersForAssignment = createAsyncThunk('order/fetchTechniciansForAssignment', async (arg) => {
	const axios = arg.axios;
	const params = '/' + arg.technician;
	const response = await axios.get(API_ORDER_ASSIGNMENT + params);
	return response.data;
});

export const switchOrderAssignmentsForTechnicians = createAsyncThunk('order/switchOrderAssignments', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SAVE_ASSIGNMENT, arg);
	return response;
});

export const fetchBuildersForAssignment = createAsyncThunk('order/fetchBuildersForAssignment', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_BUILDER_ASSIGNMENT, { builder: arg.user });
	return response.data;
});

export const switchBuilderAssignment = createAsyncThunk('order/switchBuilderAssignments', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SAVE_BUILDER_ASSIGNMENT, arg);
	return response;
});
