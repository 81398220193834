import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';

const NoDataComponent = () => {
	return (
		<div className='mt-3 mb-3 pt-2 pb-2 text-center' style={{ color: '#c4c4c4' }}>
			<FontAwesomeIcon icon={faTable} size='2x' />
			<p>Keine Einträge gefunden</p>
		</div>
	);
};

export default NoDataComponent;
