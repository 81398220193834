import { axiosPrivate } from '../API/axios';
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import useAuth from './useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

const useAxiosPrivate = () => {
	const refresh = useRefreshToken();
	const { auth, persist } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const requestInterceptor = axiosPrivate.interceptors.request.use(
			(config) => {
				if (!config.headers['Authorization']) {
					config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		const responseInterceptor = axiosPrivate.interceptors.response.use(
			(response) => response,
			async (error) => {
				const prevRequest = error?.config;
				if (error?.response.status === 401 && !prevRequest?.sent && persist) {
					prevRequest.sent = true;
					try {
						const newAccessToken = await refresh();
						prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
					} catch (error) {
						navigate('/login', { state: { from: location }, replace: true });
					}

					return axiosPrivate(prevRequest);
				} else if (error?.response.status === 403) {
					navigate('/verboten', { state: { from: location }, replace: true });
				}

				return Promise.reject(error);
			}
		);

		return () => {
			axiosPrivate.interceptors.request.eject(requestInterceptor);
			axiosPrivate.interceptors.response.eject(responseInterceptor);
		};
	}, [auth, refresh]);

	return axiosPrivate;
};

export default useAxiosPrivate;
