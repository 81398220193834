import './OrderStatus.scss';

const OrderStatus = (props) => {
	const className = 'orderStatus';

	const hasStartedDate = !!props.data.wBgn;
	const hasStartedBy = !!props.data.wBU;
	const isDoneByTechnician = props.data.done;
	const isDoneEntirely = props.data.cDone;

	if (!hasStartedBy && !hasStartedDate && !isDoneEntirely && !isDoneByTechnician) {
		return <div className={`${className} ${className}--undone`}>Nicht begonnen</div>;
	} else if (!!hasStartedBy && !!hasStartedDate && !isDoneByTechnician && !isDoneEntirely) {
		return (
			<div className={className}>
				<div className={`${className} ${className}--orderBegun`}>Arbeit begonnen</div>
				<div className={`${className} ${className}--startedBy`}>{props.data.wBgn}</div>
			</div>
		);
	}

	if (!!isDoneEntirely) {
		return (
			<div className={className}>
				<div className={`${className} ${className}--startedDate`}>Abgeschlossen</div>
				<div className={`${className} ${className}--startedBy`}>{props.data.cDoneDate}</div>
			</div>
		);
	}

	return (
		<div className={className}>
			<div className={`${className} ${className}--doneByTechnician`}>Fertig lt. Techniker</div>
			<div className={`${className} ${className}--startedBy`}>{props.data.doneDate}</div>
		</div>
	);
};

export default OrderStatus;
