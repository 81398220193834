import { Route, Navigate, Routes } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders';

import { ToastContainer } from 'react-toastify';
import Login from '../Components/Login/Login';
import Impressum from '../Components/Login/Static/Impressum';
import Privacy from '../Components/Login/Static/Privacy';
import BuilderDashboard from '../Dashboards/BuilderDashboard';
import PersistLogin from '../Components/Common/PersistLogin';
import Logout from '../Components/Login/Logout';
import Register from '../Components/Login/Register';
import Forbidden from '../Components/Login/Forbidden';

/*
const UserPages = lazy(() => import('../../DemoPages/UserPages'));
const Applications = lazy(() => import('../../DemoPages/Applications'));
const Dashboards = lazy(() => import('../../DemoPages/Dashboards'));

const Widgets = lazy(() => import('../../DemoPages/Widgets'));
const Elements = lazy(() => import('../../DemoPages/Elements'));
const Components = lazy(() => import('../../DemoPages/Components'));
const Charts = lazy(() => import('../../DemoPages/Charts'));
const Forms = lazy(() => import('../../DemoPages/Forms'));
const Tables = lazy(() => import('../../DemoPages/Tables'));
*/

const TechnicianDashboard = lazy(() => import('../Dashboards/TechnicianDashboard'));
const AdminDashboard = lazy(() => import('../Dashboards/AdminDashboard'));

const MainApp = () => {
	return (
		<Fragment>
			<Routes>
				<Route path='/login' element={<Login />} />
				<Route path='/logout' element={<Logout />} />
				<Route path='/impressum' element={<Impressum />} />
				<Route path='/datenschutz' element={<Privacy />} />
				<Route path='/registrierung/:code' element={<Register />} />
				<Route path='/verboten' element={<Forbidden />} />

				<Route path='*' element={<Navigate replace to='/login' />} />
				<Route element={<PersistLogin />}>
					{/* <Route element={<ProtectedRoute isAllowed={isTechniker || isIBSTechniker} />}> */}
					<Route
						path='/techniker/*'
						element={
							<Suspense
								fallback={
									<div className='loader-container'>
										<div className='loader-container-inner'>
											<div className='text-center'>
												<Loader type='ball-pulse-rise' />
											</div>
											<h6 className='mt-5'>Wird geladen...</h6>
										</div>
									</div>
								}
							>
								<TechnicianDashboard />
							</Suspense>
						}
					/>
					{/* </Route>
					<Route element={<ProtectedRoute isAllowed={isAdmin} />}> */}
					<Route
						path='/admin/*'
						element={
							<Suspense
								fallback={
									<div className='loader-container'>
										<div className='loader-container-inner'>
											<div className='text-center'>
												<Loader type='ball-pulse-rise' />
											</div>
											<h6 className='mt-5'>Wird geladen...</h6>
										</div>
									</div>
								}
							>
								<AdminDashboard />
							</Suspense>
						}
					/>
					{/* </Route>
					<Route element={<ProtectedRoute isAllowed={isErrichter || isIBSErrichter} />}> */}
					<Route
						path='/errichter/*'
						element={
							<Suspense
								fallback={
									<div className='loader-container'>
										<div className='loader-container-inner'>
											<div className='text-center'>
												<Loader type='ball-pulse-rise' />
											</div>
											<h6 className='mt-5'>Wird geladen...</h6>
										</div>
									</div>
								}
							>
								<BuilderDashboard />
							</Suspense>
						}
					/>
					{/* </Route> */}
				</Route>
			</Routes>
			<ToastContainer />
		</Fragment>
	);
};

export default MainApp;
