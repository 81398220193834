import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Input, Form, FormFeedback, Button, FormGroup, Label } from 'reactstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import {
	loadTechnician,
	saveTechnician,
	checkUsername,
	getSelectedTechnician,
	fetchBuilderTechnicians,
	loadBuilders,
} from '../../../reducers/TechnicianOverviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Bounce } from 'react-toastify';
import cx from 'classnames';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';

const CreateTechnicianForm = () => {
	//const history = useHistory();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [parent, setParent] = useState('');
	const [parents, setParents] = useState([]);
	const [parentError, setParentError] = useState('');

	const [vorname, setVorname] = useState('');
	const [nachname, setNachname] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [telefon, setTelefon] = useState('');
	const [benutzername, setBenutzername] = useState('');
	const [passwort, setPasswort] = useState('');
	const [aktiv, setAktiv] = useState(true);
	const [passwortWiederholung, setPasswortWiederholung] = useState('');
	const [benutzernameError, setBenutzernameError] = useState('');
	const [passwortError, setPasswortError] = useState('');
	const [vornameError, setVornameError] = useState('');
	const [nachnameError, setNachnameError] = useState('');

	const { technikerID } = useParams();
	const axios = useAxiosPrivate();
	const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&\\+\\-*])(?=.{8,})');
	// RFC 2822 standard email validation
	const emailRegex = new RegExp(
		/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
	);

	useEffect(() => {
		dispatch(loadBuilders({ axios })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}

			if (response.payload?.status === 'ok') {
				setParents(response.payload.data);
				setParent(response.payload?.data[0].id);
			} else {
				setParents([]);
			}
		});
		if (technikerID !== '0') {
			dispatch(loadTechnician({ axios, technician: technikerID })).then((response) => {
				if (response?.error?.message.indexOf('401') >= 0) {
					navigate('/login', { state: { from: location }, replace: true });
				}

				if (response.payload.status === 'ok') {
					const data = response.payload.data;
					setVorname(data.vorname);
					setNachname(data.nachname);
					setBenutzername(data.benutzer);
					setAktiv(data.aktiv);
					setEmail(data.email);
					setTelefon(data.telefon);
					setPasswort('F4k3p4$$w0rD!');
					setPasswortWiederholung('F4k3p4$$w0rD!');
					setParent(data.parent);
				} else {
					toast('Fehler beim Laden des Benutzers', {
						transition: Bounce,
						closeButton: true,
						autoClose: 5000,
						position: 'top-right',
						type: 'error',
					});
				}
			});
		}
	}, []);

	const handleClick = () => {
		setAktiv(!aktiv);
	};

	const validate = async () => {
		const errors = [];
		if (parent === '') {
			setParentError('Wählen Sie einen Errichter aus');
			errors.push('Wählen Sie einen Errichter aus');
		} else {
			setParentError('');
		}

		if (benutzername === '') {
			setBenutzernameError('Geben Sie einen Benutzernamen ein');
			errors.push('Geben Sie einen Benutzernamen ein');
		} else {
			setBenutzernameError('');
		}

		if (vorname === '') {
			setVornameError('Geben Sie einen Vornamen ein');
			errors.push('Geben Sie einen Vornamen ein');
		} else {
			setVornameError('');
		}

		if (nachname === '') {
			setNachnameError('Geben Sie einen Nachnamen ein');
			errors.push('Geben Sie einen Nachnamen ein');
		} else {
			setNachnameError('');
		}

		if (email !== '' && !emailRegex.test(email)) {
			setEmailError('Geben Sie eine gültige E-Mail Adresse ein');
			errors.push('Geben Sie eine gültige E-Mail Adresse ein');
		} else {
			setEmailError('');
		}

		if (passwort === '') {
			setPasswortError('Geben Sie ein Passwort ein');
			errors.push('Geben Sie ein Passwort ein');
		} else if (passwort !== passwortWiederholung) {
			setPasswortError('Die Passwörter müssen übereinstimmen');
			errors.push('Die Passwörter müssen übereinstimmen');
		} else if (passwort !== 'F4k3p4$$w0rD!' && !strongRegex.test(passwort)) {
			setPasswortError('Geben Sie ein gültiges Passwort ein');
			errors.push('Geben Sie ein gültiges Passwort ein');
		} else {
			setPasswortError('');
		}

		return !errors.length;
	};

	const submitFormData = async () => {
		const form = document.techniker_anlegen;
		let formData = new FormData(form);
		formData.append('zugangAktiv', aktiv);

		const valid = await validate();
		if (valid) {
			dispatch(checkUsername({ axios, username: benutzername, technicianID: technikerID })).then((response) => {
				const payload = response.payload;

				if (payload.status === 1) {
					dispatch(saveTechnician({ axios, technician: technikerID, form: formData })).then((response) => {
						if (response?.error?.message.indexOf('401') >= 0) {
							navigate('/login', { state: { from: location }, replace: true });
						}
						if (response.payload.status === 'ok') {
							toast(response.payload.message, {
								transition: Bounce,
								closeButton: true,
								autoClose: 5000,
								position: 'top-right',
								type: 'success',
							});
							dispatch(fetchBuilderTechnicians({ axios })).then((response) => {
								if (response?.error?.message.indexOf('401') >= 0) {
									navigate('/login', { state: { from: location }, replace: true });
								}
							});
							navigate('/errichter/techniker');
						} else {
							toast(response.payload.message, {
								transition: Bounce,
								closeButton: true,
								autoClose: 5000,
								position: 'top-right',
								type: 'error',
							});
						}
					});
				} else {
					if (payload.status === 0) {
						setBenutzernameError(payload.message);
					} else {
						toast(payload.message, {
							transition: Bounce,
							closeButton: true,
							autoClose: 5000,
							position: 'top-right',
							type: 'error',
						});
					}
				}
			});
		}
	};

	const heading = () => {
		return technikerID === '0' ? 'Techniker anlegen' : 'Techniker bearbeiten';
	};

	const subheading = () => {
		return technikerID === '0'
			? 'Füllen Sie das Formular aus um einen Techniker Zugang anzulegen'
			: 'Bearbeiten Sie die Daten im Formular und speichern Sie Ihre gemachten Änderungen';
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}

						<PageTitle heading={heading()} subheading={subheading()} icon='pe-7s-user icon-gradient bg-tempting-azure' />
						<Form
							onSubmit={(e) => {
								e.preventDefault();
								submitFormData();
								return false;
							}}
							id='techniker_anlegen'
							name='techniker_anlegen'
						>
							<Row>
								<Col md='8'>
									<Card className='main-card mb-3'>
										<CardHeader>Füllen Sie das Formular aus</CardHeader>
										<CardBody>
											<Row>
												<Col md='6'>
													<FormGroup>
														<Row>
															<Col>
																<Label>Zugehörigkeit</Label>
																<Input
																	type='select'
																	id='basisBenutzer'
																	name='basisBenutzer'
																	onChange={(e) => {
																		setParent(e.target.value);
																	}}
																	value={parent}
																	invalid={parentError !== ''}
																>
																	<option value=''>Zugehörigen Errichter auswählen</option>
																	{parents.map((e) => {
																		return (
																			<option key={e.id} value={e.id}>
																				{e.name}
																			</option>
																		);
																	})}
																</Input>
																{parentError !== '' && <FormFeedback>{parentError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col md='6'>
													<FormGroup>
														<Row>
															<Col>
																<Label>Vorname</Label>
																<Input
																	type='text'
																	id='vorname'
																	name='vorname'
																	onChange={(e) => {
																		setVorname(e.target.value);
																	}}
																	value={vorname}
																	invalid={vornameError !== ''}
																	maxLength={30}
																/>
																{vornameError !== '' && <FormFeedback>{vornameError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>Nachname</Label>
																<Input
																	type='text'
																	id='nachname'
																	name='nachname'
																	onChange={(e) => {
																		setNachname(e.target.value);
																	}}
																	value={nachname}
																	invalid={nachnameError !== ''}
																	maxLength={30}
																/>
																{nachnameError !== '' && <FormFeedback>{nachnameError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>E-Mail</Label>
																<Input
																	type='text'
																	id='email'
																	name='email'
																	onChange={(e) => {
																		setEmail(e.target.value);
																	}}
																	value={email}
																	maxLength={500}
																	invalid={emailError !== ''}
																/>
																{emailError !== '' && <FormFeedback>{emailError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>Telefon</Label>
																<Input
																	type='text'
																	id='telefon'
																	name='telefon'
																	onChange={(e) => {
																		setTelefon(e.target.value);
																	}}
																	value={telefon}
																	maxLength={50}
																/>
															</Col>
														</Row>
													</FormGroup>
												</Col>
												<Col md='6'>
													<FormGroup>
														<Row>
															<Col>
																<Label>Benutzername</Label>
																<Input
																	type='text'
																	id='benutzername'
																	name='benutzername'
																	onChange={(e) => {
																		setBenutzername(e.target.value);
																	}}
																	value={benutzername}
																	invalid={benutzernameError !== ''}
																	maxLength={40}
																/>
																{benutzernameError !== '' && <FormFeedback>{benutzernameError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>Passwort</Label>
																<Input
																	type='password'
																	id='passwort'
																	name='passwort'
																	onChange={(e) => {
																		setPasswort(e.target.value);
																	}}
																	value={passwort}
																	invalid={passwortError !== ''}
																	maxLength={255}
																/>
																{passwortError !== '' && <FormFeedback>{passwortError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>Passwort wiederholen</Label>
																<Input
																	type='password'
																	id='passwortWiederholung'
																	name='passwortWiederholung'
																	onChange={(e) => {
																		setPasswortWiederholung(e.target.value);
																	}}
																	value={passwortWiederholung}
																	invalid={passwortError !== ''}
																	maxLength={255}
																/>
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>Zugang aktiv</Label>
																<div>
																	<div
																		className='switch has-switch me-2 mb-2 me-2'
																		data-on-label='Aktiv'
																		data-off-label='Inaktiv'
																		onClick={handleClick}
																	>
																		<div
																			className={cx('switch-animate', {
																				'switch-on': aktiv,
																				'switch-off': !aktiv,
																			})}
																		>
																			<input type='checkbox' name='zugangAktiv' />
																			<span className='switch-left bg-success'>Ja</span>
																			<label>&nbsp;</label>
																			<span className='switch-right bg-success'>Nein</span>
																		</div>
																	</div>
																</div>
															</Col>
														</Row>
													</FormGroup>
												</Col>
											</Row>
										</CardBody>
										<CardFooter className='justify-content-end align-items-center pt-4'>
											<FormGroup>
												<Row>
													<Col>
														<Button color='primary' type='submit'>
															Speichern
														</Button>
														<Link className='btn btn-sm btn-link' to='/errichter/techniker'>
															Abbrechen
														</Link>
													</Col>
												</Row>
											</FormGroup>
										</CardFooter>
									</Card>
								</Col>
								<Col md='4'>
									<Card>
										<CardHeader>Techniker {technikerID === '0' ? 'anlegen' : 'bearbeiten'}</CardHeader>
										<CardBody>
											{technikerID === '0' ? (
												<>
													Erstellen Sie einen Benutzerzugang für das ibs-Auftragsportal für ihren Techniker. <br />
													<br />
													Ein Techniker kann sich auf der Plattform http://ibs-auftragsportal.de anmelden und erhält Zugriff auf alle
													Aufträge die ihm von Ihnen zugewiesen wurden. Mit dem Zugang wird der Techniker in die Lage versetzt die
													Dokumentation für den Auftrag digital durchzuführen.
													<br />
													<br />
													Neue Passwörter müssen unserer Richtlinie entsprechen. Das bedeutet, dass ein neues Passwort mindestens 8
													Zeichen lang sein muss und mindestens eine Zahl, je einen Groß- und Kleinbuchstaben sowie ein Sonderzeichen
													enthalten muss.
												</>
											) : (
												<>
													Bearbeiten Sie den Benutzerzugang Ihren Wünschen entsprechend.
													<br />
													<br />
													Wenn Sie das Passwort für den Techniker beibehalten möchten, dann lassen Sie das Kennwort unverändert. Die
													im Passwort-Feld eingetragenen Zeichen entsprechen nicht dem Kennwort, welches für den Techniker vergeben
													wurde.
													<br />
													<br />
													Neue Passwörter müssen unserer Richtlinie entsprechen. Das bedeutet, dass ein neues Passwort mindestens 8
													Zeichen lang sein muss und mindestens eine Zahl, je einen Groß- und Kleinbuchstaben sowie ein Sonderzeichen
													enthalten muss.
												</>
											)}
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Form>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default CreateTechnicianForm;
