import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	API_ADMIN_DASHBOARD_STATISTICS,
	API_ADMIN_DASHBOARD_TEST,
	API_BUILDER_DASHBOARD_STATISTICS,
	API_BUILDER_TECHNICIAN_ASSIGNMENT_DASHBOARD_STATISTICS,
	API_TECHNICIAN_DASHBOARD_STATISTICS,
} from '../Components/API/endpoints';

export const fetchTechnicianDashboardStatistics = createAsyncThunk('technician/dashboardStatistics', async (arg) => {
	const axios = arg.axios;
	try {
		const response = await axios.get(API_TECHNICIAN_DASHBOARD_STATISTICS);
		return response.data;
	} catch (error) {
		throw error;
	}
});

export const fetchAdminDashboardStatistics = createAsyncThunk('admin/dashboardStatistics', async (arg) => {
	const axios = arg.axios;
	try {
		const response = await axios.get(API_ADMIN_DASHBOARD_STATISTICS);
		return response.data;
	} catch (error) {
		throw error;
	}
});

export const fetchAdminDashboardLastStartedOrders = createAsyncThunk('admin/dashboardStatisticsLastStartedOrders', async (arg) => {
	const axios = arg.axios;
	try {
		const response = await axios.get(API_ADMIN_DASHBOARD_TEST);
		return response.data;
	} catch (error) {
		throw error;
	}
});

export const fetchBuilderDashboardStatistics = createAsyncThunk('builder/dashboardStatistics', async (arg) => {
	const axios = arg.axios;
	try {
		const response = await axios.get(API_BUILDER_DASHBOARD_STATISTICS);
		return response.data;
	} catch (error) {
		throw error;
	}
});

export const fetchBuilderOrderAssignmentDashboardStatistics = createAsyncThunk('builder/builderOrderAssignmentDashboardStatistics', async (arg) => {
	const axios = arg.axios;
	try {
		const response = await axios.get(API_BUILDER_TECHNICIAN_ASSIGNMENT_DASHBOARD_STATISTICS);
		return response.data;
	} catch (error) {
		throw error;
	}
});
