import React, { Fragment } from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { setOrder } from '../../../reducers/OrderOverviewSlice';
import { useDispatch } from 'react-redux';
import { API_DOWNLOAD_ALL_DOCUMENTS_FOR_ORDER } from '../API/endpoints';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { toast, Bounce } from 'react-toastify';
import { smallDisplay } from '../Technician/Components/Services';

const OrderActions = (props) => {
	const dispatch = useDispatch();
	const axios = useAxiosPrivate();

	const setSelectedOrder = () => {
		dispatch(setOrder(props.order.id));
	};

	const downloadDocument = async (order) => {
		const url = API_DOWNLOAD_ALL_DOCUMENTS_FOR_ORDER + order;
		await axios({
			url: url,
			method: 'GET',
			responseType: 'blob', // important
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Dokumente_Auftrag_' + order + '.zip');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((reason) => {
				const type = reason.response.status === 400 ? 'warning' : 'error';
				const message =
					reason.response.status === 400
						? 'Keine Dokumente zum herunterladen verfügbar'
						: 'Das angeforderte Dokument konnte nicht heruntergeladen werden.';
				toast(message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: type,
				});
			});
	};

	if (!smallDisplay) {
		return (
			<UncontrolledButtonDropdown direction='left' className='mb-2 me-2'>
				<Link className='btn btn-sm btn-primary' to='/errichter/auftrag/ansehen' onClick={setSelectedOrder}>
					Auftrag ansehen
				</Link>

				<DropdownToggle size='sm' className='dropdown-toggle-split' caret color='primary' />
				<DropdownMenu>
					<DropdownItem>
						<Link className='btn btn-sm btn-link' to='/errichter/auftrag/techniker/zuweisen' onClick={setSelectedOrder}>
							Techniker zuweisen
						</Link>
					</DropdownItem>
					<DropdownItem>
						<Link className='btn btn-sm btn-link' onClick={() => downloadDocument(props.order.id)}>
							Dokumente herunterladen
						</Link>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledButtonDropdown>
		);
	}

	return (
		<Fragment>
			<Link className='btn btn-sm btn-primary me-2' to='/errichter/auftrag/techniker/zuweisen' onClick={setSelectedOrder}>
				<i className='pe-7s-users btn-icon-wrapper' style={{ fontSize: '20px' }} />
			</Link>
			<Link className='btn btn-sm btn-primary' onClick={() => downloadDocument(props.order.id)}>
				<i className='pe-7s-download btn-icon-wrapper' style={{ fontSize: '20px' }} />
			</Link>
		</Fragment>
	);
};

export default OrderActions;
