export const convertTimeStringToMinutes = (time) => {
	const parts = time.split(':');

	if (parts.length !== 2) {
		return -1;
	}

	return Number.parseInt(parts[1]) + 60 * Number.parseInt(parts[0]);
};

export const isTimeStringBeforeAnother = (beforeTime, afterTime) => {
	const timeA = convertTimeStringToMinutes(beforeTime);
	const timeB = convertTimeStringToMinutes(afterTime);

	if (timeA === -1 || timeB === -1) {
		// Ungültige Zeiten kann man nicht vergleichen
		return null;
	}

	return timeA < timeB;
};

export const calculateTimeInMinutesBetweenTimeStrings = (firstTime, secondTime) => {
	const timeA = convertTimeStringToMinutes(firstTime);
	const timeB = convertTimeStringToMinutes(secondTime);

	if (timeA === -1 || timeB === -1) {
		// Ungültige Zeiten kann man nicht vergleichen
		return null;
	}

	return timeB - timeA;
};

export const convertMinutesIntoTimeString = (minutes) => {
	const hours = Math.floor(minutes / 60);
	const mins = minutes % 60;
	return (hours < 10 ? '0' + hours : hours) + ':' + (mins < 10 ? '0' + mins : mins);
};
