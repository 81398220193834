import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	API_BEGIN_FOG_UNIT_CHECK,
	API_FINISH_FOG_UNIT_CHECK,
	API_FOG_UNIT_CHECKS,
	API_FOG_UNIT_CHECKS_RESET,
	API_FOG_UNIT_COMMENT_UPDATE,
	API_FOG_UNIT_DATA_FOR_ORDER,
	API_FOG_UNIT_EDIT,
} from '../App/Components/API/endpoints';

const initialState = {
	total: 0,
};

export const beginFogUnitCheck = createAsyncThunk('fogunit/beginCheck', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_BEGIN_FOG_UNIT_CHECK, { nID: arg.nID, aID: arg.aID });
	return response.data;
});

export const finishFogUnitCheck = createAsyncThunk('fogunit/finishCheck', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FINISH_FOG_UNIT_CHECK, { nID: arg.nID });
	return response.data;
});

export const fetchFogUnitData = createAsyncThunk('fogunit/fetchData', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FOG_UNIT_DATA_FOR_ORDER, { nID: arg.nID });
	return response.data;
});

export const editFogUnitData = createAsyncThunk('fogunit/editData', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FOG_UNIT_EDIT, { nID: arg.nID, gnr: arg.gnr, loc: arg.loc, hgh: arg.hgh });
	return response.data;
});

export const updateFogUnitComment = createAsyncThunk('fogunit/updateComment', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FOG_UNIT_COMMENT_UPDATE, { nID: arg.nID, c: arg.c });
	return response.data;
});

export const fogUnitCheck = createAsyncThunk('fogunit/fogUnitCheck', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FOG_UNIT_CHECKS, { nID: arg.nID, typ: arg.typ, wert: arg.wert, anzahl: arg.anzahl || null });
	return response.data;
});

export const fogUnitCheckReset = createAsyncThunk('fogunit/fogUnitCheckReset', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FOG_UNIT_CHECKS_RESET, { nID: arg.nID });
	return response.data;
});

export const FogUnitSlice = createSlice({
	name: 'messages',
	initialState,
});

export default FogUnitSlice.reducer;
