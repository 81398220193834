import axios from 'axios';
import useAuth from './useAuth';
import { API_REFRESH_ROUTE } from '../API/endpoints';

const useRefreshToken = () => {
	const { setAuth } = useAuth();

	const refresh = async () => {
		const response = await axios.get(API_REFRESH_ROUTE, {
			withCredentials: true,
		});

		setAuth((prev) => {
			return { ...prev, accessToken: response.data.token };
		});

		return response.data.token;
	};

	return refresh;
};

export default useRefreshToken;
