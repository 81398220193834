import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';

import store from './config/configureStore';
import { Provider } from 'react-redux';

import App from './App/App';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { AuthProvider } from './Context/AuthContext';

import 'react-toastify/dist/ReactToastify.css';

const persistor = persistStore(store);

const rootElement = document.getElementById('root');

const renderApp = (Component) => {
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AuthProvider>
					<HashRouter>
						<Component />
					</HashRouter>
				</AuthProvider>
			</PersistGate>
		</Provider>,
		rootElement
	);
};

//renderApp(Main);
renderApp(App);
/*
if (module.hot) {
  console.log("module.hot", !!module.hot);
  module.hot.accept("./App/App", () => {
    const NextApp = require("./App/App").default;
    renderApp(NextApp);
  });
}
*/
serviceWorker.unregister();
