export const ERROR_ARRIVAL_TIME = 'Geben Sie bitte Abfahrts- und Ankunftszeit ein';
export const ERROR_WORK_TIME = 'Geben Sie bitte Beginn und Ende der Arbeitszeit ein';
export const ERROR_DEPARTURE_TIME = 'Geben Sie bitte Abfahrts- und Ankunftszeit ein';
export const ERROR_ARRIVAL_BEGINS_AFTER_IT_ENDS = 'Das Ende der Hinfahrt muss nach dem Beginn der Hinfahrt liegen';
export const ERROR_WORK_BEGINS_AFTER_IT_ENDS = 'Das Ende der Arbeitszeit muss nach dem Beginn der Arbeitszeit liegen';
export const ERROR_DEPARTURE_BEGINS_AFTER_IT_ENDS = 'Das Ende der Rueckfahrt muss nach dem Beginn der Rueckfahrt liegen';
export const ERROR_ARRIVAL_TIME_MISSING = 'Geben Sie Ihre Ankunftszeit ein';
export const ERROR_WORK_TIME_START_MISSING = 'Geben Sie den Arbeitsbeginn ein';
export const ERROR_WORK_TIME_END_MISSING = 'Geben Sie das Arbeitsende ein';
export const ERROR_END_OF_ARRIVAL_MISSING = 'Geben Sie das Ende der Hinfahrt ein.';
export const ERROR_DEPARTURE_BEGINS_BEFORE_WORK_ENDS = 'Die Rückfahrt kann nicht vor dem Arbeitsende beginnen';
export const ERROR_DEPARTURE_BEGINS_BEFORE_ARRIVAL = 'Die Rückfahrt kann nicht vor der Ankunft beginnen';
export const ERROR_WORK_STARTS_BEFORE_ARRIVAL = 'Die Arbeitszeit kann nicht vor der Ankunft beginnen';
export const ERROR_DEPARTURE_STARTS_BEFORE_WORK_ENDS = 'Die Abfahrt muss zeitlich nach der Arbeitszeit liegen';
export const ERROR_ARRIVAL_AND_WORK_TIMES_MISSING = 'Geben Sie den Beginn der Hinfahrt oder der Arbeit an';
export const ERROR_MILEAGE_WRONG_FORMAT = 'Geben Sie eine gültige km-Zahl an';
export const ERROR_TIMEFORMAT = 'Geben Sie eine gültige Uhrzeit ein';
export const ERROR_TIME_EXCEEDED = 'Die angegebene Zeit liegt zu weit in der Zukunft';
export const ERROR_UNREALISTIC_MILEAGE = 'Geben Sie einen realistischen km-Stand an';
export const ERROR_MISSING_TECHNICIAN = 'Wählen Sie einen Techniker aus';
export const ERROR_DEPARTURE_BEFORE_ARRIVAL = 'Die Anreise muss vor der Rückreise stattfinden';
export const ERROR_MISSING_WORK_DATE = 'Geben Sie ein Datum ein';
