import './CustomerAndAddress.scss';
import React, { useState } from 'react';
import { Button, Modal, Card, CardHeader, CardBody, CardFooter, ModalBody } from 'reactstrap';

const CustomerAndAddress = (props) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => {
		setShowModal(!showModal);
	};

	const className = 'customerAndAddress';
	return (
		<>
			<div className={className}>
				<div className={`${className}__customer`}>{props.customer}</div>
				<div className={`${className}__address`}>
					{props.withGoogleLink ? (
						<a
							href='#'
							onClick={(e) => {
								e.preventDefault();
								toggle();
							}}
						>
							{props.street} - {props.pcode} {props.location}
						</a>
					) : (
						<>
							{props.street} - {props.pcode} {props.location}
						</>
					)}
				</div>
				{showModal && (
					<Modal isOpen={showModal} toggle={toggle}>
						<ModalBody className='p-0'>
							<Card className='main-card'>
								<CardHeader>
									<i className='header-icon lnr-earth icon-gradient bg-plum-plate'> </i>
									{props.street} - {props.pcode} {props.location}
								</CardHeader>
								<CardBody>
									<div>
										<Button
											className='mb-2 me-2 btn-icon-vertical btn-transition border-0'
											outline
											color='dark'
											href={'https://maps.google.de/?q=' + props.street + '-' + props.pcode + ' ' + props.location}
											target='_blank'
											onClick={toggle}
										>
											<i className='lnr-earth btn-icon-wrapper'> </i>
											Zur Karte
										</Button>
									</div>
								</CardBody>
								<CardFooter className='d-block text-end'>
									<Button size='sm' className='me-2' color='link' onClick={toggle}>
										Schließen
									</Button>
								</CardFooter>
							</Card>
						</ModalBody>
					</Modal>
				)}
			</div>
		</>
	);
};

export default CustomerAndAddress;
