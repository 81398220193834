import React, { useState } from 'react';
import cx from 'classnames';
import { Row, Col } from 'reactstrap';
import EMailFields from '../Common/EMailFields';

const OrderAssignTechnicianRow = (props) => {
	const [checked, setChecked] = useState(props.technician.zugewiesen);

	const technician = props.technician;
	const handleClick = () => {
		setChecked(!checked);
		props.handleChange(!checked, technician);
	};

	return (
		<Row style={{ borderBottom: '1px solid #e4e4e4', marginBottom: '.5rem' }}>
			<Col xs={5} sm={3}>
				<div>
					<div style={{ display: 'flex', maxWidth: '270px', lineHeight: '1.2', alignItems: 'center' }}>
						<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							{((!!technician.nachname ? technician.nachname : '') + ', ' + (!!technician.vorname ? technician.vorname : '')).trim()}
						</div>
					</div>
					{!!technician.firma && <div style={{ color: '#4f9f24', fontSize: '11px' }}>{technician.firma}</div>}
				</div>
			</Col>
			<Col xs='none' sm={4}>
				<EMailFields emails={technician.email} />
			</Col>
			<Col>{technician.telefon}</Col>
			<Col className='text-end'>
				<div className='switch has-switch me-2 mb-2 me-2' data-on-label='Zugewiesen' data-off-label='Nicht zugewiesen' onClick={handleClick}>
					<div
						className={cx('switch-animate', {
							'switch-on': checked,
							'switch-off': !checked,
						})}
					>
						<input type='checkbox' />
						<span className='switch-left bg-success'>Ja</span>
						<label>&nbsp;</label>
						<span className='switch-right bg-success'>Nein</span>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default OrderAssignTechnicianRow;
