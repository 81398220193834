import React, { Fragment, useRef } from 'react';

import cx from 'classnames';

class SearchBox extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeSearch: false,
		};
		this.searchRef = React.createRef();
	}

	render() {
		return (
			<div style={{ fontSize: '0.88rem' }}>
				<div
					className={cx('search-wrapper', {
						active: this.state.activeSearch || this.props.initialValue !== '',
					})}
				>
					<div className='input-holder'>
						<input
							ref={this.searchRef}
							type='text'
							className='search-input'
							placeholder='Suchen...'
							onChange={(e) => this.props.search(e.target.value)}
							defaultValue={this.props.initialValue}
						/>
						<button
							onClick={() => {
								this.setState({ activeSearch: !this.state.activeSearch });
								this.searchRef.current.focus();
							}}
							className='search-icon'
						>
							<span />
						</button>
					</div>
					<button
						onClick={() => {
							this.setState({ activeSearch: !this.state.activeSearch });
							this.props.search('');
							this.searchRef.current && (this.searchRef.current.value = '');
						}}
						className='btn-close btn-sm'
					/>
				</div>
			</div>
		);
	}
}

export default SearchBox;
