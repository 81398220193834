import './Common.scss';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Col, Row, Button, Form, FormGroup, Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import logo from '../../../assets/images/ibs_logo_tp.png';
import { useDispatch } from 'react-redux';
import { sendLoginCredentials } from '../../Services/LoginHandler';
import useAuth from '../Hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

// Layout
const Login = () => {
	const dispatch = useDispatch();
	const { setAuth, persist, setPersist } = useAuth();
	const username = useRef();
	const password = useRef();
	const [usernameValue, setUsernameValue] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [loginError, setLoginError] = useState('');
	//const history = useHistory();
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);

	const toggle = () => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		localStorage.setItem('persist', persist);
	}, [persist]);

	const handleLogin = () => {
		dispatch(sendLoginCredentials({ username: usernameValue, password: passwordValue })).then((response) => {
			if (response.meta.requestStatus === 'fulfilled' && response.payload?.status === 200) {
				const accessToken = response.payload.data.token;
				const split = !!accessToken ? accessToken.split('.')[1] : '';
				const buffer = !!accessToken ? Buffer.from(split, 'base64') : null;
				const parsed = !!accessToken ? JSON.parse(buffer) : null;
				setAuth({ prev: null, accessToken: accessToken });

				const role = Number.parseInt(parsed.roles);
				if (role > 100 && role < 200) {
					navigate('/admin/dashboard', { replace: false });
				} else if (role > 200 && role < 300) {
					navigate('/techniker/dashboard', { replace: false });
				} else if (role > 300 && role < 400) {
					navigate('/errichter/dashboard', { replace: false });
				}
				setLoginError('');
			} else if (response.meta.requestStatus === 'rejected' && response.payload?.code === 401) {
				const message = response.payload?.message === 'Invalid credentials.' ? 'Falsche Zugangsdaten' : response.payload?.message;
				setLoginError(message);
			}
		});
	};

	const togglePersist = () => {
		setPersist((prev) => !prev);
	};

	return (
		<Fragment>
			<div className={`ibsBackground h-100 pt-md-5 `}>
				<PerfectScrollbar>
					<div className=''>
						<Col lg='8' xl='4' className='mx-auto app-login-box shadowBox p-5'>
							<div className='mx-auto mb-3'>
								<img src={logo} width='150' alt='' />
							</div>
							<div className='text'>
								<h4 className='mt-2'>
									<div>Willkommen!</div>
									<span>Melden Sie sich mit Ihren Zugangsdaten an</span>
								</h4>
							</div>
							{loginError !== '' && (
								<div className=''>
									<Alert color='warning'>{loginError}</Alert>
								</div>
							)}
							<Form id='loginForm'>
								<Row>
									<Col md={12}>
										<FormGroup>
											<Input
												ref={username}
												type='text'
												name='email'
												id='exampleEmail'
												placeholder='Benutzername...'
												className='inputField'
												size='lg'
												maxLength='40'
												/*style={{ color: '#6c757d !important', backgroundColor: '#ffffff !important' }}*/
												value={usernameValue}
												onChange={(e) => setUsernameValue(e.target.value)}
												onKeyDown={(e) => e.keyCode === 13 && handleLogin()}
											/>
										</FormGroup>
									</Col>
									<Col md={12}>
										<FormGroup>
											<Input
												ref={password}
												type='password'
												name='password'
												id='password'
												placeholder='Passwort...'
												className='inputField'
												size='lg'
												maxLength='100'
												value={passwordValue}
												onChange={(e) => setPasswordValue(e.target.value)}
												onKeyDown={(e) => e.keyCode === 13 && handleLogin()}
											/>
										</FormGroup>
									</Col>
								</Row>
							</Form>

							<div>
								<Button color='primary' size='lg' className='loginButton' block tag='a' onClick={handleLogin}>
									Anmelden
								</Button>
							</div>
							<div>
								<div className='text form-check form-check-info mt-1'>
									<label
										className='form-check-label'
										style={{ display: 'flex', alignItems: 'center', fontSize: '1rem', justifyContent: 'center' }}
									>
										<input
											type='checkbox'
											name='persist'
											className='form-check-input'
											checked={persist}
											onChange={togglePersist}
											style={{ height: '1.25rem', width: '1.25rem', marginRight: '.5rem' }}
										/>
										<i className='input-helper' />
										Dem Gerät vertrauen{' '}
										<FontAwesomeIcon icon={faQuestionCircle} style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={toggle} />
									</label>
								</div>
							</div>
							<Row>
								<Col className='d-flex justify-content-center align-items-center'>
									<Button className='staticLink' color='link' tag='a' href='#/impressum'>
										Impressum
									</Button>
								</Col>
								<Col className='d-flex justify-content-center align-items-center'>
									<Button className='staticLink' color='link' tag='a' href='#/datenschutz'>
										Datenschutz
									</Button>
								</Col>
							</Row>
							<div className='text-center text-white opacity-8 mt-3'>Copyright &copy; ibs Sicherheitstechnik {new Date().getFullYear()}</div>
						</Col>
					</div>
					{showModal && (
						<Modal isOpen={showModal} toggle={toggle}>
							<ModalHeader>Was bedeutet es dem Gerät zu vertrauen?</ModalHeader>
							<ModalBody>
								<p>
									Der Haken 'Dem Gerät vertrauen' ermöglicht es den eingeloggten Zustand in die Applikation zu verlängern. Nach einer
									erfolgreichen Authentifizierung merkt sich die Applikation wer Sie sind und ermöglicht es auch noch nach Stunden die
									weiternutzung auch ohne eine neue Anmeldung.
								</p>
								<p>
									<div>
										<strong>Wann sollte ich einem Gerät vertrauen?</strong>
										<br />
										<br />
										Wenn Sie ein Gerät benutzen, auf welches nur Sie Zugriff haben, können Sie den Haken immer setzen.
									</div>
								</p>
								<p>
									<div>
										<strong>Warum sollte ich einem Gerät nicht vertrauen?</strong>
										<br />
										<br />
										Wenn Sie an einem Fremden Rechner, Tablet, Handy arbeiten und dort nur kurz Eintragungen vornehmen möchten, sollten Sie
										dem Gerät nicht vertrauen. Sollte eine Fremde Person Zugriff an dieses Gerät haben und die Applikation öffnen, dann kann
										die Person all Ihre Daten einsehen und gegebenenfalls manipulieren. Dem können Sie vorbeugen indem Sie fremden Geräten
										nicht vertrauen. Dadurch läuft die Session nach 15 Minuten ab und Sie müssen sich neu einloggen.
									</div>
								</p>
							</ModalBody>
							<ModalFooter>
								<Button onClick={toggle}>Schließen</Button>
							</ModalFooter>
						</Modal>
					)}
				</PerfectScrollbar>
			</div>
		</Fragment>
	);
};

export default Login;
