import './PieTooltipComponent.scss';
import React from 'react';

const PieTooltipComponent = (props) => {
	const element = props.element;
	const data = element.data;
	const { art, typ, status } = data;
	const { value, formattedValue } = element;
	const className = 'pieTooltipComponent';
	const stateClassName = status === 'fertig' ? `${className}__state ${className}__state--done` : `${className}__state ${className}__state--undone`;
	return (
		<div className={className}>
			<div className={`${className}__header`}>
				<div className={`${className}__colorCube`} style={{ backgroundColor: element.color }} />
				<div className={`${className}__title`}>{art}</div>
			</div>

			{typ && <div className={`${className}__type`}>{typ}</div>}
			{status && (
				<div className={`${className}__status`}>
					<div className={`${className}__label`}>Status</div>
					<div className={stateClassName}>{status}</div>
				</div>
			)}

			<div className={`${className}__value`}>
				<div className={`${className}__count`}>
					{value} {value === 1 ? 'Auftrag' : 'Aufträge'}
				</div>
				<div className={`${className}__percent`}>{formattedValue}</div>
			</div>
		</div>
	);
};

export default PieTooltipComponent;
