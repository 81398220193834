import '../Common/DataTableAdditions.scss';
import '../Login/Common.scss';
import React, { Fragment, useEffect, useState } from 'react';
import {
	Row,
	Col,
	Card,
	CardBody,
	Button,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	Nav,
	NavItem,
	NavLink,
	Modal,
	ModalBody,
	CardHeader,
	CardFooter,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { useDispatch, useSelector } from 'react-redux';

import {
	getPage,
	getPerPage,
	getTotal,
	getStatus,
	getSortColumn,
	getSortOrder,
	getFilter,
	setFilter,
	setPage,
	setPerPage,
	fetchBuilderTechnicians,
	switchTechnicianAccountActivation,
	setSortByAndSortOrder,
	getTechnicians,
	deleteTechnician,
	setSelectedTechnician,
} from '../../../reducers/TechnicianOverviewSlice';
import NoDataComponent from '../Common/NoDataComponent';
import SearchBox from '../../../Layout/AppHeader/Components/SearchBox';
import { Link } from 'react-router-dom';
import { toast, Bounce } from 'react-toastify';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import EMailFields from '../Common/EMailFields';
import OrderCountBadges from '../Common/OrderCountBadges';
import useAuth from '../Hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingDataComponent from '../Common/LoadingDataComponent';
import { setOrder } from '../../../reducers/OrderOverviewSlice';
import { smallDisplay } from '../Technician/Components/Services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faList, faLock } from '@fortawesome/free-solid-svg-icons';

const TechnicianOverview = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const page = useSelector(getPage);
	const perPage = useSelector(getPerPage);
	const total = useSelector(getTotal);
	const technicians = useSelector(getTechnicians);
	const status = useSelector(getStatus);
	const sortBy = useSelector(getSortColumn);
	const sortOrder = useSelector(getSortOrder);
	const [search, setSearch] = useState(filter.search);
	const [showConfirm, setShowConfirm] = useState(false);
	const [selectedTechnicanAccount, setSelectedTechnicianAccount] = useState(null);
	const axios = useAxiosPrivate();
	const { isErrichter, isIBSErrichter } = useAuth();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const technicianName = () => {
		const technician = technicians.find((technician) => technician.i === selectedTechnicanAccount);

		if (!technician) return '';
		return technician.vn + ' ' + technician.nn;
	};

	const handleSwitch = (technicianID) => {
		dispatch(switchTechnicianAccountActivation({ axios, technician: technicianID })).then((response) => {
			if (response.payload.status === 'ok') {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				reloadBuilderTechnicians();
			} else {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	const handleDeleteTechnician = () => {
		dispatch(deleteTechnician({ axios, technician: selectedTechnicanAccount })).then((response) => {
			if (response.payload.status === 'ok') {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				reloadBuilderTechnicians();
				hideDeleteDialog();
			} else {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	const showDeleteDialog = (technicianID) => {
		setSelectedTechnicianAccount(technicianID);
		setShowConfirm(true);
	};

	const hideDeleteDialog = () => {
		setSelectedTechnicianAccount(null);
		setShowConfirm(false);
	};

	const columns = [
		{
			name: '',
			id: 'showAction',
			selector: (row) => (
				<Link className='btn btn-sm btn-primary' to={'/errichter/techniker-auftrag/zuweisen/' + row.i}>
					<FontAwesomeIcon icon={faList} className='btn-icon-wrapper' />
				</Link>
			),
			sortable: false,
			compact: true,
			center: true,
			omit: !smallDisplay,
			minWidth: '60px',
			maxWidth: '60px',
		},
		{
			name: 'Name',
			id: 'name',
			cell: (row) => (
				<div>
					<div style={{ display: 'block', maxWidth: '270px' }}>
						<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							{((!!row.nn ? row.nn : '') + ', ' + (!!row.vn ? row.vn : '')).trim()}
						</div>
					</div>
					{!!row.er && <div style={{ fontSize: '10px' }}>{row.er}</div>}
				</div>
			),
			sortable: true,
			minWidth: '150px',
			maxWidth: '300px',
		},
		{
			name: 'E-Mail',
			id: 'email',
			selector: (row) => <EMailFields emails={row.em} />,
			sortable: true,
			minWidth: '150px',
			maxWidth: '300px',
		},
		{
			name: 'Telefon',
			id: 'phone',
			selector: (row) => `${!!row.tel ? row.tel : ''}`,
			sortable: true,
			minWidth: '100px',
			maxWidth: '120px',
		},
		{
			name: 'Benutzername',
			id: 'username',
			selector: (row) => `${!!row.usr ? row.usr : ''}`,
			sortable: true,
			minWidth: '170px',
			maxWidth: '170px',
		},
		{
			name: 'Aufträge',
			id: 'count',
			selector: (row) => <OrderCountBadges rowId={row.i} openOrders={row.cnt} ordersInProgress={row.cntWork} doneOrders={row.cntDone} />,
			sortable: false,
			width: '170px',
			textAlign: 'center',
		},
		{
			name: 'Anmeldung',
			id: 'login',
			selector: (row) => (row.za ? <div className='badge bg-success'>zugelassen</div> : <div className='mb-2 me-2 badge bg-danger'>gesperrt</div>),
			sortable: false,
			width: '120px',
		},
		{
			name: 'Aktionen',
			id: 'actions',
			allowOverflow: true,
			selector: (row) =>
				isErrichter ? (
					!smallDisplay ? (
						<UncontrolledButtonDropdown direction='left' className='mb-2 me-2'>
							<Button color='primary' size='sm' href={'#/errichter/techniker-auftrag/zuweisen/' + row.i}>
								Aufträge zuweisen
							</Button>
							<DropdownToggle size='sm' className='dropdown-toggle-split' caret color='primary' />
							<DropdownMenu>
								<Nav vertical>
									<NavItem className='nav-item-header'>
										<div
											style={{ display: 'block', maxWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
										>
											<i className='nav-link-icon pe-7s-user me-2'> </i>
											{((!!row.nn ? row.nn : '') + ', ' + (!!row.vn ? row.vn : '')).trim()}
										</div>
									</NavItem>
									<NavItem>
										<NavLink onClick={(e) => handleSwitch(row.i)}>{row.za ? 'Zugang deaktivieren' : 'Zugang aktivieren'}</NavLink>
									</NavItem>
									<NavItem>
										<NavLink href={`#/errichter/techniker-anlegen/${row.i}`}>Techniker bearbeiten</NavLink>
									</NavItem>
									<NavItem>
										<NavLink onClick={() => showDeleteDialog(row.i)}>Techniker löschen</NavLink>
									</NavItem>
								</Nav>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					) : (
						<>
							<Link className='btn btn-sm btn-primary me-2' onClick={(e) => handleSwitch(row.i)}>
								<FontAwesomeIcon icon={faLock} className='btn-icon-wrapper' />
							</Link>
							<Link className='btn btn-sm btn-primary me-2' to={'/errichter/techniker-auftrag/zuweisen/' + row.i}>
								<FontAwesomeIcon icon={faList} className='btn-icon-wrapper' />
							</Link>
							<Link className='btn btn-sm btn-danger' onClick={() => showDeleteDialog(row.i)}>
								<FontAwesomeIcon icon={faTrash} className='btn-icon-wrapper' />
							</Link>
						</>
					)
				) : (
					<Button color='primary' size='sm' href={'#/errichter/techniker-auftrag/zuweisen/' + row.i}>
						Aufträge zuweisen
					</Button>
				),
			sortable: false,
			minWidth: '180px',
		},
	];

	const reloadBuilderTechnicians = () => {
		setLoading(true);
		dispatch(fetchBuilderTechnicians({ axios, page, perPage, sortBy, sortOrder, filter })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}
			setLoading(false);
		});
	};
	useEffect(() => {
		if (status === 'idle') {
			reloadBuilderTechnicians();
		}
		dispatch(setOrder(null));
		dispatch(setSelectedTechnician(null));
	}, []);

	useEffect(() => {
		reloadBuilderTechnicians();
	}, [page, perPage, sortBy, sortOrder, filter]);

	const filterHeader = () => {
		return (
			<Row>
				<Col>
					<SearchBox
						initialValue={search}
						search={(search) => {
							setSearch(search);
							dispatch(setFilter(filter.type, search));
						}}
					/>
				</Col>
				<Col className='text-end'>
					{!isIBSErrichter && (
						<Link className='me-2 btn btn-icon btn-square btn-primary' to='/errichter/techniker-anlegen/0'>
							<i className='lnr-user btn-icon-wrapper'> </i>
							Techniker anlegen
						</Link>
					)}
				</Col>
			</Row>
		);
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}
						<PageTitle heading='Techniker' subheading='Übersicht Ihrer Techniker im System' icon='pe-7s-user icon-gradient bg-tempting-azure' />
						<Row>
							<Col md='12'>
								<Card className='main-card mb-3'>
									<CardBody>
										<div className='technicianTable'>
											<DataTable
												overflowY={true}
												overflowYOffset={'500px'}
												title={filterHeader()}
												data={technicians}
												columns={columns}
												responsive
												sortServer
												pagination
												paginationServer
												paginationDefaultPage={page}
												paginationPerPage={perPage}
												onChangeRowsPerPage={(e) => dispatch(setPerPage(e))}
												onChangePage={(e) => dispatch(setPage(e))}
												paginationTotalRows={total}
												onSort={(selectedColumn, sortDirection) => {
													dispatch(setSortByAndSortOrder(selectedColumn.id, sortDirection));
												}}
												paginationComponentOptions={{
													rowsPerPageText: 'Zeilen pro Seite',
													rangeSeparatorText: 'von',
												}}
												noDataComponent={<NoDataComponent />}
												fixedHeader
												fixedHeaderScrollHeight='58vh'
												progressPending={loading}
												progressComponent={<LoadingDataComponent />}
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</CSSTransition>
			</TransitionGroup>
			{showConfirm && (
				<Modal
					isOpen={showConfirm}
					toggle={() => {
						hideDeleteDialog();
					}}
				>
					<ModalBody className='p-0'>
						<Card className='main-card'>
							<CardHeader>Techniker löschen?</CardHeader>
							<CardBody>{technicianName() + ' wirklich löschen?'}</CardBody>
							<CardFooter className='d-block text-end'>
								<Button size='sm' className='me-2' color='danger' onClick={() => handleDeleteTechnician()}>
									Ja, löschen!
								</Button>
								<Button size='sm' className='me-2' color='link' onClick={() => hideDeleteDialog()}>
									Schließen
								</Button>
							</CardFooter>
						</Card>
					</ModalBody>
				</Modal>
			)}
		</Fragment>
	);
};

export default TechnicianOverview;
