import './OrderDescription.scss';
import React, { Fragment, useState } from 'react';
import { Alert, Button, Modal, Card, CardHeader, CardBody, CardFooter, ModalBody } from 'reactstrap';

const OrderDescription = (props) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => {
		setShowModal(!showModal);
	};

	const className = 'orderDescription';
	return (
		<div className={className}>
			<a
				href='#'
				className={`${className}__link`}
				onClick={(e) => {
					e.preventDefault();
					toggle();
				}}
			>
				{props.description}
			</a>
			{showModal && (
				<Modal isOpen={showModal} toggle={toggle}>
					<ModalBody className='p-0'>
						<Card className='main-card'>
							<CardHeader>
								<i className='header-icon lnr-earth icon-gradient bg-plum-plate'> </i>
								<span>{props.order} -&nbsp; </span>
								<small> Auftragsbeschreibung</small>
							</CardHeader>
							<CardBody className={`${className}__description`}>
								<div dangerouslySetInnerHTML={{ __html: props.description.replace(new RegExp('\r?\n', 'g'), '<br />') }} />
								{!!props.miscellaneous && (
									<Fragment>
										<hr />

										<Alert color='info'>
											<h6>Sonstiges</h6>
											<div dangerouslySetInnerHTML={{ __html: props.miscellaneous.replace(new RegExp('\r?\n', 'g'), '<br />') }} />
										</Alert>
									</Fragment>
								)}
							</CardBody>
							<CardFooter className='d-block text-end'>
								<Button size='sm' className='me-2' color='link' onClick={toggle}>
									Schließen
								</Button>
							</CardFooter>
						</Card>
					</ModalBody>
				</Modal>
			)}
		</div>
	);
};

export default OrderDescription;
