import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, Button, Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap';
import { getSelectedOrder } from '../../../reducers/OrderOverviewSlice';
import { fetchTechniciansForAssignment, switchOrderAssignmentsForTechnicians } from '../../Services/AssignmentHandler';
import OrderAssignTechnicianRow from './OrderAssignTechnicianRow';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { toast, Bounce } from 'react-toastify';
import { sendOrderAssignmentsChangedMail } from '../../Services/MailHandler';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import HeaderBacklink from '../Common/HeaderBacklink';

const OrderAssignTechnicians = (props) => {
	const dispatch = useDispatch();
	const selectedOrder = useSelector(getSelectedOrder);
	const [availableTechnicians, setAvailableTechnicians] = useState([]);
	const [changedAssignments, setChangedAssignments] = useState([]);
	const axios = useAxiosPrivate();
	const [search, setSearch] = useState('');

	const reloadTechnicians = () => {
		dispatch(fetchTechniciansForAssignment({ axios, order: selectedOrder })).then((thunk) => {
			setAvailableTechnicians(thunk.payload.data);
			setChangedAssignments([]);
		});
	};

	useEffect(() => {
		reloadTechnicians();
	}, []);

	const handleChange = (checked, technician) => {
		technician.zugewiesen = checked;

		const alreadyAssigned = changedAssignments.find((t) => t.id === technician.id);

		dispatch(switchOrderAssignmentsForTechnicians({ axios, technician: technician.id, order: selectedOrder, type: checked ? 1 : 0 })).then((response) => {
			if (response.payload.data.status === 'ok') {
				let assignments = changedAssignments.slice(0);
				if (!alreadyAssigned) {
					assignments.push(technician);
				} else {
					assignments = changedAssignments.filter((a) => a.id !== technician.id);
				}
				setChangedAssignments(assignments);

				toast(response.payload.data.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
			} else {
				toast(response.payload.data.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	const assignmentChangesText = () => {
		if (changedAssignments.length === 0) {
			return 'Keine Änderungen vorgenommen';
		} else if (changedAssignments.length === 1) {
			return 'Eine Zuweisung wurde geändert';
		}

		return `${changedAssignments.length} Zuweisungen wurden geändert`;
	};

	const handleSendEMail = () => {
		const submitData = [];

		changedAssignments.forEach((technician) => {
			submitData.push({ technician: technician.id, assigned: technician.zugewiesen });
		});

		dispatch(sendOrderAssignmentsChangedMail({ axios, order: selectedOrder, changes: submitData })).then((response) => {
			if (response.payload.status === 'ok') {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				reloadTechnicians();
			} else {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}
						{!props.noPageTitle && (
							<PageTitle
								heading='Technikerzuweisung'
								subheading='Weisen Sie den Auftrag einer beliebigen Anzahl an Technikern zu'
								icon='pe-7s-user icon-gradient bg-tempting-azure'
								headerActions={
									<HeaderBacklink backlinkTooltipContent={'Zurück zur Auftragsübersicht'} backlinkTarget={'/errichter/auftraege'} />
								}
							/>
						)}

						<Card>
							<CardHeader>
								<Col>Technikerzuweisung</Col>
								<Col className='d-flex align-items-center justify-content-center text-alternate me-2' xs='auto'>
									{assignmentChangesText()}
								</Col>
								{!!changedAssignments.length && (
									<Col xs='auto'>
										<Button color='alternate' size='sm' onClick={(e) => handleSendEMail()}>
											Techniker per E-Mail informieren
										</Button>
									</Col>
								)}
							</CardHeader>
							<CardBody>
								<Row className='mb-3'>
									<Col xs={5} sm={3}>
										<strong>Techniker</strong>
									</Col>
									<Col sm={4} className='d-none d-xs-block'>
										<strong>E-Mail</strong>
									</Col>
									<Col sm={2} className='d-none d-xs-block'>
										<strong>Telefon</strong>
									</Col>
									<Col className='text-end'>
										<strong>Zugewiesen</strong>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<InputGroup style={{ marginBottom: '2rem' }}>
											<Input
												onChange={(e) => {
													setSearch(e.target.value);
												}}
												value={search}
												maxLength={30}
												placeholder={'Filtern ...'}
											/>
											<InputGroupText>
												<span style={{ cursor: 'pointer' }} onClick={(e) => setSearch('')}>
													Filter löschen
												</span>
											</InputGroupText>
										</InputGroup>
									</Col>
								</Row>
								<div className='scroll-area-md mb-3'>
									<PerfectScrollbar>
										{availableTechnicians
											.filter((t) => {
												if (t.vorname.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
													return true;
												}
												if (t.nachname.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
													return true;
												}
												return false;
											})
											.map((technician) => {
												return (
													<OrderAssignTechnicianRow
														key={Math.ceil(Math.random() * 15000000)}
														technician={technician}
														handleChange={handleChange}
													/>
												);
											})}
									</PerfectScrollbar>
								</div>
							</CardBody>
						</Card>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default OrderAssignTechnicians;
