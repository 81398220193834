import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, FormFeedback, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrder, fetchAllThumbsForOrder, uploadPhotoForOrder } from '../../../../reducers/OrderOverviewSlice';
import { toast, Bounce } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Loader } from 'react-loaders';
import Photo from './Photo';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import useAuth from '../../Hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const PhotoGallery = () => {
	const dispatch = useDispatch();
	const selectedOrder = useSelector(getSelectedOrder);
	const loading = false;
	const targetWidth = 1000;
	const [imageDataArray, setImageDataArray] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [selectedFileError, setSelectedFileError] = useState('');
	const [selectedFileErrors, setSelectedFileErrors] = useState('');
	const [captions, setCaptions] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [thumbnails, setThumbnails] = useState([]);
	const [thumbnailErrors, setThumbnailErrors] = useState([]);
	const axios = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();
	const { isTechniker, isIBSTechniker } = useAuth();
	const [isUploading, setIsUploading] = useState(false);

	const loadAllThumbnails = () => {
		dispatch(fetchAllThumbsForOrder({ axios, selectedOrder: selectedOrder })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}
			if (!!response.payload?.data) {
				setThumbnails(response.payload.data.fotos);
				setThumbnailErrors(response.payload.data.errors);
			}
		});
	};

	useEffect(() => {
		if (!!selectedFiles) {
			loadImageFile();
		}
	}, [selectedFiles]);

	useEffect(() => {
		loadAllThumbnails();
	}, []);

	const loadImageFile = function () {
		if (!showModal || selectedFiles.length === 0) return;

		const newImages = [];
		const filePromises = selectedFiles.map((file) => {
			return new Promise((resolve, reject) => {
				const fileReader = new FileReader();
				fileReader.onload = function (event) {
					const image = new Image();
					image.onload = function () {
						let scale = 1;
						if (image.width > targetWidth) {
							scale = image.width / targetWidth;
						}

						const canvas = document.createElement('canvas');
						const context = canvas.getContext('2d');
						canvas.width = image.width / scale;
						canvas.height = image.height / scale;
						context.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

						const imageData = canvas.toDataURL('image/jpeg', 0.75);
						newImages.push(imageData);
						resolve();
					};
					image.src = event.target.result;
				};
				fileReader.readAsDataURL(file);
			});
		});

		Promise.all(filePromises).then(() => {
			setImageDataArray(newImages);
		});
	};

	const resetForm = () => {
		setShowModal(false);
		setSelectedFileError('');
		setImageDataArray([]);
		setSelectedFileErrors([]);
		setSelectedFiles([]);
	};

	/*
	const uploadPhoto = () => {
		if (isUploading) {
			return;
		}
		const form = new FormData();
		//form.append('comment', comment);
		//form.append('imagedata', imageData);

		setIsUploading(true);
		dispatch(uploadPhotoForOrder({ axios, selectedOrder: selectedOrder, form: form })).then((response) => {
			// Hier könnten auch META Daten überprüft werden um den Erfolg des Requests sicherzustellen
			if (response.payload.status === 'ok') {
				// Erfolgsmeldung anzeigen (toast) / Modal schließen / Thumbs zum Auftrag neu laden!
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				resetForm();
				loadAllThumbnails();
			} else if (response.payload.status === 'error') {
				// Fehlermeldung aus message lesen
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
			setIsUploading(false);
		});
	};
	*/
	const uploadPhoto = (image, file, caption) => {
		const form = new FormData();
		form.append('comment', caption);
		form.append('imagedata', image);
		form.append('filename', file.name);
		// Set up the form data, including the file and caption

		return dispatch(uploadPhotoForOrder({ axios, selectedOrder: selectedOrder, form: form }))
			.then((response) => {
				// Handle the response and perform necessary actions
				return Promise.resolve(); // Resolve the promise when the upload is completed
			})
			.catch((error) => {
				// Handle errors and perform necessary actions
				return Promise.reject(error); // Reject the promise if there was an error
			});
	};

	const handleUploadAll = async () => {
		setIsUploading(true);
		const uploadPromises = [];

		selectedFiles.forEach((file, index) => {
			const caption = captions[index];
			const image = imageDataArray[index];
			uploadPromises.push(uploadPhoto(image, file, caption));
		});

		Promise.all(uploadPromises)
			.then(() => {
				// All uploads completed successfully, show the message here
				const message =
					uploadPromises.length === 1
						? 'Das Bild wurde erfolgreich hochgeladen'
						: uploadPromises.length > 1
						? 'Die Bilder wurden erfolgreich hochgeladen'
						: 'Kein Bild wurde hochgeladen';
				toast(message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: uploadPromises.length >= 1 ? 'success' : 'warning',
				});
			})
			.catch((error) => {
				// Handle errors if any of the uploads failed
				console.log('error', error);
				toast('Das Bild konnte nicht hochgeladen werden', {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			})
			.finally(() => {
				setIsUploading(false);
				loadAllThumbnails();
				resetForm();
			});
	};

	const handleModalShow = () => {
		setImageDataArray([]);
		setSelectedFiles([]);
		setSelectedFileErrors([]);
		setCaptions([]);
		setShowModal(true);
	};

	const handleFileSelection = (e) => {
		const fileErrors = [];
		const files = Array.from(e.target.files);
		const filteredFiles = files.filter((file) => file.type === 'image/jpeg' || file.type === 'image/png');
		//const toBigFiles = files.filter((file) => file.size > 12 * 1024 * 1024);
		const wrongTypes = files.filter((file) => file.type !== 'image/jpeg' && file.type !== 'image/png');
		const initialCaptions = filteredFiles.map(() => '');
		//toBigFiles.forEach((file) => fileErrors.push(`Die Datei ${file.name} ist zu groß`));
		wrongTypes.forEach((file) => fileErrors.push(`Die Datei ${file.name} ist kein gültiges Bild`));

		setSelectedFiles(filteredFiles);
		setSelectedFileErrors(fileErrors);
		setCaptions(initialCaptions);
	};

	const handleCaptionChange = (index, value) => {
		const updatedCaptions = [...captions];
		updatedCaptions[index] = value;
		setCaptions(updatedCaptions);
	};

	return (
		<LoadingOverlay
			tag='div'
			active={loading}
			styles={{
				overlay: (base) => ({
					...base,
					background: '#fff',
					opacity: 0.5,
				}),
			}}
			spinner={<Loader color='#ffffff' active type={'line-scale-pulse-out'} />}
		>
			<div>
				<h4>Fotos</h4>
				<hr />
				<Row>
					{(isTechniker || isIBSTechniker) && (
						<Col xs='12' sm='12' md='auto'>
							<Button className='mb-3 me-2 btn-icon-vertical btn-transition' color='primary' onClick={() => handleModalShow()}>
								<i className='pe-7s-camera btn-icon-wrapper'> </i>
								Foto <br />
								hochladen
							</Button>
						</Col>
					)}
					<Col>
						{thumbnails.length === 0 && thumbnailErrors.length === 0 && (
							<div>
								<Alert color='info'>Zu dem Auftrag wurden keine Fotos hochgeladen</Alert>
							</div>
						)}
						{thumbnailErrors.length > 0 && (
							<Alert color='warning'>
								<ul style={{ marginBottom: '0' }}>
									{thumbnailErrors.map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							</Alert>
						)}
						{thumbnails.map((thumb) => {
							return (
								<Photo
									key={thumb.id}
									order={selectedOrder}
									id={thumb.id}
									image={thumb.image}
									reloadGallery={loadAllThumbnails}
									label={thumb.bemerkung}
								/>
							);
						})}
					</Col>
				</Row>
			</div>
			{!!showModal && (
				<Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
					<ModalHeader toggle={() => setShowModal(!showModal)}>Foto(s) hochladen</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label for='foto'>Foto(s) hochladen</Label>
							<Input
								type='file'
								multiple={true}
								name='foto[]'
								id='foto'
								onChange={(e) => handleFileSelection(e)}
								accept='image/png, image/jpeg'
								invalid={!!selectedFileError}
							/>
							{!!selectedFileError && <FormFeedback>{selectedFileError}</FormFeedback>}
							{selectedFileErrors.length > 0 && (
								<FormFeedback style={{ display: 'block' }}>
									{selectedFileErrors.map((error, index) => {
										return <div key={`error_${index}`}>{error}</div>;
									})}
								</FormFeedback>
							)}
						</FormGroup>

						<Fragment>
							<hr />
							{!!imageDataArray.length && (
								<>
									<h5>Vorschau</h5>

									<Slider {...settings}>
										{imageDataArray.map((data, index) => (
											<Fragment>
												<FormGroup style={{ backgroundColor: 'white' }}>
													<Label for={`caption_${index}`}>Bezeichnung Bild {index + 1}</Label>
													<Input
														type='text'
														id={`caption_${index}`}
														defaultValue={captions[index]}
														onChange={(e) => handleCaptionChange(index, e.target.value)}
													/>
												</FormGroup>
												<div key={`iData${index}`} style={{ position: 'relative' }}>
													<img width='100%' style={{ maxWidth: '800px' }} src={data} />
												</div>
											</Fragment>
										))}
									</Slider>
								</>
							)}

							{/*<img width='100%' style={{ maxWidth: '800px' }} src={imageData} alt='' />*/}
						</Fragment>
					</ModalBody>
					<ModalFooter>
						<Button color='primary' disabled={isUploading || selectedFiles.length === 0} onClick={() => handleUploadAll()}>
							Hochladen
						</Button>{' '}
						<Button color='link' onClick={() => resetForm()}>
							abbrechen
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</LoadingOverlay>
	);
};

export default PhotoGallery;
