import React, { Fragment, useRef, useState } from 'react';
import { Alert, Row, Button, Col, Card, CardBody, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { updatePassword } from '../../Services/UserHandler';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { toast, Bounce } from 'react-toastify';

const ChangePasswordForm = (props) => {
	const dispatch = useDispatch();
	const axios = useAxiosPrivate();

	const passwortRef = useRef();
	const [passwort, setPasswort] = useState('');
	const [passwortError, setPasswortError] = useState('');

	const passwortWiederholungRef = useRef();
	const [passwortWiederholung, setPasswortWiederholung] = useState('');
	const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&\\+\\-*])(?=.{8,})');

	const validate = () => {
		let error = '';
		if (passwort === '') {
			error = 'Geben Sie ein Passwort ein';
		} else if (passwort !== passwortWiederholung) {
			error = 'Die Passwörter müssen übereinstimmen';
		} else if (passwort !== 'F4k3p4$$w0rD!' && !strongRegex.test(passwort)) {
			error = 'Geben Sie ein gültiges Passwort ein';
		}
		setPasswortError(error);
		return error === '';
	};

	const resetForm = () => {
		setPasswort('');
		setPasswortError('');
		setPasswortWiederholung('');
	};

	const handlePasswortUpdate = () => {
		const valid = validate();
		if (!!valid) {
			dispatch(updatePassword({ axios, passwort })).then((response) => {
				const payload = response.payload;
				const data = payload.data;

				if (payload.status === 200) {
					toast(payload.data.message, {
						transition: Bounce,
						closeButton: true,
						autoClose: 5000,
						position: 'top-right',
						type: data.status === 1 ? 'success' : 'warning',
					});

					if (payload.data.status === 1) {
						resetForm();
						props.close();
					}
				}
			});
		}
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}
						<div style={{ overflow: 'hidden', position: 'relative' }}>
							<PageTitle
								heading='Benutzerprofil'
								subheading='Bearbeiten Sie Ihr Benutzerprofil'
								icon='lnr-users icon-gradient bg-tempting-azure'
								className='p-0'
								alternate={true}
							/>
						</div>
						<Row>
							<Col>
								<Card className='main-card mb-0'>
									<CardBody>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												// TODO: implement submit function
												handlePasswortUpdate();
												return false;
											}}
											id='profil'
											name='profil'
										>
											<FormGroup row>
												<Label sm={4}>Passwort</Label>
												<Col sm={8}>
													<Input
														ref={passwortRef}
														type='password'
														name='password'
														id='password'
														placeholder='Passwort'
														size='md'
														value={passwort}
														onChange={(e) => setPasswort(e.target.value)}
														invalid={passwortError !== ''}
													/>
													{passwortError !== '' && <FormFeedback className='alternate-form-feedback'>{passwortError}</FormFeedback>}
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={4}>Passwort wiederholen</Label>
												<Col sm={8}>
													<Input
														ref={passwortWiederholungRef}
														type='password'
														id='passwortWiederholung'
														name='passwortWiederholung'
														placeholder='Passwort wiederholen'
														onChange={(e) => {
															setPasswortWiederholung(e.target.value);
														}}
														size='md'
														value={passwortWiederholung}
														invalid={passwortError !== ''}
													/>
												</Col>
											</FormGroup>
											<Row>
												<Col>
													<Alert color='info'>
														{' '}
														Das Passwort muss 8 Zeichen lang sein und je einen Groß-, Kleinbuchstaben eine Zahl sowie ein
														Sonderzeichen (wie z.B. #,!,$,%,&,...) beinhalten.
													</Alert>
												</Col>
											</Row>
											<Row>
												<Col>
													<Button className='mb-2 me-2' type='submit' color='primary'>
														Speichern
													</Button>
													<Button
														className='mb-2 me-2'
														color='link'
														onClick={(e) => {
															props.close();
														}}
													>
														abbrechen
													</Button>
												</Col>
											</Row>
										</Form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default ChangePasswordForm;
