import './TechnicianAssignOrdersRow.scss';
import React, { useState } from 'react';
import cx from 'classnames';
import { Row, Col } from 'reactstrap';

const TechnicianAssignOrdersRow = (props) => {
	const auftrag = props.order;
	const [checked, setChecked] = useState(auftrag.zugewiesen);

	const handleClick = () => {
		if (props.changeable) {
			setChecked(!checked);
			props.handleChange(!checked, props.order);
		}
	};

	const className = 'technicianAssignOrdersRow';

	return (
		<Row className={className}>
			<Col xs='auto' className='d-flex justify-content-center align-items-center'>
				<div className='switch has-switch me-2 mb-2 me-2' data-on-label='Zugewiesen' data-off-label='Nicht zugewiesen' onClick={handleClick}>
					<div
						className={cx('switch-animate', {
							'switch-on': checked,
							'switch-off': !checked,
						})}
					>
						<input type='checkbox' />
						<span className='switch-left bg-success'>Ja</span>
						<label>&nbsp;</label>
						<span className='switch-right bg-success'>Nein</span>
					</div>
				</div>
			</Col>
			<Col>
				<div className={`${className}__orderNumber`}>
					{auftrag.auftragsnummer}{' '}
					<span className={`${className}__orderType`}>
						{auftrag.kuerzel} ({auftrag.auftragsart} {auftrag.auftragstyp})
					</span>
				</div>
				<div className={`${className}__location`}>
					<span>
						{auftrag.plz} {auftrag.ort} - {auftrag.strasse}
					</span>
				</div>
			</Col>
			<Col xs={'auto'}>
				<span className='badge bg-primary me-1' style={{ textAlign: 'center' }}>
					{auftrag.zuweisung}
				</span>
			</Col>
		</Row>
	);
};

export default TechnicianAssignOrdersRow;
