import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropdownToggle, DropdownMenu, UncontrolledButtonDropdown, Nav, NavItem } from 'reactstrap';
import { fetchMessagesForThreeMonths } from '../../../reducers/MessagesSlice';
import Message from './Message';
import useAxiosPrivate from '../../../App/Components/Hooks/useAxiosPrivate';
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NoDataComponent from './NoDataComponent';
import { IoIosChatboxes } from 'react-icons/io';

const MessageDropdownComponent = (props) => {
	const { user } = props;
	const axios = useAxiosPrivate();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [messages, setMessages] = useState([]);

	useEffect(() => {
		fetchMessages();
	}, []);

	const fetchMessages = () => {
		dispatch(fetchMessagesForThreeMonths({ axios })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}

			if (response.payload.status === 1) {
				setMessages(response.payload.data);
			} else {
				setMessages([]);
			}
		});
	};

	const reloadTrigger = () => {
		fetchMessages();
	};

	return (
		<UncontrolledButtonDropdown>
			<DropdownToggle color='link' className='p-0'>
				<div className='me-2 btn-icon btn-icon-only btn btn-link btn-sm'>
					<div className=''>
						<IoIosChatboxes fontSize='35px' color='#445a65' />
					</div>
					{messages.length > 0 && <span className='badge rounded-pill bg-primary'>{messages.length}</span>}
				</div>
			</DropdownToggle>
			<DropdownMenu end className='rm-pointers dropdown-menu-lg'>
				<div className='dropdown-menu-header mb-0'>
					<div className='dropdown-menu-header-inner bg-primary'>
						<div
							className='menu-header-image opacity-2'
							style={{
								backgroundImage: 'url(' + city3 + ')',
							}}
						/>
						<div className='menu-header-content text-start'>
							<div className='widget-content p-0'>
								<div className='widget-content-wrapper'>
									<div className='widget-content-left'>
										<div className='widget-heading'>Nachrichten</div>
										<div className='widget-subheading opacity-8'>{user}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='scroll-area-xs' style={{ height: '280px', marginTop: '.5rem', marginBottom: '.5rem' }}>
						{messages.length > 0 ? (
							<PerfectScrollbar>
								<Nav vertical>
									<NavItem>
										{messages.map((message) => {
											return <Message key={message.id} message={message} reloadTrigger={reloadTrigger} />;
										})}
									</NavItem>
								</Nav>
							</PerfectScrollbar>
						) : (
							<NoDataComponent />
						)}
					</div>
				</div>
			</DropdownMenu>
		</UncontrolledButtonDropdown>
	);
};

export default MessageDropdownComponent;
