import React, { Fragment, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Input, Form, FormFeedback, Button, FormGroup, Label } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { toast, Bounce } from 'react-toastify';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import PageTitle from '../../../../Layout/AppMain/PageTitle';
import TextareaAutosize from 'react-textarea-autosize';
import MessageComponent from '../../Common/MessageComponent';
import { createMessage } from '../../../../reducers/MessagesSlice';
import de from 'date-fns/locale/de';
registerLocale('de', de);

export const Checkbox = ({ label, onChange, id, checked }) => (
	<div className='form-check'>
		<input id={id} className='form-check-input' type='checkbox' name={id} checked={checked} onChange={onChange} />
		<label className='form-check-label' htmlFor={id}>
			{label}
		</label>
	</div>
);

const MessageForm = () => {
	//const history = useHistory();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [title, setTitle] = useState('');
	const [titleError, setTitleError] = useState('');
	const [message, setMessage] = useState('');
	const [messageError, setMessageError] = useState('');
	const [template, setTemplate] = useState('default');
	const [recipients, setRecipients] = useState([]);
	const [recipientsError, setRecipientsError] = useState('');
	const [vonDatum, setVonDatum] = useState(null);
	const [bisDatum, setBisDatum] = useState(null);
	const axios = useAxiosPrivate();

	const validate = async () => {
		const errors = [];
		if (title === '') {
			setTitleError('Geben Sie einen Titel ein');
			errors.push('Geben Sie einen Titel ein');
		} else {
			setTitleError('');
		}

		if (message === '') {
			setMessageError('Geben Sie den Inhalt der Benachrichtigung ein');
			errors.push('Geben Sie den Inhalt der Benachrichtigung ein');
		} else {
			setMessageError('');
		}

		if (recipients.length === 0) {
			setRecipientsError('Wählen Sie mindestens eine Zielgruppe aus.');
			errors.push('Wählen Sie mindestens eine Zielgruppe aus.');
		} else {
			setRecipientsError('');
		}

		return !errors.length;
	};

	const submitFormData = async () => {
		const form = document.nachricht_anlegen;
		let formData = new FormData(form);
		formData.append('recipients', recipients.join(', '));

		const valid = await validate();
		if (valid) {
			dispatch(createMessage({ axios, form: formData })).then((response) => {
				if (response?.error?.message.indexOf('401') >= 0) {
					navigate('/login', { state: { from: location }, replace: true });
				}
				if (response.payload.status === 1) {
					toast(response.payload.message, {
						transition: Bounce,
						closeButton: true,
						autoClose: 5000,
						position: 'top-right',
						type: 'success',
					});
					navigate('/admin/benachrichtigungen');
				} else {
					toast(response.payload.message, {
						transition: Bounce,
						closeButton: true,
						autoClose: 5000,
						position: 'top-right',
						type: 'error',
					});
				}
			});
		}
	};

	const heading = () => {
		return 'Benachrichtigung anlegen';
	};

	const subheading = () => {
		return 'Füllen Sie das Formular aus um eine Benachrichtigung für eine Zielgruppe anzulegen';
	};

	const toggleRecipient = (checked, role) => {
		if (checked) {
			const newRecipients = [...recipients, role];
			setRecipients(newRecipients);
		} else {
			setRecipients(recipients.filter((r) => r !== role));
		}
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}

						<PageTitle heading={heading()} subheading={subheading()} icon='pe-7s-note icon-gradient bg-tempting-azure' />
						<Form
							onSubmit={(e) => {
								e.preventDefault();
								submitFormData();
								return false;
							}}
							id='nachricht_anlegen'
							name='nachricht_anlegen'
						>
							<Row>
								<Col md='6'>
									<Card className='main-card mb-3'>
										<CardHeader>Füllen Sie das Formular aus</CardHeader>
										<CardBody>
											<Row>
												<Col>
													<FormGroup>
														<Row>
															<Col>
																<Label>Titel</Label>
																<Input
																	type='text'
																	id='title'
																	name='title'
																	onChange={(e) => {
																		setTitle(e.target.value);
																	}}
																	value={title}
																	invalid={titleError !== ''}
																/>
																{titleError !== '' && <FormFeedback>{titleError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>Inhalt der Benachrichtigung</Label>
																<TextareaAutosize
																	className='form-control'
																	maxRows={10}
																	minRows='3'
																	name='message'
																	defaultValue={message}
																	onChange={(e) => setMessage(e.target.value)}
																/>
																{messageError !== '' && <FormFeedback className='d-block'>{messageError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label>Wem soll die Benachrichtigung angezeigt werden?</Label>
																<Checkbox
																	id={1}
																	className='form-check-input'
																	label={'Administratoren'}
																	onChange={(e) => toggleRecipient(e.target.checked, 'ROLE_IBS_ADMIN')}
																	checked={!!recipients.find((r) => r === 'ROLE_IBS_ADMIN')}
																/>
																<Checkbox
																	id={2}
																	className='form-check-input'
																	label={'Errichter (ibs)'}
																	onChange={(e) => toggleRecipient(e.target.checked, 'ROLE_ERRICHTER_IBS')}
																	checked={!!recipients.find((r) => r === 'ROLE_ERRICHTER_IBS')}
																/>
																<Checkbox
																	id={3}
																	className='form-check-input'
																	label={'Errichter'}
																	onChange={(e) => toggleRecipient(e.target.checked, 'ROLE_ERRICHTER')}
																	checked={!!recipients.find((r) => r === 'ROLE_ERRICHTER')}
																/>
																<Checkbox
																	id={4}
																	className='form-check-input'
																	label={'Techniker (ibs)'}
																	onChange={(e) => toggleRecipient(e.target.checked, 'ROLE_TECHNIKER_IBS')}
																	checked={!!recipients.find((r) => r === 'ROLE_TECHNIKER_IBS')}
																/>
																<Checkbox
																	id={5}
																	className='form-check-input'
																	label={'Techniker (extern)'}
																	onChange={(e) => toggleRecipient(e.target.checked, 'ROLE_TECHNIKER_EXTERN')}
																	checked={!!recipients.find((r) => r === 'ROLE_TECHNIKER_EXTERN')}
																/>
																{recipientsError !== '' && <FormFeedback className='d-block'>{recipientsError}</FormFeedback>}
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Label>Wie soll die Benachrichtigung dargestellt werden?</Label>
														<Input
															type='select'
															id='darstellung'
															name='darstellung'
															onChange={(e) => {
																setTemplate(e.target.value);
															}}
															value={template}
														>
															<option value='default'>Standard</option>
															<option value='info'>Information</option>
															<option value='warning'>Warnung</option>
															<option value='danger'>Dringend</option>
														</Input>
													</FormGroup>
												</Col>
											</Row>
											<Row className='mb-3'>
												<Col>
													<strong>Soll die Nachricht nur in einem beschränkten Zeitraum relevant sein?</strong>
												</Col>
											</Row>
											<Row>
												<Col>
													<FormGroup>
														<Label>
															Ab wann ist diese Meldung interessant? <br />
															<small>(leer lassen wenn ab sofort)</small>
														</Label>
														<DatePicker
															locale={'de'}
															dateFormat={'dd.MM.Y'}
															className='form-control'
															name='von'
															selected={vonDatum}
															onChange={(d) => setVonDatum(d)}
															isClearable={true}
														/>
													</FormGroup>
												</Col>
												<Col>
													<FormGroup>
														<Label>
															Bis wann ist diese Meldung interessant? <br />
															<small>(leer lassen wenn dauerhaft)</small>
														</Label>
														<DatePicker
															locale={'de'}
															dateFormat={'dd.MM.Y'}
															className='form-control'
															name='bis'
															selected={bisDatum}
															onChange={(d) => setBisDatum(d)}
															isClearable={true}
														/>
													</FormGroup>
												</Col>
											</Row>
										</CardBody>
										<CardFooter className='justify-content-end align-items-center pt-4'>
											<FormGroup>
												<Row>
													<Col>
														<Button color='primary' type='submit'>
															Speichern
														</Button>
														<Link className='btn btn-sm btn-link' to='/admin/benachrichtigungen'>
															Abbrechen
														</Link>
													</Col>
												</Row>
											</FormGroup>
										</CardFooter>
									</Card>
								</Col>
								<Col md='6'>
									<Card>
										<CardHeader>Vorschau</CardHeader>
										<CardBody>
											<MessageComponent title={title} content={message} template={template} />
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Form>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default MessageForm;
