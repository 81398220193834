import '../Common/Order/OrderShow.scss';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, Alert, Row, Col, Card, CardHeader, CardBody, TabContent, TabPane, Button, ButtonGroup } from 'reactstrap';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrder, fetchOrderDetails, loadDepartmentData } from '../../../reducers/OrderOverviewSlice';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Loader } from 'react-loaders';
import OrderStatusCard from '../Common/Order/OrderStatusCard';
import OrderObjectCard from '../Common/Order/OrderObjectCard';
import OrderMandantCard from '../Common/Order/OrderMandantCard';
import OrderPosition from '../Common/Order/OrderPosition';
import OrderAssignTechnicians from './OrderAssignTechnicians';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import HeaderBacklink from '../Common/HeaderBacklink';
import { faCommentDots, faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhotoGallery from '../Technician/Components/PhotoGallery';
import OrderDocuments from '../Technician/Components/OrderDocuments';
import { API_DOWNLOAD_RETURNJOURNEYS_PDF } from '../API/endpoints';
import { toast, Bounce } from 'react-toastify';
import OrderDepartureCard from '../Technician/Components/OrderDepartureCard';
import { smallDisplay } from '../Technician/Components/Services';
import DataTable from 'react-data-table-component';
import OrderSafetyRegulations from '../Technician/Components/OrderSafetyRegulations';

const OrderShow = (props) => {
	const className = 'orderShow';
	const dispatch = useDispatch();
	const selectedOrder = useSelector(getSelectedOrder);
	const [orderDetails, setOrderDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState('1');
	const axios = useAxiosPrivate();
	const assignTechnicians = props.assignTechnicians;
	const [anAbfahrten, setAnAbfahrten] = useState([]);
	const [showCummulativeHours, setShowCummulativeHours] = useState(false);

	useEffect(() => {
		reloadOrderDetails();
		ladeAnAbfahrten();
	}, [selectedOrder]);

	const columns = [
		{
			name: 'Name',
			id: 'name',
			sortable: false,
			selector: (row) => (row.name === 'Gesamt' ? <strong>{row.name}</strong> : row.name),
		},
		{
			name: 'Hinfahrt',
			id: 'hinfahrt',
			sortable: false,
			center: true,
			selector: (row) => (row.name === 'Gesamt' ? <strong>{minutenangabeAlsUhrzeit(row.hinfahrt)}</strong> : minutenangabeAlsUhrzeit(row.hinfahrt)),
		},
		{
			name: 'Arbeitszeit',
			id: 'arbeit',
			sortable: false,
			center: true,
			selector: (row) => (row.name === 'Gesamt' ? <strong>{minutenangabeAlsUhrzeit(row.arbeit)}</strong> : <>{minutenangabeAlsUhrzeit(row.arbeit)}</>),
		},
		{
			name: 'Pause',
			id: 'rueckfahrt',
			sortable: false,
			center: true,
			selector: (row) => (row.name === 'Gesamt' ? <strong>{minutenangabeAlsUhrzeit(row.pause)}</strong> : minutenangabeAlsUhrzeit(row.pause)),
		},
		{
			name: 'Rückfahrt',
			id: 'rueckfahrt',
			sortable: false,
			center: true,
			selector: (row) => (row.name === 'Gesamt' ? <strong>{minutenangabeAlsUhrzeit(row.rueckfahrt)}</strong> : minutenangabeAlsUhrzeit(row.rueckfahrt)),
		},
		{
			name: 'Strecke',
			id: 'km',
			sortable: false,
			center: true,
			selector: (row) => (row.name === 'Gesamt' ? <strong>{`${row.km === 0 ? '-' : row.km} km`}</strong> : `${row.km === 0 ? '-' : row.km} km`),
		},
	];

	const uhrzeitInMinutenangabe = (uhrzeit) => {
		if (!uhrzeit) return 0;

		const parts = uhrzeit.split(':');
		return Number.parseInt(parts[0]) * 60 + Number.parseInt(parts[1]);
	};

	const minutenangabeAlsUhrzeit = (minuten) => {
		if (minuten <= 0) return '-';

		const stunden = Math.floor(minuten / 60);

		const uebrigeMinuten = minuten % 60;

		// Stunden und Minuten zu einem String im Format hh:mm formatieren
		const formattierteStunden = String(stunden).padStart(2, '0');
		const formattierteMinuten = String(uebrigeMinuten).padStart(2, '0');

		return `${formattierteStunden}:${formattierteMinuten}`;
	};

	const ladeAnAbfahrten = () => {
		setLoading(true);
		dispatch(loadDepartmentData({ axios, auftrag: selectedOrder })).then((thunk) => {
			setLoading(false);
			if (thunk.payload.status === 1) {
				setAnAbfahrten(thunk.payload.data);
			} else {
				setAnAbfahrten([]);
			}
		});
	};

	const reloadOrderDetails = () => {
		setLoading(true);
		dispatch(fetchOrderDetails({ axios, selectedOrder })).then((thunk) => {
			setLoading(false);
			setOrderDetails(thunk.payload);
		});
	};

	const auftragsbeschreibungZeile = () => {
		if (!orderDetails || !orderDetails.auftrag.beschreibung) {
			return null;
		}
		return (
			<Row>
				<Col sm='12'>
					<Card className='main-card mb-3'>
						<CardHeader>Auftragsbeschreibung</CardHeader>
						<CardBody>
							<div
								dangerouslySetInnerHTML={{
									__html: orderDetails.auftrag.beschreibung.replace(new RegExp('\r?\n', 'g'), '<br />'),
								}}
							></div>
							<OrderSafetyRegulations />
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	};

	const anAbreisen = () => {
		if (anAbfahrten.length === 0) {
			return null;
		}

		return <OrderDepartureCard departures={anAbfahrten} />;
	};

	const restarbeitenZeile = () => {
		if (!orderDetails || !orderDetails.auftrag.restarbeiten) {
			return null;
		}

		return (
			<Row>
				<Col sm='12'>
					<Card className='main-card mb-3 text-white bg-secondary'>
						<CardHeader>Restarbeiten</CardHeader>
						<CardBody>
							<Alert color='danger'>
								<div
									dangerouslySetInnerHTML={{
										__html: orderDetails.auftrag.restarbeiten.replace(new RegExp('\r?\n', 'g'), '<br />'),
									}}
								></div>
							</Alert>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	};

	const auftragCard = () => {
		return (
			<LoadingOverlay
				tag='div'
				active={loading}
				styles={{
					overlay: (base) => ({
						...base,
						background: '#fff',
						opacity: 0.5,
					}),
				}}
				spinner={<Loader color='#ffffff' active type={'line-scale-pulse-out'} />}
			>
				{!!orderDetails && (
					<OrderObjectCard
						objekt={orderDetails.objekt}
						auftragsnummer={orderDetails.auftrag.auftragsnummer}
						syncStatus={orderDetails.auftrag.syncStatus}
						auftraggeber={orderDetails.kunde.arbeitgeber}
						termin={orderDetails.auftrag.termin}
					/>
				)}
			</LoadingOverlay>
		);
	};

	const mandantenCard = () => {
		if (!orderDetails) {
			return null;
		}

		return (
			<LoadingOverlay
				tag='div'
				active={loading}
				styles={{
					overlay: (base) => ({
						...base,
						background: '#fff',
						opacity: 0.5,
					}),
				}}
				spinner={<Loader color='#ffffff' active type={'line-scale-pulse-out'} />}
			>
				<OrderMandantCard mandant={orderDetails.mandant} />
			</LoadingOverlay>
		);
	};

	const statusCard = () => {
		if (!orderDetails || !orderDetails.auftrag) {
			return null;
		}

		const auftrag = orderDetails.auftrag;
		const auftragBegonnen = !!auftrag.arbeitBegonnenDatum;
		const fertigLautBenutzer = !!auftrag.fertigLautBenutzer;
		const komplettFertig = !!auftrag.komplettFertig;

		return (
			<LoadingOverlay
				tag='div'
				active={loading}
				styles={{
					overlay: (base) => ({
						...base,
						background: '#fff',
						opacity: 0.5,
					}),
				}}
				spinner={<Loader color='#ffffff' active type={'line-scale-pulse-out'} />}
			>
				<OrderStatusCard
					auftragBegonnen={auftragBegonnen}
					fertigLautBenutzer={fertigLautBenutzer}
					komplettFertig={komplettFertig}
					fertiggestelltVon={auftrag.fertiggestelltVon}
					fertigLautBenutzerDatum={auftrag.fertigLautBenutzerDatum}
					komplettFertigDatum={auftrag.komplettFertigDatum}
					arbeitBegonnenVon={auftrag.arbeitBegonnenVon}
					arbeitBegonnenDat={auftrag.arbeitBegonnenDatum}
				/>
			</LoadingOverlay>
		);
	};

	const headingText = () => {
		if (!orderDetails) {
			return 'Auftrag';
		}

		return <>Auftrag: {orderDetails.auftrag.auftragsnummer}</>;
	};

	const subheadingText = () => {
		if (!orderDetails) {
			return 'Details zum Auftrag';
		}

		if (!!orderDetails.auftrag.bestellnummerBeimKunden) {
			return (
				<Fragment>
					<span className='text-alternate fs-12'>Auftrag-# beim Kunden: {orderDetails.auftrag.bestellnummerBeimKunden}</span>
					{!smallDisplay && (
						<span>
							{' / '}
							{orderDetails.auftrag.auftragsArtName} -{orderDetails.auftrag.auftragsTypName}
						</span>
					)}
				</Fragment>
			);
		}
	};

	const downloadReturnJourneyDocument = async () => {
		const url = API_DOWNLOAD_RETURNJOURNEYS_PDF + selectedOrder;
		await axios({
			url: url,
			method: 'GET',
			responseType: 'blob', // important
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Auftrag_' + selectedOrder + '_Rueckfahrten.pdf');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((reason) => {
				toast('Das angeforderte Dokument konnte nicht heruntergeladen werden.', {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			});
	};

	const auftragspositionen = () => {
		if (!orderDetails) {
			return null;
		}
		let positionen = null;
		const aktionen = orderDetails.hatRFDokument ? (
			<div className={`${className}__actionBarWrapper`}>
				<div className={`${className}__actionBar`}>
					{orderDetails.hatRFDokument && (
						<Link
							className={`${className}__actionLink download btn btn-icon btn-icon-only btn-transition`}
							onClick={() => downloadReturnJourneyDocument()}
						>
							<i className={`${className}__actionIcon lnr-cloud-download btn-icon-wrapper`} />
							<div className='d-none d-md-block mt-2' style={{ fontWeight: '600' }}>
								Rückfahrtdokument <br />
								herunterladen
							</div>
						</Link>
					)}
				</div>
			</div>
		) : null;

		if (!!orderDetails.positionen.length > 0) {
			positionen = orderDetails.positionen.map((position, index) => {
				return <OrderPosition key={index} index={index + 1} position={position} reloadOrderDetails={reloadOrderDetails} />;
			});
		}

		if (!!positionen) {
			return (
				<Fragment>
					{aktionen}
					{anAbreisen()}
					{positionen}
				</Fragment>
			);
		}

		return (
			<div>
				<Alert color='info'>Zu dem Auftrag existieren keine Positionen</Alert>
			</div>
		);
	};

	const kummulierteStunden = () => {
		if (!orderDetails) {
			return null;
		}

		const data = [];
		const gesamtSumme = {
			name: 'Gesamt',
			hinfahrt: 0,
			arbeit: 0,
			rueckfahrt: 0,
			rueckfahrtZaehler: 0,
			hinfahrtZaehler: 0,
			arbeitZaehler: 0,
			km: 0,
			pause: 0,
			pausenZaehler: 0,
		};
		orderDetails.positionen.forEach((position) => {
			if (position.storniert) return;

			const { id, vorname, nachname } = position.erledigtDurchBenutzer;
			const { hinfahrtBeginn, hinfahrtEnde, arbeitsbeginn, arbeitsende, rueckfahrtBeginn, rueckfahrtEnde, km } = position;

			const hinfahrt = !!hinfahrtBeginn && !!hinfahrtEnde ? uhrzeitInMinutenangabe(hinfahrtEnde) - uhrzeitInMinutenangabe(hinfahrtBeginn) : 0;
			const arbeit = !!arbeitsbeginn && !!arbeitsende ? uhrzeitInMinutenangabe(arbeitsende) - uhrzeitInMinutenangabe(arbeitsbeginn) : 0;
			const rueckfahrt = !!rueckfahrtBeginn && !!rueckfahrtEnde ? uhrzeitInMinutenangabe(rueckfahrtEnde) - uhrzeitInMinutenangabe(rueckfahrtBeginn) : 0;
			const summe = data.find((d) => d.id === id);

			if (!summe) {
				data.push({
					id: id,
					name: `${vorname} ${nachname}`,
					hinfahrt: hinfahrt,
					hinfahrtZaehler: !!hinfahrt ? 1 : 0,
					arbeit: arbeit,
					arbeitZaehler: !!arbeit ? 1 : 0,
					rueckfahrt: rueckfahrt,
					rueckfahrtZaehler: !!rueckfahrt ? 1 : 0,
					pause: arbeit < 360 ? 0 : arbeit < 600 ? 30 : 45,
					pausenZaehler: arbeit < 360 ? 0 : 1,
					km: km,
				});
			} else {
				summe.hinfahrt += hinfahrt;
				summe.hinfahrtZaehler += !!hinfahrt ? 1 : 0;
				summe.arbeit += arbeit;
				summe.arbeitZaehler += !!arbeit ? 1 : 0;
				summe.rueckfahrt += rueckfahrt;
				summe.rueckfahrtZaehler += !!rueckfahrt ? 1 : 0;
				summe.pause += arbeit < 360 ? 0 : arbeit < 600 ? 30 : 45;
				summe.pausenZaehler += arbeit < 360 ? 0 : 1;
				summe.km += km;
			}

			gesamtSumme.hinfahrt += hinfahrt;
			gesamtSumme.hinfahrtZaehler += !!hinfahrt ? 1 : 0;
			gesamtSumme.arbeit += arbeit;
			gesamtSumme.arbeitZaehler += !!arbeit ? 1 : 0;
			gesamtSumme.rueckfahrt += rueckfahrt;
			gesamtSumme.rueckfahrtZaehler += !!rueckfahrt ? 1 : 0;
			gesamtSumme.pause += arbeit < 360 ? 0 : arbeit < 600 ? 30 : 45;
			gesamtSumme.pausenZaehler += arbeit < 360 ? 0 : 1;
			gesamtSumme.km += km;
		});

		if (!!anAbfahrten) {
			anAbfahrten.forEach((reise) => {
				const id = reise.tID;
				const hinfahrt = !!reise.aV && !!reise.aB ? uhrzeitInMinutenangabe(reise.aB) - uhrzeitInMinutenangabe(reise.aV) : 0;
				const arbeit = 0;
				const rueckfahrt = !!reise.rV && !!reise.rB ? uhrzeitInMinutenangabe(reise.rB) - uhrzeitInMinutenangabe(reise.rV) : 0;

				const km = (!!reise.aK ? reise.aK : 0) + (!!reise.rK ? reise.rK : 0);

				const summe = data.find((d) => d.id === id);
				if (!summe) {
					data.push({
						id: id,
						name: `${reise.t}`,
						hinfahrt: hinfahrt,
						hinfahrtZaehler: !!hinfahrt ? 1 : 0,
						arbeit: arbeit,
						arbeitZaehler: !!arbeit ? 1 : 0,
						rueckfahrt: rueckfahrt,
						rueckfahrtZaehler: !!rueckfahrt ? 1 : 0,
						pause: 0,
						pausenZaehler: 0,
						km: km,
					});
				} else {
					summe.hinfahrt += hinfahrt;
					summe.hinfahrtZaehler += !!hinfahrt ? 1 : 0;
					summe.arbeit += arbeit;
					summe.arbeitZaehler += !!arbeit ? 1 : 0;
					summe.rueckfahrt += rueckfahrt;
					summe.rueckfahrtZaehler += !!rueckfahrt ? 1 : 0;
					summe.pause += 0;
					summe.pausenZaehler += 0;
					summe.km += km;
				}

				gesamtSumme.hinfahrt += hinfahrt;
				gesamtSumme.hinfahrtZaehler += !!hinfahrt ? 1 : 0;
				gesamtSumme.arbeit += arbeit;
				gesamtSumme.arbeitZaehler += !!arbeit ? 1 : 0;
				gesamtSumme.rueckfahrt += rueckfahrt;
				gesamtSumme.rueckfahrtZaehler += !!rueckfahrt ? 1 : 0;
				gesamtSumme.pause += 0;
				gesamtSumme.pausenZaehler += 0;
				gesamtSumme.km += km;
			});
		}

		data.push(gesamtSumme);

		return (
			<div>
				<h4 className='mb-1'>Kummulierte Stunden</h4>
				<small className='d-flex mb-2'>
					Summierte Stunden aller erfassten An-/Abreisedaten sowei aller Auftragspositionen pro Techniker. An-/Abreisedaten werden zu der
					Hin-/Rückfahrt addiert.
				</small>
				<DataTable data={data} columns={columns} />
			</div>
		);
	};

	const tabulatoren = () => {
		const cummulativeHoursToggle = () => {
			setShowCummulativeHours(!showCummulativeHours);
		};

		return (
			<Card>
				<CardHeader className='tabs-lg-alternate'>
					<Nav justified>
						<NavItem>
							<NavLink
								href='#'
								className={classnames({
									active: activeTab === '1',
								})}
								onClick={() => {
									setActiveTab('1');
								}}
							>
								<div className='widget-number'>Positionen</div>
								<div className='tab-subheading'>
									<span className='pe-2 opacity-6'>
										<FontAwesomeIcon icon={faCommentDots} />
									</span>
									Erfasste Arbeiten, Zeiten und km
								</div>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								href='#'
								className={classnames({
									active: activeTab === '2',
								})}
								onClick={() => {
									setActiveTab('2');
								}}
							>
								<div className='widget-number'>Fotos & Dokumente</div>
								<div className='tab-subheading'>
									<span className='pe-2 opacity-6'>
										<FontAwesomeIcon icon={faImages} />
									</span>
									Fotos & Dokumente zum Auftrag
								</div>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								href='#'
								className={classnames({
									active: activeTab === '3',
								})}
								onClick={() => {
									setActiveTab('3');
								}}
							>
								<div className='widget-number'>Techniker</div>
								<div className='tab-subheading'>Zuweisungen zum Auftrag</div>
							</NavLink>
						</NavItem>
					</Nav>
				</CardHeader>
				<CardBody>
					<TabContent activeTab={activeTab}>
						<TabPane tabId='1'>
							<CardBody>
								<div className={`${className}__cummulatedHours mb-2`}>
									<ButtonGroup size='sm' className='mb-2'>
										<Button
											className='btn-square'
											color={!showCummulativeHours ? 'primary' : 'light'}
											onClick={cummulativeHoursToggle}
											active={!showCummulativeHours}
											outline={!showCummulativeHours}
										>
											Auftragspositionen
										</Button>
										<Button
											className='btn-square'
											color={showCummulativeHours ? 'primary' : 'light'}
											onClick={cummulativeHoursToggle}
											active={showCummulativeHours}
											outline={showCummulativeHours ? true : undefined}
										>
											Kummulierte Stunden
										</Button>
									</ButtonGroup>

									<Button className='d-none mb-2 btn-icon btn-shadow btn-outline-2x' outline color='dark' onClick={cummulativeHoursToggle}>
										{showCummulativeHours ? 'Auftragspositionen anzeigen' : 'Kummulierte Stunden anzeigen'}
									</Button>
								</div>
								{showCummulativeHours ? <div>{kummulierteStunden()}</div> : <div className='mb-0'>{auftragspositionen()}</div>}
							</CardBody>
						</TabPane>
						<TabPane tabId='2'>
							<CardBody>
								<PhotoGallery />
								<hr />
								<OrderDocuments />
							</CardBody>
						</TabPane>
						<TabPane tabId='3'>
							<OrderAssignTechnicians noPageTitle={true} />
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		);
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div className={className}>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}
						<PageTitle
							heading={headingText()}
							subheading={subheadingText()}
							icon='lnr-construction icon-gradient bg-tempting-azure'
							headerActions={<HeaderBacklink backlinkTooltipContent={'Zurück zur Auftragsübersicht'} backlinkTarget={'/errichter/auftraege'} />}
						/>
						<Row>
							<Col xl='4'>
								{auftragCard()}
								{statusCard()}
								{mandantenCard()}
							</Col>
							<Col xl='8'>
								{auftragsbeschreibungZeile()}
								{restarbeitenZeile()}
								{!assignTechnicians ? tabulatoren() : <OrderAssignTechnicians noPageTitle={true} />}
							</Col>
						</Row>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default OrderShow;
