import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import SyncStatus from '../SyncStatus';

const OrderObjectCard = (props) => {
	const { termin, auftragsnummer, objekt, syncStatus, auftraggeber } = props;
	return (
		<Card className='main-card mb-3'>
			<CardHeader>
				{
					<Fragment>
						<SyncStatus id={'id' + objekt.id} syncStatus={syncStatus ? 1 : 0} /> Auftrag #{auftragsnummer}
					</Fragment>
				}
			</CardHeader>
			<CardBody>
				<Table size='sm' className='mb-2'>
					<tbody>
						<tr>
							<td>Termin</td>
							<td className='text-end text-alternate fw-bold'>{termin || ''}</td>
						</tr>
						<tr>
							<td>Auftraggeber</td>
							<td className='text-end text-alternate fw-bold'>{auftraggeber || ''}</td>
						</tr>
						<tr>
							<td>Objekt-Nr</td>
							<td className='text-end text-alternate fw-bold'>{objekt.objektNummer}</td>
						</tr>
						<tr>
							<td>Markt-Nr</td>
							<td className='text-end text-alternate fw-bold'>{objekt.marktNummer || '-'}</td>
						</tr>
						<tr>
							<td>Typ</td>
							<td className='text-end text-alternate'>{objekt.typ || ''}</td>
						</tr>
						<tr>
							<td>Straße</td>
							<td className='text-end text-alternate'>{objekt.strasse || ''}</td>
						</tr>
						<tr>
							<td>PLZ Ort</td>
							<td className='text-end text-alternate'>{objekt.plz + ' ' + objekt.ort}</td>
						</tr>
						<tr>
							<td>Telefon</td>
							<td className='text-end text-alternate'>{objekt.telefon || ''}</td>
						</tr>
						<tr>
							<td>Zentrale</td>
							<td className='text-end text-alternate'>{objekt.zentrale || ''}</td>
						</tr>
						<tr>
							<td>Wählgerät</td>
							<td className='text-end text-alternate'>{objekt.waehlgeraet || ''}</td>
						</tr>
						<tr>
							<td>Scharfschaltung</td>
							<td className='text-end text-alternate'>{objekt.scharfschaltung || ''}</td>
						</tr>
					</tbody>
				</Table>
			</CardBody>
		</Card>
	);
};

export default OrderObjectCard;
