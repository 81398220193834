import React from 'react';
import { Tooltip } from 'reactstrap';

export default class TooltipItem extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			tooltipOpen: false,
		};
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen,
		});
	}

	render() {
		return (
			<span>
				<span id={this.props.id}>{this.props.element}</span>
				<Tooltip placement={this.props.placement} isOpen={this.state.tooltipOpen} target={this.props.id} toggle={this.toggle}>
					{this.props.tooltip}
				</Tooltip>
			</span>
		);
	}
}
