import React from 'react';

const CollisionMessage = (props) => {
	const collision = props.collision;
	const collisions = [];

	if (!!collision.kollisionHinfahrt) {
		collisions.push(collision.kollisionHinfahrt);
	}
	if (!!collision.kollisionArbeitszeit) {
		collisions.push(collision.kollisionArbeitszeit);
	}
	if (!!collision.kollisionRueckfahrt) {
		collisions.push(collision.kollisionRueckfahrt);
	}
	if (!!collision.kollision) {
		collisions.push(collision.kollision);
	}

	const messages = collisions.map((c, i) => {
		return (
			<div key={`m-${i}`}>
				Die eingetragene <b>{c.field}</b> kollidiert mit der <b>{c.collidesWith}</b> vom Auftrag <b>{collision.auftrag}</b>.
			</div>
		);
	});

	return <>{messages.map((m) => m)}</>;
};

export default CollisionMessage;
