import './Photo.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { fetchImageData, deleteImageData } from '../../../../reducers/OrderOverviewSlice';
import { useDispatch } from 'react-redux';
import { toast, Bounce } from 'react-toastify';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import useAuth from '../../Hooks/useAuth';

const Photo = (props) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [imageData, setImageData] = useState(null);
	const axios = useAxiosPrivate();
	const { isTechniker, isIBSTechniker } = useAuth();
	const [showConfirm, setShowConfirm] = useState(false);

	const deleteButton = () => {
		dispatch(deleteImageData({ axios, selectedOrder: props.order, photo: props.id })).then((response) => {
			if (response.payload.status === 'ok') {
				setShowModal(false);
				props.reloadGallery();
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
			} else {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	useEffect(() => {
		if (!!showModal) {
			dispatch(fetchImageData({ axios, selectedOrder: props.order, photo: props.id })).then((response) => {
				if (response.payload.status === 'ok') {
					setImageData(response.payload.data);
				}
			});
		}
	}, [showModal]);

	return (
		<Fragment>
			<div className='photo'>
				<div className='photo__image'>
					<img src={props.image} onClick={(e) => setShowModal(true)} alt='' />
				</div>
				<div className={`photo__comment`}>{!!props.label ? props.label : <div className='photo__noComment'>Keine Bemerkung</div>}</div>
			</div>
			{!!showModal && (
				<Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
					<ModalHeader toggle={() => setShowModal(!showModal)}>Foto zum Auftrag</ModalHeader>
					<ModalBody>
						{!!imageData && (
							<Fragment>
								<div>
									<img width='100%' style={{ maxWidth: '800px' }} src={imageData.image} alt='' />
								</div>
								<div className='photo__dialogComment'>
									<strong>Bemerkung</strong>
									<br />
									<span>{imageData.bemerkung}</span>
								</div>
							</Fragment>
						)}
						{showConfirm && (
							<Modal
								isOpen={showConfirm}
								toggle={() => {
									setShowConfirm(false);
								}}
							>
								<ModalBody className='p-0'>
									<Card className='main-card'>
										<CardHeader>Foto löschen?</CardHeader>
										<CardBody>Wollen Sie das Foto wirklich löschen?</CardBody>
										<CardFooter className='d-block text-end'>
											<Button size='sm' className='me-2' color='danger' onClick={() => deleteButton()}>
												Ja, löschen!
											</Button>
											<Button size='sm' className='me-2' color='link' onClick={() => setShowConfirm(false)}>
												Schließen
											</Button>
										</CardFooter>
									</Card>
								</ModalBody>
							</Modal>
						)}
					</ModalBody>

					<ModalFooter>
						{(isTechniker || isIBSTechniker) && (
							<Button color='danger' onClick={() => setShowConfirm(true)}>
								Löschen
							</Button>
						)}
						<Button color='light' onClick={() => setShowModal(false)}>
							Schließen
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</Fragment>
	);
};

export default Photo;
