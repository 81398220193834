import React, { Fragment } from 'react';
import TooltipItem from './TooltipItem';

const pluralizeOpenOrders = (count) => {
	if (count === 0) {
		return 'Keine offenen Aufträge';
	} else if (count === 1) {
		return 'Ein offener Auftrag';
	}
	return `${count} offene Aufträge`;
};

const pluralizeWorkOrders = (count) => {
	if (count === 0) {
		return 'Keine Aufträge in Bearbeitung';
	} else if (count === 1) {
		return 'Ein Auftrag in Bearbeitung';
	}
	return `${count} Aufträge in Bearbeitung`;
};

const pluralizeDoneOrders = (count) => {
	if (count === 0) {
		return 'Keine Aufträge fertiggestellt';
	} else if (count === 1) {
		return 'Ein Auftrag fertiggestellt';
	}
	return `${count} Aufträge fertiggestellt`;
};

const OrderCountBadges = (props) => {
	const ttID = 'za-' + props.rowId;
	return (
		<Fragment>
			<TooltipItem
				id={ttID}
				placement='top'
				tooltip={pluralizeOpenOrders(props.openOrders)}
				element={
					<span className='badge bg-primary me-1' style={{ width: '40px', textAlign: 'center' }}>
						{props.openOrders}
					</span>
				}
			/>
			<TooltipItem
				id={'b' + ttID}
				placement='top'
				tooltip={pluralizeWorkOrders(props.ordersInProgress)}
				element={
					<span className='badge bg-info me-1' style={{ width: '40px', textAlign: 'center' }}>
						{props.ordersInProgress}
					</span>
				}
			/>
			<TooltipItem
				id={'done_' + ttID}
				placement='top'
				tooltip={pluralizeDoneOrders(props.doneOrders)}
				element={
					<span className='badge me-1' style={{ width: '40px', textAlign: 'center', backgroundColor: '#ABD473' }}>
						{props.doneOrders}
					</span>
				}
			/>
		</Fragment>
	);
};

export default OrderCountBadges;
