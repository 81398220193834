import React, { useEffect, useState } from 'react';
import useAuth from '../Hooks/useAuth';
import useRefreshToken from '../Hooks/useRefreshToken';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Loader from 'react-loaders';

const PersistLogin = () => {
	const [isLoading, setIsLoading] = useState(true);
	const refresh = useRefreshToken();
	const { auth, persist } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		let isMounted = true;

		const verifyRefreshToken = async () => {
			try {
				await refresh();
			} catch (error) {
				/* // Refresh token is also expired */
				if (error.response.status === 401) {
					navigate('/login', { state: { from: location }, replace: true });
				}
			} finally {
				isMounted && setIsLoading(false);
			}
		};

		!auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);
	}, []);

	return (
		<>
			{!persist ? (
				<Outlet />
			) : isLoading ? (
				<div className='loader-container'>
					<div className='loader-container-inner'>
						<div className='text-center'>
							<Loader type='ball-pulse-rise' />
						</div>
						<h6 className='mt-5'>Bitte warten Sie bis die Applikation vollständig geladen ist</h6>
					</div>
				</div>
			) : (
				<Outlet />
			)}
		</>
	);
};

export default PersistLogin;
