import { useState } from 'react';
import { Tooltip } from 'reactstrap';

const SyncStatus = (props) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const id = 'T' + props.id;
	let syncStatusClassName = '';
	let syncStatusTooltipContent = '';
	switch (props.syncStatus) {
		case 0:
			syncStatusClassName = 'bg-alternate';
			syncStatusTooltipContent = 'Wartet auf Synchronisation';
			break;
		case 1:
			syncStatusClassName = 'bg-success';
			syncStatusTooltipContent = 'Erfolgreich synchronisiert';
			break;
		case 10:
			syncStatusClassName = 'bg-info';
			syncStatusTooltipContent = 'Synchronsation läuft';
			break;
		default:
			syncStatusClassName = 'bg-danger';
			syncStatusTooltipContent = 'Synchronsation fehlgeschlagen';
	}

	const className = !!props.noPadding ? 'align-center' : 'align-center me-2';

	return (
		<div className={className}>
			<div id={id} className={`badge badge-dot badge-dot-lg ${syncStatusClassName}`}>
				&nbsp;
			</div>
			<Tooltip
				isOpen={tooltipOpen}
				placement={'right'}
				target={id}
				toggle={() => {
					setTooltipOpen(!tooltipOpen);
				}}
			>
				{syncStatusTooltipContent}
			</Tooltip>
		</div>
	);
};

export default SyncStatus;
