import './OrderAssignmentPlates.scss';
import React from 'react';
import TooltipItem from './TooltipItem';

const OrderAssignmentPlates = (props) => {
	const className = 'orderAssignmentPlates';
	const assignments = props.data.a;
	const builders = assignments.b;
	const technicians = assignments.t;
	const hideBuilder = props.hideBuilder;

	const builderClassName =
		`${className}__badge ` +
		(builders.length === 0 ? `${className}__badge--inaktiv` : builders.length === 1 ? `${className}__badge--aktiv` : `${className}__badge--aktiv-plus`);
	const techniciansClassName = `${className}__badge ` + (technicians.length > 0 ? `${className}__badge--aktiv` : `${className}__badge--inaktiv`);

	return (
		<div className={className}>
			{!hideBuilder && (
				<TooltipItem
					id={'b' + props.data.id}
					placement='top'
					tooltip={
						<div>
							<ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
								{assignments.b.map((acc, index) => {
									return (
										<li key={acc + '' + index} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
											{acc}
										</li>
									);
								})}
							</ul>
						</div>
					}
					element={<span className={builderClassName}>E</span>}
				/>
			)}
			{assignments.t.length > 0 ? (
				<TooltipItem
					id={'t' + props.data.id}
					placement='top'
					tooltip={
						<div>
							<ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
								{assignments.t.map((acc, index) => {
									return (
										<li key={acc + '' + index} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
											{acc}
										</li>
									);
								})}
							</ul>
						</div>
					}
					element={<span className={techniciansClassName}>T</span>}
				/>
			) : (
				<span className={techniciansClassName}>T</span>
			)}
		</div>
	);
};

export default OrderAssignmentPlates;
