import './OrderPosition.scss';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Alert,
	FormFeedback,
	Label,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Modal,
	ModalBody,
	CardFooter,
	FormGroup,
	Input,
	InputGroup,
	ListGroup,
	ListGroupItem,
} from 'reactstrap';
import SyncStatus from '../SyncStatus';
import { faEdit, faFilePdf, faCarSide, faBuilding, faClock, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipItem from '../TooltipItem';
import { Link } from 'react-router-dom';
import { supplementReturnTrip, cancelOrderPosition, getSelectedOrder, saveNewKmForPosition } from '../../../../reducers/OrderOverviewSlice';
import { toast, Bounce } from 'react-toastify';
import { convertTimeStringToMinutes, isTimeStringBeforeAnother } from './HelperFunctions';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import useAuth from '../../Hooks/useAuth';
import { API_DOWNLOAD_ORDER_PDF } from '../../API/endpoints';
import { ERROR_UNREALISTIC_MILEAGE } from '../../Technician/Components/ErrorMessages';
import CollisionMessage from '../../Technician/Components/CollisionMessage';

const OrderPosition = (props) => {
	const className = 'orderPosition';
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const selectedOrder = useSelector(getSelectedOrder);
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [showReturnTripForm, setShowReturnTripForm] = useState(false);

	const [backFrom, setBackFrom] = useState('');
	const [backFromError, setBackFromError] = useState('');
	const [backTo, setBackTo] = useState('');
	const [kmError, setKmError] = useState('');
	const [backToError, setBackToError] = useState('');
	const [backMileage, setBackMileage] = useState('');
	const [backMileageError, setBackMileageError] = useState('');
	const axios = useAxiosPrivate();
	const { isTechniker, isIBSTechniker, isAdmin } = useAuth();
	const [submitting, setSubmitting] = useState(false);
	const [kmEdit, setKmEdit] = useState(null);

	const spacerStyle = {
		fontSize: '1rem',
		fontWeight: '600',
		borderBottom: '1px solid black',
		marginRight: '5px',
		marginLeft: '5px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};

	const pos = props.position;
	const colorClass = () => {
		if (pos.storniert) {
			return '#77777722';
		}

		if (pos.fertig) {
			return '#00ff2422';
		}

		return 'transparent';
	};

	const expandClass = open ? 'lnr-chevron-up' : 'lnr-chevron-down';
	const isEditable = !pos.fertig && !pos.storniert && (isTechniker || isIBSTechniker);
	const editID = 'pos-edit-' + pos.id;
	const toggleID = 'tog-' + pos.id;
	const cancelID = 'can-' + pos.id;
	const pdfID = 'pdf-' + pos.id;

	const showConfirmDialog = () => {
		setConfirmCancel(true);
	};

	const neuenKmSpeichern = async (km) => {
		dispatch(saveNewKmForPosition({ axios, k: km, p: kmEdit.id }))
			.then((response) => {
				if (response?.error?.message.indexOf('401') >= 0) {
					navigate('/login', { state: { from: location }, replace: true });
				}

				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				props.reloadOrderDetails();

				setKmEdit(null);
				setKmError('');
			})
			.catch((reason) => {
				toast('Km-Stand konnte nicht aktualisiert werden', {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});

				setKmError('Es ist ein Fehler aufgetreten');
			});
	};

	const downloadDocument = async (position) => {
		const url = API_DOWNLOAD_ORDER_PDF + position.id;
		await axios({
			url: url,
			method: 'GET',
			responseType: 'blob', // important
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Auftrag_' + selectedOrder + '.pdf');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((reason) => {
				toast('Das angeforderte Dokument konnte nicht heruntergeladen werden.', {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			});
	};

	const cancelPosition = () => {
		dispatch(cancelOrderPosition({ axios, orderPosition: pos.id })).then((response) => {
			if (response.payload.status === 'ok') {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				props.reloadOrderDetails();
				setConfirmCancel(false);
			} else {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	const toggle = () => {
		setConfirmCancel(!confirmCancel);
	};

	const toggleReturnTrip = () => {
		setShowReturnTripForm(!showReturnTripForm);
	};

	const INVALID_TIME_MISSING = 'Uhrzeit fehlt';
	const INVALID_TIME_FIELD = 'Geben Sie eine gülitge Uhrzeit ein';
	const INVALID_TIMESPAN = 'Eingegebener Zeitraum ist ungültig';
	const INVALID_END_BEFORE_START = 'Die Rückfahrt kann nicht vor dem Ende der Arbeitszeit begonnen haben';
	const INVALID_MISSING_MILEAGE = 'Geben Sie die Rückfahrstrecke in km an';
	const INVALID_NOT_A_NUMBER = 'Geben Sie eine gültige Zahl ein';
	const INVALID_NEGATIVE_MILEAGE = 'Geben Sie einen positiven km-Wert ein';
	const REGEX_24H_TIME = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;

	const getValueOfTimeField = (timestring) => {
		const parts = timestring.split(':');

		return parts[0] * 60 + parts[1] * 1;
	};

	const submitReturnTrip = () => {
		//hier muss erstmal validiert werden!
		// werte passen aber!
		const errors = [];
		const isBackFromEmpty = backFrom === '';
		const isBackToEmpty = backTo === '';
		const isBackFromValid = REGEX_24H_TIME.test(backFrom);
		const isBackToValid = REGEX_24H_TIME.test(backTo);

		if (isBackFromEmpty) {
			setBackFromError(INVALID_TIME_MISSING);
			errors.push(INVALID_TIME_MISSING);
		} else if (!isBackFromValid) {
			setBackFromError(INVALID_TIME_FIELD);
			errors.push('Von: ' + INVALID_TIME_FIELD);
		} else {
			setBackFromError('');
		}

		if (isBackToEmpty) {
			setBackToError(INVALID_TIME_MISSING);
			errors.push(INVALID_TIME_MISSING);
		} else if (!isBackToValid) {
			setBackToError(INVALID_TIME_FIELD);
			errors.push('Bis: ' + INVALID_TIME_FIELD);
		} else {
			setBackToError('');
		}

		if (!isBackFromEmpty && isBackFromValid && !isBackToEmpty && isBackToValid) {
			const isTimespanValid = isTimeStringBeforeAnother(backFrom, backTo);
			if (!isTimespanValid) {
				setBackFromError(INVALID_TIMESPAN);
				setBackToError(INVALID_TIMESPAN);
				errors.push(INVALID_TIMESPAN);
			}

			const returnTripStart = convertTimeStringToMinutes(backFrom);
			const endOfWork = convertTimeStringToMinutes(pos.arbeitsende);

			if (endOfWork > returnTripStart) {
				setBackFromError(INVALID_END_BEFORE_START);
				errors.push(INVALID_END_BEFORE_START);
			}

			if (!backMileage) {
				setBackMileageError(INVALID_MISSING_MILEAGE);
				errors.push(INVALID_MISSING_MILEAGE);
			} else if (isNaN(backMileage)) {
				setBackMileageError(INVALID_NOT_A_NUMBER);
				errors.push(INVALID_NOT_A_NUMBER);
			} else if (backMileage < 0) {
				setBackMileageError(INVALID_NEGATIVE_MILEAGE);
				errors.push(INVALID_NEGATIVE_MILEAGE);
			} else {
				setBackMileageError('');
			}
		}

		if (backMileage !== '') {
			// Hier ist das Format richtig, aber es muss geprüft werden ob die gefahrenen KM auch realistisch sind.
			let fahrzeit = 0;
			if (!isBackFromEmpty && !isBackToEmpty) {
				fahrzeit += getValueOfTimeField(backTo) - getValueOfTimeField(backFrom);
			}

			fahrzeit = fahrzeit / 60;

			if (fahrzeit > 0) {
				const kmh = backMileage / fahrzeit;

				if (fahrzeit > 4) {
					if (kmh > 140) {
						errors.push(ERROR_UNREALISTIC_MILEAGE);
						setBackMileageError(ERROR_UNREALISTIC_MILEAGE);
					}
				} else if (fahrzeit > 2) {
					if (kmh > 130) {
						errors.push(ERROR_UNREALISTIC_MILEAGE);
						setBackMileageError(ERROR_UNREALISTIC_MILEAGE);
					}
				} else if (fahrzeit > 1) {
					if (kmh > 120) {
						errors.push(ERROR_UNREALISTIC_MILEAGE);
						setBackMileageError(ERROR_UNREALISTIC_MILEAGE);
					}
				} else {
					if (kmh > 110) {
						errors.push(ERROR_UNREALISTIC_MILEAGE);
						setBackMileageError(ERROR_UNREALISTIC_MILEAGE);
					}
				}
			}
		}

		// Hier sind die Fehler gesetzt/zurückgesetzt
		if (errors.length === 0) {
			// Hier kann submitted werden
			const formData = new FormData();
			formData.append('backFrom', backFrom);
			formData.append('backTo', backTo);
			formData.append('mileage', backMileage);
			setSubmitting(true);
			// dispatch that shit with URL and formdata
			dispatch(supplementReturnTrip({ axios: axios, position: pos.id, form: formData }))
				.then((response) => {
					if (response.payload.status === 'ok') {
						toast(response.payload.message, {
							transition: Bounce,
							closeButton: true,
							autoClose: 5000,
							position: 'top-right',
							type: 'success',
						});
						setShowReturnTripForm(false);
						props.reloadOrderDetails();
					} else if (response.payload.status === 'kollision') {
						response.payload.data.forEach((c) => {
							toast(<CollisionMessage collision={c} />, {
								transition: Bounce,
								closeButton: true,
								autoClose: 5000,
								position: 'top-right',
								type: 'error',
							});
						});
					} else {
						toast(response.payload.message, {
							transition: Bounce,
							closeButton: true,
							autoClose: 5000,
							position: 'top-right',
							type: 'error',
						});
					}
					setSubmitting(false);
				})
				.catch((reason) => {
					setSubmitting(false);
				});
		}
	};

	let listGroupClassName = `${className}__listGroup`;
	let headlineClassName = `${className}__headline`;
	if (!isEditable && pos.dokumente) {
		listGroupClassName += ` ${className}__listGroup--done`;
		headlineClassName += ` ${className}__headline--done`;
	}
	return (
		<Fragment>
			<ListGroupItem className={listGroupClassName}>
				<Row>
					<Col>
						<div className='widget-content-wrapper'>
							<div className='widget-content p-0'>
								<div className='widget-content-left' style={{ marginBottom: '.5rem' }}>
									<div className={headlineClassName}>
										{pos.erledigtDurchBenutzer ? `${pos.erledigtDurchBenutzer.vorname} ${pos.erledigtDurchBenutzer.nachname}` : '-'}
									</div>
									<div className={`${className}__date`}>{pos.datum}</div>
									<Row className=''>
										<Col xs={12} md={3} className={`${className}__nameValue`}>
											<div>Hinfahrt: </div>
											<div>
												{pos.hinfahrtBeginn} - {pos.hinfahrtEnde}
											</div>
										</Col>
										<Col xs={12} md={3} className={`${className}__nameValue`}>
											<div>Arbeitszeit: </div>
											<div>
												{pos.arbeitsbeginn} - {pos.arbeitsende}
											</div>
										</Col>
										<Col xs={12} md={3} className={`${className}__nameValue`}>
											<div>Rückfahrt:</div>
											<div>
												{!pos.rueckfahrtBeginn && !pos.rueckfahrtEnde ? (
													pos.fertig && (isTechniker || isIBSTechniker) ? (
														<Button color='link' className='p-0 m-0' onClick={toggleReturnTrip}>
															Nachtragen
														</Button>
													) : (
														'-'
													)
												) : (
													pos.rueckfahrtBeginn + ' - ' + pos.rueckfahrtEnde
												)}
											</div>
										</Col>
										<Col xs={12} md={3} className={`${className}__nameValue`}>
											<div>Fahrtlänge:</div>
											<div
												onClick={(e) => {
													e.preventDefault();
													if (isAdmin && e.ctrlKey && e.button === 0) {
														setKmEdit(pos);
													}
												}}
											>
												{!!pos.km ? `${pos.km} km` : '-'}
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={'auto'}>
						<div className={`${className}__buttons`}>
							{isEditable && (
								<Fragment>
									<span className='edit'>
										<TooltipItem
											id={editID}
											placement='left'
											tooltip={'Position bearbeiten'}
											element={
												<Link className='btn btn-icon btn-icon-only p-0 m-0' to={'/techniker/position/bearbeiten/' + pos.id}>
													<FontAwesomeIcon icon={faEdit} className={`btn-icon-wrapper ${className}__editButtonIcon`} />
												</Link>
											}
										/>
									</span>
									<span className='delete'>
										<TooltipItem
											id={cancelID}
											placement='left'
											tooltip={'Position stornieren'}
											element={
												<Button
													className={`btn-icon-wrapper ${className}__deleteButtonIcon`}
													color='link'
													onClick={() => showConfirmDialog()}
												>
													<i className={`lnr-trash btn-icon-wrapper`} />
												</Button>
											}
										/>
									</span>
								</Fragment>
							)}
							{!isEditable && pos.dokumente && (
								<span className='btn btn-icon m-0 p-0 done'>
									<FontAwesomeIcon icon={faCheck} className={`btn-icon-wrapper  p-0 m-0 ${className}__doneButtonIcon`} />
								</span>
							)}
							{!isEditable && pos.dokumente && (
								<Fragment>
									<span className={'download'}>
										<TooltipItem
											id={pdfID}
											placement='left'
											tooltip={'Auftragszettel herunterladen'}
											element={
												<a onClick={() => downloadDocument(pos)} className='btn btn-icon btn-icon-only' color='link'>
													<FontAwesomeIcon icon={faFilePdf} className={`btn-icon-wrapper ${className}__downloadButtonIcon`} />
												</a>
											}
										/>
									</span>
								</Fragment>
							)}
							<span className={`toggle ${open ? 'toggle--open' : 'toggle--closed'}`}>
								<TooltipItem
									id={toggleID}
									placement='left'
									tooltip={open ? 'Einklappen' : 'Ausklappen'}
									element={
										<Button className='btn-icon btn-icon-only m-0 p-0' color='link' onClick={() => setOpen(!open)}>
											<i className={`${expandClass} btn-icon-wrapper ${className}__toggleButtonIcon`} />
										</Button>
									}
								/>
							</span>
						</div>
					</Col>
				</Row>

				{open && (
					<Row>
						<Col>
							<div className='mb-3'>
								<div className=''>Arbeiten </div>
								<div
									className=''
									style={{ fontWeight: 600 }}
									dangerouslySetInnerHTML={{
										__html: !!pos.arbeiten ? pos.arbeiten.replace(new RegExp('\r?\n', 'g'), '<br />') : '-',
									}}
								></div>
							</div>
							{(isIBSTechniker || isAdmin) && (
								<>
									<div className=''>Gebuchte Artikel </div>
									<div>
										{pos.artikelliste.length === 0 && <>-</>}
										{pos.artikelliste.map((a) => (
											<div key={a.id} className={`d-flex justify-content-between ${className}__materialRow`}>
												<div>
													<div className={`${className}__label`}>{a.bezeichnung}</div>
													<div className={`${className}__value`}>Artikel-Nr: {a.artikelNr}</div>
												</div>
												<div className={`${className}__itemCount d-flex align-items-center`}>
													{a.menge} {a.einheit}
												</div>
											</div>
										))}
									</div>
								</>
							)}

							<div className=''>Material </div>
							<div
								className='mb-3'
								style={{ fontWeight: 600 }}
								dangerouslySetInnerHTML={{ __html: !!pos.material ? pos.material.replace(new RegExp('\r?\n', 'g'), '<br />') : '-' }}
							></div>
							<div />
						</Col>
						<Col lg={2} className={'align-self-end mb-3'}>
							<div className=''>Erstellt von</div>
							<div className='' style={{ fontWeight: 600 }}>
								{pos.erstelltDurchBenutzer
									? `${
											!!pos.erstelltDurchBenutzer.vorname || !!pos.erstelltDurchBenutzer.nachname
												? pos.erstelltDurchBenutzer.vorname + ' ' + pos.erstelltDurchBenutzer.nachname
												: pos.erstellerLogin
									  }`
									: '-'}
							</div>
						</Col>
					</Row>
				)}
				{showReturnTripForm && (
					<Modal isOpen={showReturnTripForm} toggle={toggleReturnTrip}>
						<ModalBody className='p-0'>
							<Card className='main-card'>
								<CardHeader>
									<i className='header-icon lnr-earth icon-gradient bg-plum-plate'> </i>
									Rückfahrt buchen
								</CardHeader>
								<CardBody>
									<Row>
										<Col>
											<Alert color='info'>
												<strong>Hinweis: </strong>Sie können einmalig die Dauer und Länge der Rückfahrt protokollieren. Sollten Sie bei
												Ihrer Eingabe einen Fehler machen, können Sie diesen nicht mehr eigenständig korrigieren. Das ist dann nur noch
												über die ibs Sicherheitstechnik möglich.{' '}
											</Alert>
										</Col>
									</Row>
									<Row className='mb-3'>
										<Col>
											<div className='fs-12 lh-sm'>Datum</div>
											<div className='fs-14 fw-bold lh-sm'>{pos.datum}</div>
										</Col>

										<Col>
											<div className='fs-12 lh-sm'>Hinfahrt</div>
											<div className='fw-bold lh-sm'>
												{pos.hinfahrtBeginn} - {pos.hinfahrtEnde} Uhr
											</div>
										</Col>
										<Col>
											<div className='fs-12 lh-sm'>Arbeitszeit</div>
											<div className='fs-14 fw-bold lh-sm'>
												{pos.arbeitsbeginn} - {pos.arbeitsende} Uhr
											</div>
										</Col>
									</Row>
									<Row className='mb-3'>
										<Col>
											<div className='fs-12 lh-sm'>Tätigkeit</div>
											<div className='fs-14 fw-bold lh-sm'>{pos.arbeiten}</div>
										</Col>
									</Row>
									<Row className='mb-3'>
										<Col xs='auto'>
											<FontAwesomeIcon icon={faCarSide} size='2x' />
										</Col>
										<Col style={{ ...spacerStyle, ...{ color: '#3ac47d', borderBottom: '2px solid #3ac47d' } }}>{pos.km} km</Col>
										<Col xs='auto'>
											<FontAwesomeIcon icon={faBuilding} size='2x' />
										</Col>
										<Col style={{ ...spacerStyle, ...{ color: '#f7b924', borderBottom: '2px solid #f7b924' } }}>? km</Col>
										<Col xs='auto'>
											<FontAwesomeIcon icon={faCarSide} size='2x' />
										</Col>
									</Row>
									<Row>
										<Col>
											<Label>Rückfahrt von - bis</Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<InputGroup className='mb-3'>
												<div className='input-group-text'>
													<FontAwesomeIcon icon={faClock} />
												</div>
												<Input
													name='rueckVon'
													type='time'
													defaultValue={backFrom}
													maxLength={5}
													onChange={(e) => setBackFrom(e.target.value)}
													invalid={backFromError !== ''}
												/>
												<FormFeedback>{backFromError}</FormFeedback>
											</InputGroup>
										</Col>
										<Col xs='auto'>-</Col>
										<Col>
											<InputGroup className='mb-3'>
												<div className='input-group-text'>
													<FontAwesomeIcon icon={faClock} />
												</div>
												<Input
													name='rueckBis'
													type='time'
													defaultValue={backTo}
													maxLength={5}
													onChange={(e) => setBackTo(e.target.value)}
													invalid={backToError !== ''}
												/>
											</InputGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormGroup>
												<Label>Länge der Rückfahrt</Label>
												<Input
													name='rueckKm'
													id='rueckKm'
													type='number'
													placeholder='km'
													defaultValue={backMileage}
													maxLength={5}
													onChange={(e) => {
														setBackMileage(e.target.value);
													}}
													invalid={backMileageError !== ''}
												/>
												<FormFeedback>{backMileageError}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								</CardBody>
								<CardFooter className='d-block text-end'>
									<Button size='sm' className='me-2' color='success' onClick={submitReturnTrip} disabled={submitting}>
										Rückfahrt buchen
									</Button>
									<Button size='sm' className='me-2' color='link' onClick={toggleReturnTrip}>
										abbrechen
									</Button>
								</CardFooter>
							</Card>
						</ModalBody>
					</Modal>
				)}
				{confirmCancel && (
					<Modal isOpen={confirmCancel} toggle={toggle}>
						<ModalBody className='p-0'>
							<Card className='main-card'>
								<CardHeader>
									<i className='header-icon lnr-earth icon-gradient bg-plum-plate'> </i>
									Auftragsposition stornieren?
								</CardHeader>
								<CardBody>Soll die Auftragsposition wirklich storniert werden? Alle erfassten Informationen gehen verloren.</CardBody>
								<CardFooter className='d-block text-end'>
									<Button size='sm' className='me-2' color='danger' onClick={cancelPosition}>
										Stornieren
									</Button>
									<Button size='sm' className='me-2' color='link' onClick={toggle}>
										Schließen
									</Button>
								</CardFooter>
							</Card>
						</ModalBody>
					</Modal>
				)}
				{!!kmEdit && (
					<Modal isOpen={!!kmEdit} toggle={() => setKmEdit(null)}>
						<ModalBody className='p-0'>
							<Card className='main-card'>
								<CardHeader>
									<i className='header-icon lnr-earth icon-gradient bg-plum-plate'> </i>
									KM-Stand überschreiben
								</CardHeader>
								<CardBody>
									<div>Korrigieren Sie den Kilometerstand ohne Rücksicht auf die vorgeschriebenen Grenzwerte</div>
									<div>
										<Input
											type='number'
											min={0}
											max={999}
											name='newKm'
											id='newKm'
											defaultValue={kmEdit.km}
											onChange={(e) => {
												const inputValue = e.target.value;
												if (!isNaN(inputValue) && inputValue !== '') {
													if (inputValue < 0 || inputValue >= 1000) {
														setKmError('Ungültige Eingabe');
													} else {
														setKmError('');
													}
												} else {
													setKmError('Ungültige Eingabe');
												}
											}}
											invalid={!!kmError}
										/>
									</div>
								</CardBody>
								<CardFooter className='d-block text-end'>
									<Button size='sm' className='me-2' color='success' onClick={() => neuenKmSpeichern(document.getElementById('newKm').value)}>
										Speichern
									</Button>
									<Button size='sm' className='me-2' color='link' onClick={() => setKmEdit(null)}>
										Schließen
									</Button>
								</CardFooter>
							</Card>
						</ModalBody>
					</Modal>
				)}
			</ListGroupItem>
		</Fragment>
	);
};

export default OrderPosition;
