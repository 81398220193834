import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import CreateTechnicianForm from '../Components/Builder/CreateTechnicianForm';

import Dashboard from '../Components/Builder/Dashboard';
import { OrderOverview } from '../Components/Builder/OrderOverview';
import OrderShow from '../Components/Builder/OrderShow';
import TechnicianAssignOrders from '../Components/Builder/TechnicianAssignOrders';
import TechnicianOverview from '../Components/Builder/TechnicianOverview';
import ChangePasswordForm from '../Components/Common/ChangePasswordForm';

import AppHeader from '../Components/Menu/AppHeader';
import AppSidebar from '../Components/Menu/AppSidebar';

const BuilderDashboard = () => (
	<Fragment>
		<AppHeader navigationType={'builder'} />
		<div className='app-main'>
			<AppSidebar navigationType='builder' />
			<div className='app-main__outer'>
				<div className='app-main__inner'>
					<Routes>
						<Route path={`/dashboard`} element={<Dashboard />} />
						<Route path={`/auftraege`} element={<OrderOverview />} />
						<Route path={`/techniker-anlegen/:technikerID`} element={<CreateTechnicianForm />} />
						<Route path={`/techniker-auftrag/zuweisen/:technikerID`} element={<TechnicianAssignOrders />} />
						<Route path={`/techniker`} element={<TechnicianOverview />} />
						<Route path={`/auftrag/ansehen`} element={<OrderShow />} />
						<Route path={`/auftrag/techniker/zuweisen`} element={<OrderShow assignTechnicians />} />
					</Routes>
				</div>
			</div>
		</div>
	</Fragment>
);

export default BuilderDashboard;
