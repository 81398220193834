import './UnassignedPieComponent.scss';
import React, { Fragment } from 'react';
import { ResponsivePie } from '@nivo/pie';

const UnassignedPieComponent = (props) => {
	const stats = props.statistik;
	const className = 'unassignedPieComponent';
	const data = [
		{
			id: 'Techniker zugewiesen und gestartet',
			label: 'Techniker zugewiesen und gestartet',
			value: stats.gestartetMT,
			backgroundColor: '#ABD473',
		},
		{
			id: 'Techniker zugewiesen',
			label: 'Techniker zugewiesen',
			value: stats.mitTechniker,
			backgroundColor: '#33937f',
		},
		{
			id: 'Gestartet ohne Technikerzuordnung',
			label: 'Gestartet ohne Technikerzuordnung',
			value: stats.gestartetOT,
			backgroundColor: '#C41F3B',
		},
		{
			id: 'Ohne Techniker',
			label: 'Ohne Techniker',
			value: stats.ohneTechniker,
			backgroundColor: '#FF7D0A',
		},
	];

	const filtered = data.filter((value) => value.value !== 0);

	return (
		<Fragment>
			<div className={`${className}__legend`}>
				{filtered.map((value, index) => {
					return (
						<span key={index} className={`${className}__legendItem`}>
							<span className={`${className}__legendItemColor`} style={{ backgroundColor: value.backgroundColor }} />
							<span>{value.label}</span>
						</span>
					);
				})}
			</div>
			<div className={className}>
				<ResponsivePie
					data={filtered}
					margin={{ top: 60, right: 60, bottom: 60, left: 60 }}
					innerRadius={0.8}
					padAngle={0.7}
					cornerRadius={3}
					colors={filtered.map((data) => data.backgroundColor)}
					activeOuterRadiusOffset={8}
					borderWidth={1}
					borderColor={{
						from: 'color',
						modifiers: [['darker', 0.2]],
					}}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsTextColor='#333333'
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: 'color' }}
					arcLabelsSkipAngle={10}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 2]],
					}}
				/>
				<div className={`${className}__innerCircle`}>
					<div className={`${className}__label`}>{stats.gesamt} offene Aufträge</div>
				</div>
			</div>
		</Fragment>
	);
};

export default UnassignedPieComponent;
