import React, { Fragment } from 'react';
import TooltipItem from './TooltipItem';

const EMailFields = (props) => {
	if (props.emails === '') {
		return <em>Keine E-Mail Adresse angegeben</em>;
	}
	const emailArray = props.emails.replace(/\r\n/g, ' ').split(' ');
	emailArray.forEach((email, index) => {
		const em = email.replace(';', '');

		if (em.trim() === '') {
			delete emailArray[index];
		}
	});

	return (
		<Fragment>
			{emailArray.map((email, idx) => {
				const ttID = 'em-' + Math.ceil(Math.random() * 10000000);
				return (
					<TooltipItem
						key={ttID}
						id={ttID}
						placement='top'
						tooltip={email}
						element={
							<a href={'mailto:' + email} key={ttID} className='badge bg-light me-1' style={{ cursor: 'pointer' }}>
								@{email.split('@')[0]}
							</a>
						}
					/>
				);
			})}
		</Fragment>
	);
};

export default EMailFields;
