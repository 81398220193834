import './Common.scss';
import React, { Fragment } from 'react';
import { Col, Button } from 'reactstrap';
import logo from '../../../assets/images/ibs_logo_tp.png';
import { useNavigate } from 'react-router-dom';

// Layout
const Forbidden = ({ match }) => {
	const navigate = useNavigate();
	return (
		<Fragment>
			<div className={`ibsBackground h-100 pt-md-5 `}>
				<div className=''>
					<Col md='4' className='mx-auto app-login-box shadowBox p-5'>
						<div className='mx-auto mb-3'>
							<img src={logo} width='150' alt='' />
						</div>
						<div className=''>
							<h4 className='mt-2'>Zugriff nicht möglich</h4>
							<p>Sie haben nicht die entsprechenden Berechtigungen um auf die von Ihnen aufgerufene Seite zuzugreifen.</p>
							<p>
								<Button color='danger' size='lg' block tag='a' onClick={(e) => navigate(-1)}>
									Zurück zur vorherigen Seite
								</Button>
							</p>
						</div>
					</Col>
				</div>
			</div>
		</Fragment>
	);
};

export default Forbidden;
