import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Loader } from 'react-loaders';
import { fetchBuilderDashboardStatistics, fetchBuilderOrderAssignmentDashboardStatistics } from '../../Services/StatisticsHandler';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import PieComponent from './PieComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import UnassignedPieComponent from './UnassignedPieComponent';
import LoadingDataComponent from '../Common/LoadingDataComponent';

const DashboardCards = (props) => {
	const dispatch = useDispatch();
	const [counts, setCounts] = useState(null);
	const [loading, setLoading] = useState(false);
	const axios = useAxiosPrivate();
	const [assignmentCounts, setAssignmentCounts] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setLoading(true);
		dispatch(fetchBuilderDashboardStatistics({ axios })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}
			setLoading(false);
			setCounts(response.payload);
		});
		dispatch(fetchBuilderOrderAssignmentDashboardStatistics({ axios })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}
			setAssignmentCounts(response.payload);
		});
	}, []);

	return (
		<LoadingOverlay
			tag='div'
			active={loading}
			styles={{
				overlay: (base) => ({
					...base,
					background: '#fff',
					opacity: 0.5,
				}),
			}}
			spinner={<Loader color='#ffffff' active type={'line-scale-pulse-out'} />}
		>
			{!!counts ? (
				<Fragment>
					<Row className='g-0'>
						<Col className={' mb-4'} sm='12' lg='6' xl='6'>
							<div className='card no-shadow rm-border bg-transparent widget-chart text-start'>
								<div className='icon-wrapper rounded-circle'>
									<div className='icon-wrapper-bg opacity-9 bg-alternate' />
									<i className='lnr-construction text-white' />
								</div>
								<div className='widget-chart-content'>
									<div className='widget-subheading'>Aufträge</div>
									<div className='widget-numbers'>
										<CountUp
											start={0}
											end={counts.gesamt}
											separator=''
											decimals={0}
											decimal=','
											prefix=''
											useEasing={false}
											suffix=' Aufträge'
											duration='1'
										/>
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className={' mb-4'} sm='12' lg='6' xl='6'>
							<PieComponent typen={counts.typen} />
						</Col>
						<Col className={' mb-4'} sm='12' lg='6' xl='6'>
							{!!assignmentCounts && (
								<div>
									<UnassignedPieComponent statistik={assignmentCounts} />
								</div>
							)}
						</Col>
					</Row>
				</Fragment>
			) : (
				<LoadingDataComponent />
			)}
		</LoadingOverlay>
	);
};

export default DashboardCards;
