import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_ADMIN_BUILDER_URL } from '../App/Components/API/endpoints';

const initialState = {
	total: 0,
	selectedBuilder: null,
	filter: { type: 'all', search: '' },
	page: 1,
	perPage: 10,
	entries: [],
	status: 'idle', // idle | loading | succeeded | failed
	error: null,
	sortBy: 'name',
	sortOrder: 'ASC',
};

export const fetchAdminBuilders = createAsyncThunk('orders/fetchAdminBuilders', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&type=${arg.filter.type}&search=${arg.filter.search}`;
	const response = await axios.get(API_ADMIN_BUILDER_URL + params);
	return response.data;
});

export const BuilderOverviewSlice = createSlice({
	name: 'builders',
	initialState,
	reducers: {
		resetBuilderFilter: {
			reducer(state, action) {
				state.filter = { type: 'all', search: '' };
			},
			prepare() {
				return {};
			},
		},
		setSelectedBuilder: {
			reducer(state, action) {
				state.selectedBuilder = action.payload.selectedBuilder;
			},
			prepare(selectedBuilder) {
				return {
					payload: {
						selectedBuilder: selectedBuilder,
					},
				};
			},
		},
		setEntries: {
			reducer(state, action) {
				state.entries = action.payload.entries;
			},
			prepare(entries) {
				return {
					payload: {
						entries: entries,
					},
				};
			},
		},
		setPerPage: {
			reducer(state, action) {
				state.perPage = action.payload.perPage;
			},
			prepare(perPage) {
				return {
					payload: {
						perPage,
					},
				};
			},
		},
		setTotal: {
			reducer(state, action) {
				state.total = action.payload.total;
			},
			prepare(total) {
				return {
					payload: {
						total,
					},
				};
			},
		},
		setPage: {
			reducer(state, action) {
				state.page = action.payload.page;
			},
			prepare(page) {
				return { payload: { page } };
			},
		},
		setFilter: {
			reducer(state, action) {
				state.filter = { type: action.payload.type, search: action.payload.search };
			},
			prepare(type, search) {
				return {
					payload: {
						type,
						search,
					},
				};
			},
		},
		setSortByAndSortOrder: {
			reducer(state, action) {
				state.sortBy = action.payload.sortBy;
				state.sortOrder = action.payload.sortOrder;
			},
			prepare(sortBy, sortOrder) {
				return {
					payload: {
						sortBy,
						sortOrder,
					},
				};
			},
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchAdminBuilders.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchAdminBuilders.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchAdminBuilders.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { setEntries, setPerPage, setPage, setTotal, setFilter, setSortByAndSortOrder, setSelectedBuilder, resetBuilderFilter } =
	BuilderOverviewSlice.actions;

export default BuilderOverviewSlice.reducer;

export const getPage = (state) => state.builderOverview.page;
export const getPerPage = (state) => state.builderOverview.perPage;
export const getTotal = (state) => state.builderOverview.total;
export const getBuilders = (state) => state.builderOverview.entries;
export const getStatus = (state) => state.builderOverview.status;
export const getError = (state) => state.builderOverview.error;
export const getSortColumn = (state) => state.builderOverview.sortBy;
export const getSortOrder = (state) => state.builderOverview.sortOrder;
export const getSelectedBuilder = (state) => state.builderOverview.selectedBuilder;
export const getFilter = (state) => state.builderOverview.filter;
