import './PageTitle.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { smallDisplay } from '../../App/Components/Technician/Components/Services';

/* Beispiele für die headerActionsKomponente
import TitleComponent1 from './PageTitleExamples/Variation1';
import TitleComponent2 from './PageTitleExamples/Variation2';
import TitleComponent3 from './PageTitleExamples/Variation3';
*/

class PageTitle extends Component {
	render() {
		let { enablePageTitleIcon, enablePageTitleSubheading, headerActions, heading, icon, subheading, alternate } = this.props;

		const className = alternate ? 'app-page-title' : 'app-page-title';
		const style = alternate ? { top: '0', position: 'relative', width: '100%', margin: '0 0 1px', padding: '1rem' } : null;

		return (
			<div className={className} style={style}>
				<div className='page-title-wrapper'>
					<div className='page-title-heading'>
						<div
							className={cx('page-title-icon', {
								'd-none': !enablePageTitleIcon,
							})}
						>
							<i className={icon} />
						</div>
						<div>
							{heading}
							<div
								className={cx('page-title-subheading', {
									'd-none': !enablePageTitleSubheading,
								})}
							>
								{subheading}
							</div>
						</div>
					</div>
					<div className='page-title-actions'>{headerActions}</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
	enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
