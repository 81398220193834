import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_FETCH_USER_INFORMATION, API_UPDATE_USER_PASSWORD, BASE_URL } from '../Components/API/endpoints';

export const fetchUserInformation = createAsyncThunk('user/fetchUserInformation', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FETCH_USER_INFORMATION, { benutzer: arg.user }, { withCredentials: true, baseURL: BASE_URL });
	return response;
});

export const updatePassword = createAsyncThunk('user/updateUserPassword', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_UPDATE_USER_PASSWORD, { passwort: arg.passwort });
	return response;
});
