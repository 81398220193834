import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import { Checkbox } from '../Admin/Components/MessageForm';

const MessageComponent = (props) => {
	const { title, content, template } = props;
	const hideCheckbox = !!props.hideCheckbox;
	const action = !!props.action ? props.action : null;

	return (
		<div>
			<Card className={`main-card card-border border-${template}`}>
				<CardHeader className={`${template !== 'default' ? 'text-white' : ''} bg-${template}`}>{title}</CardHeader>
				<CardBody className={``}>
					{content.split('\n').map((line, index) => (
						<React.Fragment key={index}>
							{line}
							<br />
						</React.Fragment>
					))}
				</CardBody>
				<CardFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
					{!hideCheckbox ? <Checkbox id={1000} className='form-check-input' label={'nicht mehr anzeigen'} /> : <span />}
					<Button color={'light'} onClick={action}>
						Schließen
					</Button>
				</CardFooter>
			</Card>
		</div>
	);
};

export default MessageComponent;
