import './Common.scss';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Col, Label, Row, Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import logo from '../../../assets/images/ibs_logo_tp.png';
import { useDispatch } from 'react-redux';
import { benutzerRegistrieren, fetchRegistrationInformation } from '../../Services/LoginHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, Bounce } from 'react-toastify';

// Layout
const Register = () => {
	const dispatch = useDispatch();
	const { code } = useParams();

	const benutzernameRef = useRef();
	const [benutzername, setBenutzername] = useState('');
	const [benutzernameError, setBenutzernameError] = useState('');

	const passwortRef = useRef();
	const [passwort, setPasswort] = useState('');
	const [passwortError, setPasswortError] = useState('');

	const passwortWiederholungRef = useRef();
	const [passwortWiederholung, setPasswortWiederholung] = useState('');

	const pinRef = useRef();
	const [pin, setPin] = useState('');
	const [pinError, setPinError] = useState('');

	const [vorname, setVorname] = useState('');
	const [nachname, setNachname] = useState('');

	const [authError, setAuthError] = useState('');

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchRegistrationInformation({ code: code })).then((response) => {
			if (response.payload.status === 200) {
				if (response.payload.data.status === 0) {
					setVorname('');
					setNachname('');
					setAuthError(response.payload.data.message);
				} else if (response.payload.data.status === 1) {
					setVorname(response.payload.data.data.vorname);
					setNachname(response.payload.data.data.nachname);
					setAuthError('');
				}
			}
		});
	}, []);

	const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&\\+\\-*])(?=.{8,})');

	const validateAndSubmit = async () => {
		let hasError = false;
		if (benutzername.length < 4) {
			hasError = true;
			setBenutzernameError('Der Benutzername muss mindestens 4 Zeichen lang sein.');
		} else {
			setBenutzernameError('');
		}

		if (!strongRegex.test(passwort) || passwort !== passwortWiederholung) {
			hasError = true;
			setPasswortError('Ihr Passwort entspricht nicht den Vorgaben.');
		} else {
			setPasswortError('');
		}

		if (pin === '') {
			setPinError('Geben Sie Ihren PIN ein.');
			hasError = true;
		} else {
			setPinError('');
		}

		if (!hasError) {
			dispatch(benutzerRegistrieren({ code, benutzername, passwort, pin })).then((response) => {
				const payload = response.payload;

				if (!payload) {
					toast('Die Anfrage wurde serverseitig nicht richtig verarbeitet. Wenden Sie sich an Ihren Administrator.', {
						transition: Bounce,
						closeButton: true,
						autoClose: 5000,
						position: 'top-right',
						type: 'error',
					});
					return;
				}

				if (payload.status === 200) {
					if (payload.data.status === 0) {
						// In dem Fall sind Fehlermeldungen enthalten im errors array
						const errors = payload.data.errors;
						if (undefined !== errors.pin) {
							setPinError(errors.pin);
						}
						if (undefined !== errors.username) {
							setBenutzernameError(errors.username);
						}
						if (undefined !== errors.password) {
							setPasswortError(errors.password);
						}
					} else if (payload.data.status === 1) {
						toast(payload.data.message, {
							transition: Bounce,
							closeButton: true,
							autoClose: 5000,
							position: 'top-right',
							type: 'success',
						});
						navigate('/login');
					} else if (payload.data.status < 0) {
						toast(payload.data.message, {
							transition: Bounce,
							closeButton: true,
							autoClose: 5000,
							position: 'top-right',
							type: 'warning',
						});
						return;
					}
				}
			});
		}
	};

	return (
		<Fragment>
			<div className={`ibsBackground h-100 p-5`}>
				<Form name='registerForm'>
					<Row>
						<Col xs={12} sm={12} md={8} className='mx-auto shadowBox app-login-box p-5'>
							<Row>
								<Col>
									<div className='mx-auto mb-3'>
										<img src={logo} width='150' alt='' />
									</div>
								</Col>
							</Row>
							{authError === '' ? (
								<Row>
									<Col md='6' className='text'>
										<h4>Willkommen, {`${(vorname + ' ' + nachname).trim()}`}!</h4>
										<p>
											Schließen Sie die Registrierung ab indem Sie ihren Benutzernamen, ihr Wunschpasswort vergeben und Ihre Eingabe mit
											dem in der Einladungs E-Mail übermittelten PIN bestätigen.{' '}
										</p>
										<Alert color='light'>Der Benutzername muss mindestens 4 Zeichen lang sein. </Alert>
										<Alert color='light'>
											Das Passwort muss mindestens aus 8 Zeichen bestehen. Um ein möglichst sicheres Passwort zu erstellen verwenden Sie
											Groß-/Kleinbuchstaben, Zahlen und Sonderzeichen. (wie z.B. #,!,$,%,&,...){' '}
										</Alert>
									</Col>
									<Col md='6' className=''>
										<Row>
											<Col md={12}>
												<FormGroup row>
													<Label for='usernameField' sm={4}>
														Benutzername
													</Label>
													<Col sm={8}>
														<Input
															ref={benutzernameRef}
															type='email'
															name='email'
															id='usernameField'
															placeholder='Benutzername'
															size='sm'
															value={benutzername}
															onChange={(e) => setBenutzername(e.target.value)}
															invalid={benutzernameError !== ''}
														/>
														{benutzernameError !== '' && (
															<FormFeedback className='alternate-form-feedback'>{benutzernameError}</FormFeedback>
														)}
													</Col>
												</FormGroup>
											</Col>
											<Col md={12}>
												<FormGroup row>
													<Label sm={4}>Passwort</Label>
													<Col sm={8}>
														<Input
															ref={passwortRef}
															type='password'
															name='password'
															id='password'
															placeholder='Passwort'
															size='md'
															value={passwort}
															onChange={(e) => setPasswort(e.target.value)}
															invalid={passwortError !== ''}
														/>
														{passwortError !== '' && (
															<FormFeedback className='alternate-form-feedback'>{passwortError}</FormFeedback>
														)}
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label sm={4}>Passwort wiederholen</Label>
													<Col sm={8}>
														<Input
															ref={passwortWiederholungRef}
															type='password'
															id='passwortWiederholung'
															name='passwortWiederholung'
															placeholder='Passwort wiederholen'
															onChange={(e) => {
																setPasswortWiederholung(e.target.value);
															}}
															size='md'
															value={passwortWiederholung}
															invalid={passwortError !== ''}
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label sm={4}>PIN</Label>
													<Col sm={8}>
														<Input
															ref={pinRef}
															type='text'
															id='pin'
															name='pin'
															size='md'
															placeholder='PIN eingeben'
															onChange={(e) => {
																setPin(e.target.value);
															}}
															value={pin}
															invalid={pinError !== ''}
														/>
														{pinError !== '' && <FormFeedback className='alternate-form-feedback'>{pinError}</FormFeedback>}
													</Col>
												</FormGroup>
											</Col>
										</Row>
										<div>
											<Button
												color='primary'
												size='lg'
												className='loginButton'
												block
												tag='a'
												style={{ backgroundColor: '#005091', borderColor: '#005091' }}
												onClick={(e) => validateAndSubmit()}
											>
												Registrieren
											</Button>
											<Row>
												<Col className='d-flex justify-content-center align-items-center'>
													<Button className='staticLink' color='link' tag='a' href='#/impressum'>
														Impressum
													</Button>
												</Col>
												<Col className='d-flex justify-content-center align-items-center'>
													<Button className='staticLink' color='link' tag='a' href='#/datenschutz'>
														Datenschutz
													</Button>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
							) : (
								<Row>
									<Col>
										<Alert color='danger'>{authError}</Alert>
									</Col>
								</Row>
							)}
							<div className='text-center text-white opacity-8 mt-3'>Copyright &copy; ibs Sicherheitstechnik {new Date().getFullYear()}</div>
						</Col>
					</Row>
				</Form>
			</div>
		</Fragment>
	);
};

export default Register;
