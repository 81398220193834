import React, { Component, Fragment } from 'react';
import { CardHeader, Container, Card, CardBody } from 'reactstrap';
import DashboardCards from './DashboardCards';

class Dashboard extends Component {
	render() {
		return (
			<Fragment>
				<Container fluid>
					<Card className='mb-3'>
						<CardHeader className='card-header-tab z-index-6'>
							<div className='card-header-title font-size-lg text-capitalize fw-normal'>
								<i className='header-icon lnr-charts icon-gradient bg-happy-green'> </i>
								Errichter Dashboard
							</div>
						</CardHeader>
						<CardBody>
							<DashboardCards />
						</CardBody>
					</Card>
				</Container>
			</Fragment>
		);
	}
}

export default Dashboard;
