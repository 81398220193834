export default function renderIBSIdentAndMarkteNr(ident, market) {
	if (!ident && !market) return <span>-</span>;
	if (!!ident && !market) return <span>{ident} / -</span>;
	if (!ident && !!market) return <span>- / {market}</span>;
	return (
		<span>
			{ident} / {market}
		</span>
	);
}
