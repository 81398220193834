import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import { Checkbox } from '../Admin/Components/MessageForm';
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { fetchUserMessages, hideUserMessage, userMessageShown } from '../../../reducers/MessagesSlice';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';

const MessageDialog = (props) => {
	const dispatch = useDispatch();
	const axios = useAxiosPrivate();
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState(null);
	const [messageIndex, setMessageIndex] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		dispatch(fetchUserMessages({ axios })).then((res) => {
			setMessages(res.payload);

			if (res.payload.length > 0) {
				setMessage(res.payload[messageIndex]);
				setMessageShown(res.payload[messageIndex].id);
			}
		});
	}, []);

	const setMessageShown = (id) => {
		dispatch(userMessageShown({ axios, data: { id } })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}

			// kann eigentlich ignoriert werden, da im bestenfall die daten gespeichert wurden
		});
	};

	const closeMessage = (id, hide) => {
		dispatch(hideUserMessage({ axios, data: { id, hide } })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}

			// here the real deal
			if (messageIndex < messages.length - 1) {
				setMessage(messages[messageIndex + 1]);
				setMessageShown(messages[messageIndex + 1].id);
				setMessageIndex(messageIndex + 1);
				document.getElementById('hide_checkbox').checked = false;
			} else {
				setMessages([]);
				setMessageIndex(0);
				setMessage(null);
			}
		});
	};

	const takeNextMessageOrClose = () => {
		const id = message.id;
		const hide = document.getElementById('hide_checkbox').checked;
		closeMessage(id, hide);
	};

	if (message === null || messages.length === 0) return null;

	return (
		<Modal isOpen={true}>
			<ModalBody className='p-0'>
				<Card className={`main-card card-border border-${message.darstellung}`}>
					<CardHeader className={`${message.darstellung !== 'default' ? 'text-white' : ''} bg-${message.darstellung}`}>{message.titel}</CardHeader>
					<CardBody className={``}>
						{message.nachricht.split('\n').map((line, index) => (
							<React.Fragment key={index}>
								{line}
								<br />
							</React.Fragment>
						))}
					</CardBody>
					<CardFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Checkbox id={'hide_checkbox'} className='form-check-input' label={'nicht mehr anzeigen'} />
						<Button color={'light'} onClick={() => takeNextMessageOrClose()}>
							{messageIndex < messages.length - 1 ? 'Nächste Nachricht' : 'Schließen'}
						</Button>
					</CardFooter>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default MessageDialog;
