import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

const OrderStatusCard = (props) => {
	const {
		auftragBegonnen,
		fertigLautBenutzer,
		komplettFertig,
		fertiggestelltVon,
		fertigLautBenutzerDatum,
		komplettFertigDatum,
		arbeitBegonnenVon,
		arbeitBegonnenDatum,
	} = props;

	const fertigLautBenutzerText = () => {
		if (!fertigLautBenutzer) {
			return 'Der Auftrag wurde noch nicht abgeschlossen';
		}

		const person = fertiggestelltVon;
		const datum = fertigLautBenutzerDatum;

		if (!person && !datum) {
			return null;
		}

		if (!!person && !!datum) {
			return (
				<>
					<strong className='text-alternate'>
						{person.vorname} {person.nachname}
					</strong>{' '}
					hat den Auftrag am <strong className='text-alternate'>{datum} Uhr</strong> abgeschlossen.
				</>
			);
		}

		if (!person && !!datum) {
			return (
				<>
					Der Auftrag wurde am <strong className='text-alternate'>{datum} Uhr</strong> abgeschlossen
				</>
			);
		}

		return (
			<>
				Der Auftrag wurde von{' '}
				<strong className='text-alternate'>
					{person.vorname} {person.nachname}
				</strong>{' '}
				abgeschlossen
			</>
		);
	};

	const komplettFertigText = () => {
		if (!komplettFertig) {
			return 'Der Auftrag wurde noch nicht abgeschlossen';
		}

		const datum = komplettFertigDatum;

		if (!datum) {
			return 'Der Auftrag wurde als komplett fertig markiert';
		}

		return (
			<>
				Der Auftrag wurde am <strong className='text-alternate'>{datum} Uhr</strong> als komplett fertig markiert.
			</>
		);
	};

	const arbeitBegonnenText = () => {
		if (!arbeitBegonnenDatum && !arbeitBegonnenVon) {
			return null;
		}

		const datum = arbeitBegonnenDatum;
		const person = arbeitBegonnenVon;

		if (!!datum && !!person) {
			return (
				<Fragment>
					Der Auftrag wurde am <strong className='text-alternate'>{datum} Uhr</strong> von{' '}
					<strong className='text-alternate'>
						{person.vorname} {person.nachname}
					</strong>{' '}
					begonnen.
				</Fragment>
			);
		}

		if (!!datum && !person) {
			return (
				<Fragment>
					Der Auftrag wurde am <strong className='text-alternate'>{datum}</strong> begonnen.
				</Fragment>
			);
		}

		return (
			<Fragment>
				Der Auftrag wurde von{' '}
				<strong className='text-alternate'>
					{person.vorname} {person.nachname}
				</strong>{' '}
				begonnen.
			</Fragment>
		);
	};

	const timelineElement = (title, content, colorClass) => {
		return (
			<VerticalTimelineElement className='vertical-timeline-item' icon={<i className={`badge badge-dot badge-dot-xl ${colorClass}`}> </i>}>
				<h4 className='timeline-title'>{title}</h4>
				<p>{content} </p>
			</VerticalTimelineElement>
		);
	};

	return (
		<Card className='main-card mb-3'>
			<CardHeader>Auftragsstatus</CardHeader>
			<CardBody>
				<VerticalTimeline layout='1-column' className='vertical-without-time'>
					{timelineElement(
						'Auftrag begonnen',
						auftragBegonnen ? arbeitBegonnenText() : 'Auftrag wurde noch nicht begonnen',
						auftragBegonnen ? 'bg-success' : 'bg-warning'
					)}
					{timelineElement(
						'Fertig laut Techniker',
						fertigLautBenutzer ? fertigLautBenutzerText() : 'Der Techniker hat den Auftrag noch nicht abgeschlossen',
						fertigLautBenutzer ? 'bg-success' : 'bg-warning'
					)}
					{timelineElement(
						'Auftrag komplett fertig',
						komplettFertig ? komplettFertigText() : 'Der Auftrag ist nicht als komplett abgeschlossen markiert',
						komplettFertig ? 'bg-success' : 'bg-warning'
					)}
				</VerticalTimeline>
			</CardBody>
		</Card>
	);
};

export default OrderStatusCard;
