import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	API_ACTION_LOG_URL,
	API_ADMIN_ORDER_URL,
	API_BUILDER_ORDER_URL,
	API_CANCEL_DEPARTMENTS,
	API_CANCEL_POSITION,
	API_CREATE_RETURN_JOURNEY,
	API_CREATE_RETURN_TRIP_DOCUMENT_URL,
	API_FETCH_ORDER_ITEMS,
	API_IMAGES_ROUTE,
	API_LOAD_DEPARTMENTS,
	API_LOAD_ORDER_POSITION,
	API_ORDERDETAIL_URL,
	API_RESET_ORDER,
	API_RE_OPEN_ORDER,
	API_SAVE_DEPARTMENT,
	API_SAVE_NEW_MILEAGE_FOR_POSITION,
	API_SAVE_ORDER_POSITION,
	API_SIGN_ORDER,
	API_START_WORK_ON_ORDER,
	API_SUPPLEMENT_RETURN_TRIP,
	API_TECHNICIAN_FOR_ORDER,
	API_TECHNICIAN_ORDERS_URL,
	API_TECHNICIAN_ORDER_URL,
	API_TOGGLE_INVOICED_STATE,
	API_INVALIDATE_ROUTE,
	API_SAVE_SETTING_SHOW_CLOSED_ORDERS_IN_DAYS,
} from '../App/Components/API/endpoints';
import { resetBuilderFilter } from './BuilderOverviewSlice';
import { resetTechnicianFilter } from './TechnicianOverviewSlice';

const initialState = {
	total: 0,
	selectedOrder: null,
	filter: { type: 'all', search: '' },
	page: 1,
	perPage: 10,
	entries: [],
	status: 'idle', // idle | loading | succeeded | failed
	error: null,
	sortBy: 'name',
	sortOrder: 'ASC',
};

export const saveSettingShowClosedOrdersInDays = createAsyncThunk('orders/saveSettingShowClosedOrdersInDays', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SAVE_SETTING_SHOW_CLOSED_ORDERS_IN_DAYS, arg.data);
	return response;
});

export const saveNewKmForPosition = createAsyncThunk('orders/saveNewKmForPosition', async (arg) => {
	const axios = arg.axios;
	const data = {
		k: arg.k,
		p: arg.p,
	};
	const response = await axios.post(API_SAVE_NEW_MILEAGE_FOR_POSITION, data);
	return response.data;
});

export const startWorkOnOrder = createAsyncThunk('orders/startWorkOnOrder', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_START_WORK_ON_ORDER + arg.selectedOrder);
	return response.data;
});

export const handleResetOrder = createAsyncThunk('orders/resetOrder', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_RESET_ORDER + arg.selectedOrder);
	return response.data;
});

export const handleReOpenOrder = createAsyncThunk('orders/reopenOrder', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_RE_OPEN_ORDER + arg.selectedOrder);
	return response.data;
});

export const fetchOrderTechnicians = createAsyncThunk('orders/fetchOrderTechnicians', async (arg) => {
	const axios = arg.axios;
	const params = `?order=${arg.order}`;
	const response = await axios.get(API_TECHNICIAN_FOR_ORDER + params);
	return response.data;
});

export const fetchAdminTechnicianOrders = createAsyncThunk('orders/fetchTechnicianOrders', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&type=${arg.filter.type}&search=${arg.filter.search}`;
	const response = await axios.post(API_TECHNICIAN_ORDERS_URL + params, { technician: arg.technician });
	return response.data;
});

export const fetchAdminOrders = createAsyncThunk('orders/fetchAdminOrders', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&type=${arg.filter.type}&search=${arg.filter.search}`;
	const response = await axios.get(API_ADMIN_ORDER_URL + params);
	return response.data;
});

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&type=${arg.filter.type}&search=${arg.filter.search}`;
	const response = await axios.get(API_TECHNICIAN_ORDER_URL + params);
	return response.data;
});

export const fetchBuilderOrders = createAsyncThunk('orders/fetchBuilderOrders', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&type=${arg.filter.type}&search=${arg.filter.search}`;
	const builderID = undefined !== arg.selectedBuilder ? arg.selectedBuilder : null;

	if (builderID) {
		const response = await axios.post(API_BUILDER_ORDER_URL + params, { selectedBuilder: builderID });
		return response.data;
	}

	const response = await axios.get(API_BUILDER_ORDER_URL + params);
	return response.data;
});

export const fetchOrderDetails = createAsyncThunk('orders/orderDetails', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_ORDERDETAIL_URL, arg);
	return response.data;
});

export const fetchOrderActionLogs = createAsyncThunk('orders/orderActionLogs', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_ACTION_LOG_URL, arg);
	return response.data;
});

export const saveOrderPosition = createAsyncThunk('orders/saveOrderPosition', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SAVE_ORDER_POSITION + '/' + arg.order, arg.form, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
});

export const fetchOrderPosition = createAsyncThunk('orders/fetchOrderPosition', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_LOAD_ORDER_POSITION + '/' + arg.position);
	return response.data;
});

export const fetchAllPhotosForOrder = createAsyncThunk('orders/fetchAllImagesForOrder', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}

	if (!!arg.photo) {
		params += '/' + arg.photo;
	}

	const response = await axios.get(API_IMAGES_ROUTE + params);
	return response.data;
});

export const uploadPhotoForOrder = createAsyncThunk('orders/uploadPhotoForOrder', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}
	const response = await axios.post(API_IMAGES_ROUTE + params, arg.form, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
});

export const fetchAllThumbsForOrder = createAsyncThunk('orders/fetchThumbsForOrder', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}
	const response = await axios.get(API_IMAGES_ROUTE + params);
	return response.data;
});

export const fetchImageData = createAsyncThunk('orders/fetchImageData', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}
	if (!!arg.photo) {
		params += '/' + arg.photo;
	}
	const response = await axios.get(API_IMAGES_ROUTE + params);
	return response.data;
});

export const deleteImageData = createAsyncThunk('orders/deleteImageData', async (arg) => {
	const axios = arg.axios;
	let params = '';
	if (!!arg.selectedOrder) {
		params += '/' + arg.selectedOrder;
	}
	if (!!arg.photo) {
		params += '/' + arg.photo;
	}
	const response = await axios.delete(API_IMAGES_ROUTE + params);
	return response.data;
});

export const cancelOrderPosition = createAsyncThunk('orders/cancelOrderPosition', async (arg) => {
	const axios = arg.axios;
	let route = API_CANCEL_POSITION;
	if (!!arg.orderPosition) {
		route = route.replace('__ID__', arg.orderPosition);
	}
	const response = await axios.get(route);
	return response.data;
});

export const signOrder = createAsyncThunk('orders/signOrder', async (arg) => {
	const axios = arg.axios;
	const params = `/${arg.order}`;

	if (arg.type === 'POST') {
		const form = arg.form;
		const response = await axios.post(API_SIGN_ORDER + params, form);
		return response.data;
	}

	const response = await axios.get(API_SIGN_ORDER + params);
	return response.data;
});

export const supplementReturnTrip = createAsyncThunk('orders/supplementReturnTrip', async (arg) => {
	const axios = arg.axios;
	const params = `/${arg.position}`;

	const form = arg.form;
	const response = await axios.post(API_SUPPLEMENT_RETURN_TRIP + params, form);
	return response.data;
});

export const createReturnTripDocument = createAsyncThunk('orders/createReturnTripDocument', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_CREATE_RETURN_TRIP_DOCUMENT_URL + '/' + arg.order);
	return response.data;
});

export const fetchOrderItems = createAsyncThunk('orders/fetchOrderItems', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FETCH_ORDER_ITEMS, arg);
	return response.data;
});

export const toggleInvoicedState = createAsyncThunk('orders/toggleInvoicedState', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_TOGGLE_INVOICED_STATE, arg);
	return response.data;
});

export const saveDepartmentData = createAsyncThunk('technician/saveDepartmentData', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SAVE_DEPARTMENT, arg.form, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
});

export const loadDepartmentData = createAsyncThunk('technician/loadDepartmentData', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_LOAD_DEPARTMENTS, { aID: arg.auftrag });
	return response.data;
});

export const cancelDepartments = createAsyncThunk('technician/cancelDepartments', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_CANCEL_DEPARTMENTS, { aID: arg.aID, rID: arg.rID });
	return response.data;
});

export const addReturnJourneyData = createAsyncThunk('technician/addReturnJourneyData', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_CREATE_RETURN_JOURNEY, arg.form, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
});

export const logout = createAsyncThunk('app/logout', async (arg) => {
	const axios = arg.axios;
	resetTechnicianFilter();
	resetBuilderFilter();
	resetOrderFilter();
	const response = await axios.post(API_INVALIDATE_ROUTE, {
		withCredentials: true,
	});
	return response;
});

export const OrderOverviewSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		resetOrderFilter: {
			reducer(state, action) {
				state.filter = { type: 'all', search: '' };
			},
			prepare() {
				return {};
			},
		},
		setOrder: {
			reducer(state, action) {
				state.selectedOrder = action.payload.selectedOrder;
			},
			prepare(selectedOrder) {
				return {
					payload: {
						selectedOrder: selectedOrder,
					},
				};
			},
		},
		updateOrder: {
			reducer(state, action) {
				state.selectedOrder = action.payload.selectedOrder;
				state.entries = action.payload.entries;
			},
			prepare(selectedOrder, invoiceNumber, invoiceDate, entries) {
				const updatedEntries = entries.map((entry) => {
					if (entry.id === selectedOrder) {
						const updatedEntry = { ...entry, invNr: invoiceNumber, inv: invoiceDate };
						return updatedEntry;
					}
					return entry;
				});

				return {
					payload: {
						entries: updatedEntries,
					},
				};
			},
		},
		setEntries: {
			reducer(state, action) {
				state.entries = action.payload.entries;
			},
			prepare(entries) {
				return {
					payload: {
						entries: entries,
					},
				};
			},
		},
		setPerPage: {
			reducer(state, action) {
				state.perPage = action.payload.perPage;
			},
			prepare(perPage) {
				return {
					payload: {
						perPage,
					},
				};
			},
		},
		setTotal: {
			reducer(state, action) {
				state.total = action.payload.total;
			},
			prepare(total) {
				return {
					payload: {
						total,
					},
				};
			},
		},
		setPage: {
			reducer(state, action) {
				state.page = action.payload.page;
			},
			prepare(page) {
				return {
					payload: {
						page,
					},
				};
			},
		},
		setFilter: {
			reducer(state, action) {
				state.filter = { type: action.payload.type, search: action.payload.search };
			},
			prepare(type, search) {
				return {
					payload: {
						type,
						search,
					},
				};
			},
		},
		setSortByAndSortOrder: {
			reducer(state, action) {
				state.sortBy = action.payload.sortBy;
				state.sortOrder = action.payload.sortOrder;
			},
			prepare(sortBy, sortOrder) {
				return {
					payload: {
						sortBy,
						sortOrder,
					},
				};
			},
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchAdminOrders.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchAdminOrders.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchAdminOrders.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})

			.addCase(fetchAdminTechnicianOrders.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchAdminTechnicianOrders.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchAdminTechnicianOrders.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})

			.addCase(fetchOrders.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchOrders.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchOrders.rejected, (state, action) => {
				state.status = 'failed';
				state.entries = [];
				state.error = action.error.message;
			})
			.addCase(fetchOrderActionLogs.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchOrderActionLogs.fulfilled, (state, action) => {
				state.status = 'succeeded';
			})
			.addCase(fetchOrderActionLogs.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(saveOrderPosition.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(saveOrderPosition.fulfilled, (state, action) => {
				state.status = 'succeeded';
			})
			.addCase(saveOrderPosition.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchOrderDetails.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchOrderDetails.fulfilled, (state, action) => {
				state.status = 'succeeded';
			})
			.addCase(fetchOrderDetails.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchOrderPosition.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchOrderPosition.fulfilled, (state, action) => {
				state.status = 'succeeded';
			})
			.addCase(fetchOrderPosition.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchAllPhotosForOrder.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchAllPhotosForOrder.fulfilled, (state, action) => {
				state.status = 'succeeded';
			})
			.addCase(fetchAllPhotosForOrder.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchBuilderOrders.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchBuilderOrders.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchBuilderOrders.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { setEntries, setPerPage, setPage, setTotal, setFilter, setSortByAndSortOrder, setOrder, updateOrder, resetOrderFilter } =
	OrderOverviewSlice.actions;

export default OrderOverviewSlice.reducer;

export const getSelectedOrder = (state) => state.orderOverview.selectedOrder;
export const getPage = (state) => state.orderOverview.page;
export const getPerPage = (state) => state.orderOverview.perPage;
export const getTotal = (state) => state.orderOverview.total;
export const getOrders = (state) => state.orderOverview.entries;
export const getOrdersStatus = (state) => state.orderOverview.status;
export const getOrdersError = (state) => state.orderOverview.error;
export const getSortColumn = (state) => state.orderOverview.sortBy;
export const getSortOrder = (state) => state.orderOverview.sortOrder;
export const getFilter = (state) => state.orderOverview.filter;
