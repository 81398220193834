import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	API_CREATE_MESSAGE,
	API_DELETE_MESSAGE,
	API_DELETE_MSSAGE_FOR_USER,
	API_FETCH_CURRENT_MESSAGES,
	API_FETCH_MESSAGES_FOR_THREE_MONTHS,
	API_FETCH_USER_MESSAGES,
	API_HIDE_USER_MESSAGE,
	API_USER_MESSAGE_SHOWN,
} from '../App/Components/API/endpoints';

const initialState = {
	total: 0,
	selectedMessage: null,
	filter: { type: 'all', search: '' },
	page: 1,
	perPage: 10,
	entries: [],
	status: 'idle', // idle | loading | succeeded | failed
	error: null,
	sortBy: 'name',
	sortOrder: 'ASC',
};

export const fetchAllMessages = createAsyncThunk('messages/fetchAllMessages', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&type=${arg.filter.type}&search=${arg.filter.search}`;
	const response = await axios.get(API_FETCH_CURRENT_MESSAGES + params);
	return response.data;
});

export const fetchMessagesForThreeMonths = createAsyncThunk('messages/fetchMessagesForThreeMonths', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_FETCH_MESSAGES_FOR_THREE_MONTHS);
	return response.data;
});

export const createMessage = createAsyncThunk('messages/createMessage', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_CREATE_MESSAGE, arg.form);
	return response.data;
});

export const deleteMessage = createAsyncThunk('messages/deleteMessage', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_DELETE_MESSAGE, { id: arg.id });
	return response.data;
});

export const deleteMessageForUser = createAsyncThunk('messages/deleteMessageForUser', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_DELETE_MSSAGE_FOR_USER, { id: arg.id });
	return response.data;
});

export const userMessageShown = createAsyncThunk('messages/userMessageShown', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_USER_MESSAGE_SHOWN, arg.data);
	return response.data;
});

export const hideUserMessage = createAsyncThunk('messages/hideUserMessage', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_HIDE_USER_MESSAGE, arg.data);
	return response.data;
});

export const fetchUserMessages = createAsyncThunk('messages/fetchUserMessages', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_FETCH_USER_MESSAGES);
	return response.data;
});

export const MessagesSlice = createSlice({
	name: 'messages',
	initialState,
	reducers: {
		resetMessageFilter: {
			reducer(state, action) {
				state.filter = { type: 'all', search: '' };
			},
			prepare() {
				return {};
			},
		},
		setMessage: {
			reducer(state, action) {
				state.selectedMessage = action.payload.selectedMessage;
			},
			prepare(selectedMessage) {
				return {
					payload: {
						selectedMessage: selectedMessage,
					},
				};
			},
		},
		setEntries: {
			reducer(state, action) {
				state.entries = action.payload.entries;
			},
			prepare(entries) {
				return {
					payload: {
						entries: entries,
					},
				};
			},
		},
		setPerPage: {
			reducer(state, action) {
				state.perPage = action.payload.perPage;
			},
			prepare(perPage) {
				return {
					payload: {
						perPage,
					},
				};
			},
		},
		setTotal: {
			reducer(state, action) {
				state.total = action.payload.total;
			},
			prepare(total) {
				return {
					payload: {
						total,
					},
				};
			},
		},
		setPage: {
			reducer(state, action) {
				state.page = action.payload.page;
			},
			prepare(page) {
				return {
					payload: {
						page,
					},
				};
			},
		},
		setFilter: {
			reducer(state, action) {
				state.filter = { type: action.payload.type, search: action.payload.search };
			},
			prepare(type, search) {
				return {
					payload: {
						type,
						search,
					},
				};
			},
		},
		setSortByAndSortOrder: {
			reducer(state, action) {
				state.sortBy = action.payload.sortBy;
				state.sortOrder = action.payload.sortOrder;
			},
			prepare(sortBy, sortOrder) {
				return {
					payload: {
						sortBy,
						sortOrder,
					},
				};
			},
		},
	},
	extraReducers(messages) {
		messages
			.addCase(fetchAllMessages.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchAllMessages.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchAllMessages.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { setEntries, setPerPage, setPage, setTotal, setFilter, setSortByAndSortOrder, setMessage, updateMessage, resetMessageFilter } =
	MessagesSlice.actions;

export default MessagesSlice.reducer;

export const getSelectedMessage = (state) => state.messagesOverview.selectedMessage;
export const getPage = (state) => state.messagesOverview.page;
export const getPerPage = (state) => state.messagesOverview.perPage;
export const getTotal = (state) => state.messagesOverview.total;
export const getMessages = (state) => state.messagesOverview.entries;
export const getMessageStatus = (state) => state.messagesOverview.status;
export const getSortColumn = (state) => state.messagesOverview.sortBy;
export const getSortOrder = (state) => state.messagesOverview.sortOrder;
export const getFilter = (state) => state.messagesOverview.filter;
