import React, { Fragment, useEffect, useState } from 'react';
import { FormGroup, FormFeedback, Input, Modal, ModalBody, CardHeader, CardFooter, Button, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	setPage,
	setPerPage,
	fetchBuilderOrders,
	getPage,
	getPerPage,
	getTotal,
	getOrders,
	getOrdersStatus,
	getSortColumn,
	getSortOrder,
	setSortByAndSortOrder,
	getFilter,
	setFilter,
	setOrder,
	updateOrder,
	toggleInvoicedState,
} from '../../../reducers/OrderOverviewSlice';
import OrderStatus from '../Common/OrderStatus';
import CustomerAndAddress from '../Common/CustomerAndAddress';
import NoDataComponent from '../Common/NoDataComponent';
import OrderActions from './OrderActions';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import OrderAssignmentPlates from '../Common/OrderAssignmentPlates';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingDataComponent from '../Common/LoadingDataComponent';
import { setSelectedTechnician } from '../../../reducers/TechnicianOverviewSlice';
import OrderDescription from '../Common/OrderDescription';
import OrderFilter from '../Common/OrderFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign, faEye } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { smallDisplay } from '../Technician/Components/Services';
import renderIBSIdentAndMarkteNr from '../Common/Order/IdentAndMarket';

const InvoicedOrderColumn = (data) => {
	const row = data.data;
	const [showToggleInvoicedStateDialog, setShowToggleInvoicedStateDialog] = useState(false);
	const [invoiceNumber, setInvoiceNumber] = useState('');
	const [invoiceNumberError, setInvoiceNumberError] = useState('');

	// Es benötigt einen Handler damit die Auftragsdaten nicht wieder neu gezogen werden müssen
	// Zugriff wohl nur von außerhalb möglich

	const showInvoicedStateDialog = () => {
		setShowToggleInvoicedStateDialog(true);
	};
	const hideInvoicedStateDialog = () => {
		setShowToggleInvoicedStateDialog(false);
	};

	const saveInvoicedState = () => {
		if (!row.inv && invoiceNumber === '') {
			setInvoiceNumberError('Geben Sie die Rechnungsnummer ein');
		} else {
			setInvoiceNumberError('');
			hideInvoicedStateDialog();
			data.toggleInvoicedState(invoiceNumber);
		}
	};

	const colors = ['rgb(111,213,111)', 'rgb(213,111,111)'];

	return (
		<div style={{ cursor: 'pointer' }}>
			{!row.inv ? (
				<FontAwesomeIcon
					onClick={(e) => {
						showInvoicedStateDialog();
					}}
					icon={faEuroSign}
					style={{ fontSize: '1.25rem', color: colors[row.inv ? 0 : 1] }}
				/>
			) : (
				<a
					onClick={(e) => {
						showInvoicedStateDialog();
					}}
					style={{ color: colors[row.inv ? 0 : 1], display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
				>
					<div>
						#: <b>{row.invNr}</b>
					</div>
					<div>
						<small>{row.inv}</small>
					</div>
				</a>
			)}

			{showToggleInvoicedStateDialog && (
				<Modal isOpen={showToggleInvoicedStateDialog} toggle={hideInvoicedStateDialog}>
					<ModalBody className='p-0'>
						<Card className='main-card'>
							<CardHeader>
								<i className='header-icon lnr-earth icon-gradient bg-plum-plate'> </i>
								Auftrag als berechnet markieren
							</CardHeader>
							<CardBody>
								{row.inv ? (
									<>Möchten Sie die Kennzeichnung als 'berechnet an die ibs' wiederrufen? </>
								) : (
									<>
										<div>
											Geben Sie die Rechnungsnummer ein unter welcher Sie die Rechnung an die ibs Sicherheitstechnik gesendet haben.
										</div>
										<div style={{ marginTop: '1rem' }}>
											<FormGroup>
												<Input
													type='text'
													id='invoiceNumber'
													name='invoiceNumber'
													onChange={(e) => {
														setInvoiceNumber(e.target.value);
													}}
													value={invoiceNumber}
													invalid={invoiceNumberError !== ''}
													maxLength={100}
													placeholder='Rechnungsnummer'
												/>
												{invoiceNumberError !== '' && <FormFeedback>{invoiceNumberError}</FormFeedback>}
											</FormGroup>
										</div>
									</>
								)}
							</CardBody>
							<CardFooter className='d-block text-end'>
								<Button size='sm' className='me-2' color='primary' onClick={saveInvoicedState}>
									{row.inv ? 'Kennzeichnung widerrufen' : 'Als berechnet kennzeichnen'}
								</Button>
								<Button size='sm' className='me-2' color='link' onClick={hideInvoicedStateDialog}>
									Abbrechen
								</Button>
							</CardFooter>
						</Card>
					</ModalBody>
				</Modal>
			)}
		</div>
	);
};

export const OrderOverview = ({}) => {
	const dispatch = useDispatch();
	const page = useSelector(getPage);
	const perPage = useSelector(getPerPage);
	const total = useSelector(getTotal);
	const orders = useSelector(getOrders);
	const status = useSelector(getOrdersStatus);
	const sortBy = useSelector(getSortColumn);
	const sortOrder = useSelector(getSortOrder);
	const filter = useSelector(getFilter);
	const axios = useAxiosPrivate();
	const [typeFilter, setTypeFilter] = useState(filter.type);
	const [search, setSearch] = useState(filter.search);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const columns = [
		{
			name: '',
			id: 'showAction',
			selector: (row) => (
				<Link className='btn btn-sm btn-primary' to='/errichter/auftrag/ansehen' onClick={() => dispatch(setOrder(row.id))}>
					<FontAwesomeIcon icon={faEye} className='btn-icon-wrapper' />
				</Link>
			),
			sortable: false,
			compact: true,
			center: true,
			omit: !smallDisplay,
			minWidth: '60px',
			maxWidth: '60px',
		},
		{
			name: 'Datum',
			id: 'date',
			selector: (row) => row.cDate,
			sortable: true,
			maxWidth: '80px',
			compact: true,
			center: true,
			omit: smallDisplay,
		},
		{
			name: (
				<div>
					<div>Kunde</div>
					<div>Anschrift</div>
				</div>
			),
			id: 'customer',
			selector: (row) => <CustomerAndAddress street={row.oStr} pcode={row.oPcode} location={row.oLoc} customer={row.cTag} withGoogleLink={true} />,
			sortable: true,
			minWidth: '250px',
		},
		{
			name: (
				<div>
					<div>Auftragsnummer</div>
					<div style={{ whiteSpace: 'nowrap' }}>Ident-Nr ibs / Markt</div>
				</div>
			),
			id: 'ordernumber',
			selector: (row) => (
				<div>
					<div>{row.oNr}</div>
					<div>{renderIBSIdentAndMarkteNr(row.oObj, row.oMnr)}</div>
				</div>
			),
			sortable: true,
			maxWidth: '150px',
		},
		{
			name: <div>Berechnet an ibs</div>,
			id: 'invoiced',
			selector: (row) => (
				<InvoicedOrderColumn
					data={row}
					toggleInvoicedState={(invoiceNumber) => {
						dispatch(toggleInvoicedState({ axios, selectedOrder: row.id, rechnungsNr: invoiceNumber })).then((response) => {
							if (response?.error?.message.indexOf('401') >= 0) {
								navigate('/login', { state: { from: location }, replace: true });
							}
							const formattedDate = format(new Date(), 'dd.MM.yyyy');
							const invNumber = !!row.invNr ? null : invoiceNumber;
							const invoiceDate = !!row.inv ? null : formattedDate;

							if (response.payload.status === 1) {
								const newOrders = [...orders];
								dispatch(updateOrder(row.id, invNumber, invoiceDate, newOrders));
							}
						});
					}}
				/>
			),
			sortable: false,
			maxWidth: '130px',
			center: true,
		},
		{
			name: (
				<div>
					<div>Auftragsart</div>
					<div>Auftragstyp</div>
				</div>
			),
			id: 'ordertype',
			selector: (row) => (
				<div>
					<div>{row.oArt}</div>
					<div>{row.oT}</div>
				</div>
			),
			sortable: true,
			maxWidth: '190px',
		},
		{
			name: (
				<div>
					<div>Termin</div>
					<div>Beschreibung</div>
				</div>
			),
			id: 'orderdescription',
			selector: (row) => (
				<div>
					<div>Termin: {!!row.aDate ? row.aDate : '-'}</div>
					<OrderDescription description={row.desc} miscellaneous={row.misc} />
				</div>
			),
			sortable: true,
			maxWidth: '250px',
		},
		/*{
		name: 'Beschreibung',
		id: 'orderdescription',
		selector: (row) => <OrderDescription description={row.desc} />,
		sortable: true,
		maxWidth: '250px',
	},*/
		{
			name: 'Zuweisungen',
			id: 'assignments',
			selector: (row) => <OrderAssignmentPlates data={row} hideBuilder={false} />,
			sortable: false,
			maxWidth: '100px',
		},
		{
			name: 'Status',
			id: 'orderstatus',
			selector: (row) => <OrderStatus data={row} />,
			sortable: true,
			maxWidth: '150px',
		},
		{
			name: 'Aktionen',
			id: 'actions',
			allowOverflow: true,
			selector: (row) => <OrderActions order={row} />,
			sortable: false,
			maxWidth: '150px',
		},
	];

	useEffect(() => {
		if (status === 'idle') {
			reloadBuilderOrders();
		}
		dispatch(setSelectedTechnician(null));
		dispatch(setOrder(null));
	}, []);

	useEffect(() => {
		reloadBuilderOrders();
	}, [page, perPage, sortBy, sortOrder, filter]);

	const reloadBuilderOrders = () => {
		setLoading(true);
		dispatch(fetchBuilderOrders({ axios, page, perPage, sortBy, sortOrder, filter })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}
			setLoading(false);
		});
	};

	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition component='div' classNames='TabsAnimation' appear={true} timeout={1500} enter={false} exit={false}>
					<div>
						{/* Benötigt ein gemeinsames ELement um die Transition ausführen zu können */}

						<PageTitle
							heading='Aufträge'
							subheading='Alle für das Webportal freigeschalteten Aufträge'
							icon='lnr-construction icon-gradient bg-tempting-azure'
						/>
						<Row>
							<Col md='12'>
								<Card className='main-card mb-3'>
									<CardBody>
										<OrderFilter />
										<DataTable
											data={orders}
											columns={columns}
											responsive
											sortServer
											pagination
											paginationServer
											paginationPerPage={perPage}
											paginationTotalRows={total}
											paginationDefaultPage={page}
											paginationComponentOptions={{
												rowsPerPageText: 'Zeilen pro Seite',
												rangeSeparatorText: 'von',
											}}
											onSort={(selectedColumn, sortDirection) => {
												dispatch(setSortByAndSortOrder(selectedColumn.id, sortDirection));
											}}
											onChangeRowsPerPage={(e) => dispatch(setPerPage(e))}
											onChangePage={(e) => dispatch(setPage(e))}
											noDataComponent={<NoDataComponent />}
											fixedHeader
											fixedHeaderScrollHeight='58vh'
											progressPending={loading}
											progressComponent={<LoadingDataComponent />}
										/>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};
