import React, { useState } from 'react';
import { Button, ButtonGroup, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFilter, setFilter } from '../../../reducers/OrderOverviewSlice';
import SearchBox from '../../../Layout/AppHeader/Components/SearchBox';

const OrderFilter = (type) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);

	const [typeFilter, setTypeFilter] = useState(filter.type);
	const [search, setSearch] = useState(filter.search);

	const onRadioBtnClick = (selected) => {
		setTypeFilter(selected);
		dispatch(setFilter(selected, search));
	};

	return (
		<div style={{ position: 'relative', marginBottom: '.5rem' }}>
			<Row>
				<Col xs={12} xl={6} style={{ marginBottom: '.5rem' }}>
					<SearchBox
						initialValue={search}
						search={(search) => {
							setSearch(search);
							dispatch(setFilter(filter.type, search));
						}}
					/>
				</Col>
				<Col xs={12} xl={6} className='text-end' style={{ marginBottom: '.5rem', overflowX: 'auto' }}>
					<ButtonGroup size='sm' className='mb-2'>
						<Button outline color='primary' onClick={() => onRadioBtnClick('started')} active={typeFilter === 'started'}>
							In Arbeit
						</Button>
						<Button outline color='primary' onClick={() => onRadioBtnClick('open')} active={typeFilter === 'open'}>
							Offene
						</Button>
						<Button outline color='primary' onClick={() => onRadioBtnClick('doneDT')} active={typeFilter === 'doneDT'}>
							Fertig lt. Techniker
						</Button>
						<Button outline color='primary' onClick={() => onRadioBtnClick('done')} active={typeFilter === 'done'}>
							Abgeschlossen
						</Button>
						<Button outline color='primary' onClick={() => onRadioBtnClick('all')} active={typeFilter === 'all'}>
							Alle
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
		</div>
	);
};

export default OrderFilter;
