import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_LOGIN_CHECK_ROUTE, API_REGISTRATION_INFORMATION_ROUTE, API_REGISTRATION_ROUTE } from '../Components/API/endpoints';

export const sendLoginCredentials = createAsyncThunk('login/handleLogin', async (arg, { rejectWithValue }) => {
	try {
		const response = await axios.post(API_LOGIN_CHECK_ROUTE, arg, { withCredentials: true });
		return response;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});

export const fetchRegistrationInformation = createAsyncThunk('fetch/registrationInformation', async (arg, { rejectWithValue }) => {
	try {
		const data = { code: arg.code };
		const response = await axios.post(API_REGISTRATION_INFORMATION_ROUTE, data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});

export const benutzerRegistrieren = createAsyncThunk('register/benutzerRegistrieren', async (arg, { rejectWithValue }) => {
	try {
		const data = { authcode: arg.code, benutzername: arg.benutzername, passwort: arg.passwort, pin: arg.pin };
		const response = await axios.post(API_REGISTRATION_ROUTE, data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});
