import React, { Fragment } from 'react';
import { useDispatch, useStore } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { setEnableMobileMenu } from '../../../reducers/ThemeOptions';
import { AdminDashboardMenu, LogoutMenu, TechnicianDashboardMenu, BuilderDashboardMenu, ExternalTechnicianDashboardMenu } from './MenuItems';
import { useNavigate } from 'react-router-dom';
import useAuth from '../Hooks/useAuth';

const NavigationBarNew = () => {
	const dispatch = useDispatch();
	const store = useStore();
	const mobileMenu = store.getState().ThemeOptions.enableMobileMenu;
	const { auth } = useAuth();
	const navigate = useNavigate();

	const toggleMobileSidebar = () => {
		dispatch(setEnableMobileMenu(!mobileMenu));
	};

	const externalTechnicianMenu = () => {
		return (
			<Fragment>
				<h5 className='app-sidebar__heading'>Menü</h5>
				<MetisMenu
					content={ExternalTechnicianDashboardMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
				<h5 className='app-sidebar__heading'>Logout</h5>
				<MetisMenu
					content={LogoutMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
			</Fragment>
		);
	};

	const technicianMenu = () => {
		return (
			<Fragment>
				<h5 className='app-sidebar__heading'>Menü</h5>
				<MetisMenu
					content={TechnicianDashboardMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
				<h5 className='app-sidebar__heading'>Logout</h5>
				<MetisMenu
					content={LogoutMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
			</Fragment>
		);
	};

	const adminMenu = () => {
		return (
			<Fragment>
				<h5 className='app-sidebar__heading'>Menü</h5>
				<MetisMenu
					content={AdminDashboardMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
				<h5 className='app-sidebar__heading'>Logout</h5>
				<MetisMenu
					content={LogoutMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
			</Fragment>
		);
	};

	const builderMenu = () => {
		return (
			<Fragment>
				<h5 className='app-sidebar__heading'>Menü</h5>
				<MetisMenu
					content={BuilderDashboardMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
				<h5 className='app-sidebar__heading'>Logout</h5>
				<MetisMenu
					content={LogoutMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
			</Fragment>
		);
	};

	const logoutOnlyMenu = () => {
		return (
			<Fragment>
				<h5 className='app-sidebar__heading'>Logout</h5>
				<MetisMenu
					content={LogoutMenu}
					onSelected={toggleMobileSidebar}
					activeLinkFromLocation
					className='vertical-nav-menu'
					iconNamePrefix=''
					classNameStateIcon='pe-7s-angle-down'
				/>
			</Fragment>
		);
	};

	const accessToken = !auth?.accessToken ? null : auth?.accessToken;

	if (!accessToken) {
		//navigate('/login');
		window.location.href = '/';
		return logoutOnlyMenu();
	} else {
		const split = !!accessToken ? accessToken.split('.')[1] : '';
		const buffer = !!accessToken ? Buffer.from(split, 'base64') : null;
		const parsed = !!accessToken ? JSON.parse(buffer) : null;

		switch (parsed.roles) {
			case '101':
				return adminMenu();
			case '201':
				return technicianMenu();
			case '202':
				return externalTechnicianMenu();
			case '301':
				return builderMenu();
			case '302':
				return builderMenu();
			default:
				return logoutOnlyMenu();
		}
	}
};

export default NavigationBarNew;
