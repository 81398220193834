import './Message.scss';
import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteMessageForUser } from '../../../reducers/MessagesSlice';
import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { toast, Bounce } from 'react-toastify';

const Message = (props) => {
	const { message, reloadTrigger } = props;
	const [showMessage, setShowMessage] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const dispatch = useDispatch();
	const axios = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();

	const toggleMessage = () => {
		setShowMessage(!showMessage);
	};

	const toggleDelete = () => {
		setShowDelete(!showDelete);
	};

	const deleteMessage = () => {
		dispatch(deleteMessageForUser({ axios, id: message.id })).then((response) => {
			if (response?.error?.message.indexOf('401') >= 0) {
				navigate('/login', { state: { from: location }, replace: true });
			}

			if (response.payload.status === 1) {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'success',
				});
				reloadTrigger();
			} else {
				toast(response.payload.message, {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	const className = `message message__wrapper message__wrapper--${message.darstellung}`;
	const icon = showMessage ? 'lnr-chevron-up' : 'lnr-chevron-down';

	return (
		<div className={className}>
			<div className={`message__content`}>
				<div>
					<div className={`message__title`}>{message.titel}</div>
					<div className={`message__date`}>{message.erzeugtAm}</div>
				</div>
				<div className='message__actions'>
					<i className={`${icon}`} style={{ fontSize: '16px' }} onClick={toggleMessage}></i>
					<span className='ms-3 me-3'>|</span>

					<i className='lnr-trash' style={{ fontSize: '16px' }} onClick={toggleDelete}></i>
				</div>
			</div>
			{showMessage && <div>{message.nachricht}</div>}

			<SweetAlert
				warning
				showCancel
				showCloseButton
				confirmBtnBsStyle='danger'
				cancelBtnBsStyle='default'
				title={'Soll die Nachricht wirklich gelöscht werden?'}
				show={showDelete}
				onConfirm={() => deleteMessage()}
				onCancel={() => {
					setShowDelete(false);
				}}
				confirmBtnText='Ja, löschen!'
				cancelBtnText='Nein'
				focusCancelBtn
			/>
		</div>
	);
};

export default Message;

/*
<div>
					{message.nachricht.split('\n').map((line, index) => (
						<React.Fragment key={index}>
							{line}
							<br />
						</React.Fragment>
					))}
				</div>
				*/
