import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';

const HeaderBacklink = (props) => {
	const { backlinkTooltipContent, backlinkAction, backlinkTarget } = props;
	const navigate = useNavigate();

	return (
		<Fragment>
			<Button
				id='Tooltip-backlink'
				className='mb-2 me-2 pt-1 pb-2 btn-icon-vertical btn-transition back-button'
				size='sm'
				color='alternate'
				onClick={(e) => {
					if (!!backlinkAction) {
						backlinkAction();
					}

					if (!!backlinkTarget) {
						navigate(backlinkTarget);
					}
				}}
			>
				<i className='pe-7s-back btn-icon-wrapper'> </i>
				<span>Zurück</span>
			</Button>

			<UncontrolledTooltip placement='left' target={'Tooltip-backlink'}>
				{backlinkTooltipContent}
			</UncontrolledTooltip>
		</Fragment>
	);
};

export default HeaderBacklink;
