import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	API_TECHNICIAN_STORAGE_ITEM_BOOKING,
	API_FILTERED_STORAGE_ITEMS_FOR_ORDER,
	API_TECHNICIAN_STORAGE,
	API_TECHNICIAN_STORAGE_ITEM_DETAILS,
	API_TECHNICIAN_STORE_STORAGE_ITEM_BOOKINGS,
	API_TECHNICIAN_BOOKED_STORAGE_ITEM_BOOKINGS,
	API_TECHNICIAN_BOOK_STORAGE_ITEMS_ON_ORDER,
	API_TECHNICIAN_STORAGE_CREATE_TRANSFER_RECEIPT,
	API_TECHNICIAN_RETURN_STORAGE_BOOKING_INFORMATIONS,
	API_TECHNICIAN_STORAGE_CREATE_TRANSFER_RECEIPT_AND_SEND_VIA_EMAIL,
} from '../App/Components/API/endpoints';

const initialState = {
	total: 0,
	item: null,
	filter: { search: '' },
	page: 1,
	perPage: 10,
	entries: [],
	status: 'idle', // idle | loading | succeeded | failed
	error: null,
	sortBy: 'name',
	sortOrder: 'ASC',
};

export const createTransferReceipt = createAsyncThunk('storage/createTransferReceipt', async (arg) => {
	const axios = arg.axios;
	const response = await axios({
		url: API_TECHNICIAN_STORAGE_CREATE_TRANSFER_RECEIPT,
		method: 'POST',
		responseType: 'blob', // important
		data: arg.data,
	});
	return response;
});

export const createTransferReceiptAndSend = createAsyncThunk('storage/createTransferReceiptAndSend', async (arg) => {
	const axios = arg.axios;
	const response = await axios({
		url: API_TECHNICIAN_STORAGE_CREATE_TRANSFER_RECEIPT_AND_SEND_VIA_EMAIL,
		method: 'POST',
		data: arg.data,
	});
	return response;
});

export const fetchStorageReturnBookingInformations = createAsyncThunk('storage/fetchStorageReturnBookingInformations', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_TECHNICIAN_RETURN_STORAGE_BOOKING_INFORMATIONS);
	return response;
});

export const fetchTechnicianStorageItemBookingList = createAsyncThunk('orders/fetchTechnicianStorageItemBookingList', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_TECHNICIAN_STORAGE_ITEM_BOOKING, arg.data);
	return response;
});

export const storeTechnicianStorageItemBookingsFromList = createAsyncThunk('orders/storeTechnicianStorageItemBookingsFromList', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_TECHNICIAN_STORE_STORAGE_ITEM_BOOKINGS, arg.data);
	return response;
});

export const technicianStorageItemBookingsForOrder = createAsyncThunk('orders/technicianStorageItemBookingsForOrder', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_TECHNICIAN_BOOKED_STORAGE_ITEM_BOOKINGS, arg.data);
	return response;
});

export const technicianBookStorageItemListForOrder = createAsyncThunk('orders/technicianBookStorageItemListForOrder', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_TECHNICIAN_BOOK_STORAGE_ITEMS_ON_ORDER, arg.data);
	return response;
});

export const fetchTechnicianStorage = createAsyncThunk('orders/fetchTechnicianStorage', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&search=${arg.filter.search}`;
	const response = await axios.get(API_TECHNICIAN_STORAGE + params);
	return response.data;
});

export const fetchFilteredStorageItemsForOrder = createAsyncThunk('orders/fetchFilteredStorageItemsForOrder', async (arg) => {
	const axios = arg.axios;
	const params = `?search=${arg.search}${arg.av ? '&av=1' : ''}`;
	const response = await axios.post(API_FILTERED_STORAGE_ITEMS_FOR_ORDER + params);
	return response.data;
});

export const fetchStorageItemDetails = createAsyncThunk('orders/fetchStorageItemDetails', async (arg) => {
	const axios = arg.axios;
	const artikelID = arg.item;
	const lagerID = arg.storage;
	const params = '/' + artikelID + '/' + lagerID;
	const response = await axios.get(API_TECHNICIAN_STORAGE_ITEM_DETAILS + params);
	return response.data;
});

export const TechnicianStorageSlice = createSlice({
	name: 'technicianStorage',
	initialState,
	reducers: {
		setItem: {
			reducer(state, action) {
				state.item = action.payload.item;
			},
			prepare(item) {
				return {
					payload: {
						item: item,
					},
				};
			},
		},
		setEntries: {
			reducer(state, action) {
				state.entries = action.payload.entries;
			},
			prepare(entries) {
				return {
					payload: {
						entries: entries,
					},
				};
			},
		},
		setPerPage: {
			reducer(state, action) {
				state.perPage = action.payload.perPage;
			},
			prepare(perPage) {
				return {
					payload: {
						perPage,
					},
				};
			},
		},
		setTotal: {
			reducer(state, action) {
				state.total = action.payload.total;
			},
			prepare(total) {
				return {
					payload: {
						total,
					},
				};
			},
		},
		setPage: {
			reducer(state, action) {
				state.page = action.payload.page;
			},
			prepare(page) {
				return {
					payload: {
						page,
					},
				};
			},
		},
		setFilter: {
			reducer(state, action) {
				state.filter = { type: action.payload.type, search: action.payload.search };
			},
			prepare(type, search) {
				return {
					payload: {
						type,
						search,
					},
				};
			},
		},
		setSortByAndSortOrder: {
			reducer(state, action) {
				state.sortBy = action.payload.sortBy;
				state.sortOrder = action.payload.sortOrder;
			},
			prepare(sortBy, sortOrder) {
				return {
					payload: {
						sortBy,
						sortOrder,
					},
				};
			},
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchTechnicianStorage.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchTechnicianStorage.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchTechnicianStorage.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { setEntries, setPerPage, setPage, setTotal, setFilter, setSortByAndSortOrder, setItem } = TechnicianStorageSlice.actions;

export default TechnicianStorageSlice.reducer;

export const getSelectedItem = (state) => state.technicianStorage.item;
export const getPage = (state) => state.technicianStorage.page;
export const getPerPage = (state) => state.technicianStorage.perPage;
export const getTotal = (state) => state.technicianStorage.total;
export const getStorageItems = (state) => state.technicianStorage.entries;
export const getStorageStatus = (state) => state.technicianStorage.status;
export const getStorageError = (state) => state.technicianStorage.error;
export const getSortColumn = (state) => state.technicianStorage.sortBy;
export const getSortOrder = (state) => state.technicianStorage.sortOrder;
export const getFilter = (state) => state.technicianStorage.filter;
