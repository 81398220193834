import React, { Fragment } from 'react';
import { Alert, Card, CardHeader, CardBody } from 'reactstrap';

const OrderMandantCard = (props) => {
	const { mandant } = props;
	return (
		<Card className='main-card mb-3'>
			<CardHeader>Mandant</CardHeader>
			<CardBody>
				{!!mandant ? (
					<Fragment>
						<p>
							<strong>
								{mandant.name} ({mandant.kuerzel})
							</strong>
						</p>
						<p>
							{mandant.strasse}
							<br />
							{mandant.plz} {mandant.ort}
						</p>
						<p>
							Steuernummer: <span className='text-primary'>{mandant.steuernummer}</span>
						</p>
					</Fragment>
				) : (
					<Alert color='info'>Keine Mandanteninformationen verfügbar</Alert>
				)}
			</CardBody>
		</Card>
	);
};

export default OrderMandantCard;
