import './PieComponent.scss';
import React, { Fragment, useState } from 'react';
import { ResponsiveSunburst } from '@nivo/sunburst';
import PieTooltipComponent from './PieTooltipComponent';
import { Button } from 'reactstrap';

const PieComponent = (props) => {
	const types = props.typen;
	const [type, setType] = useState(null);
	const [art, setArt] = useState('Aufträge');
	const [data, setData] = useState({
		name: 'nivo',
		children: !!types ? types : [],
	});
	const className = 'pieComponent';

	const flatten = (dataArray) => {
		const reduced = dataArray.reduce((acc, item) => {
			if (item.children) {
				return [...acc, item, ...flatten(item.children)];
			}

			return [...acc, item];
		}, []);
		return reduced;
	};

	const findObject = (data, name) => {
		const found = data.find((searchedName) => searchedName.name === name);
		return found;
	};

	return (
		<Fragment>
			<div className={`${className}__legend`}>
				{data.children.map((e, i) => {
					return (
						<span key={i} className={`${className}__legendItem`}>
							<span className={`${className}__legendItemColor`} style={{ backgroundColor: e.color }} />
							<span>
								{!e.typ && e.art}
								{e.typ && `${e.typ}`}
								{e.status && ` - ${e.status}`}
							</span>
						</span>
					);
				})}
			</div>
			<div className={className}>
				<ResponsiveSunburst
					data={data}
					margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
					id='name'
					value='anzahl'
					cornerRadius={2}
					transitionMode='pushIn'
					animate={true}
					motionConfig={'gentle'}
					colors={(el) => el.data.color}
					childColor={(parent, child) => {
						// @ts-expect-error
						return child.data.color;
					}}
					enableArcLabels={true}
					arcLabelsSkipAngle={3}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 1.7]],
					}}
					arcLabel={(d) => d.value}
					tooltip={(element) => {
						return <PieTooltipComponent element={element} />;
					}}
					onClick={(clickedData) => {
						const foundObject = findObject(flatten(data.children), clickedData.id);

						if (foundObject && foundObject.children) {
							setArt(clickedData.data.art);
							setType(clickedData.data.typ);
							setData(foundObject);
						}
					}}
				/>
				<div className={`${className}__innerCircle`}>
					{art && <div className={`${className}__label`}>{art}</div>}
					{type && <div className={`${className}__subLabel`}>{type}</div>}
					{art !== 'Aufträge' && (
						<div className={`${className}__action`}>
							<Button
								color='link'
								onClick={() => {
									setData({
										name: 'nivo',
										children: types,
									});
									setArt('Aufträge');
									setType(null);
								}}
							>
								Zurücksetzen
							</Button>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default PieComponent;
