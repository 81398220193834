import React from 'react';
import Loader from 'react-loaders';

const LoadingDataComponent = () => {
	const loaderStyles = { width: '160px', height: '60px' };
	const loaderTextStyles = { fontSize: '.875rem', color: '#545cd8FF' };
	return (
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
			<div className='d-flex justify-content-center align-items-center' style={loaderStyles}>
				<Loader type='line-scale' />
			</div>
			<p className='d-flex justify-content-center align-items-center' style={loaderTextStyles}>
				wird geladen...
			</p>
		</div>
	);
};

export default LoadingDataComponent;
