import './OrderDepartureCard.scss';
import React from 'react';

import { Col, Row } from 'reactstrap';
import { sortiereStundenbuchungNachDatum } from './Services';

const OrderDepartureCard = (props) => {
	const anAbfahrten = props.departures;
	const className = 'orderDepartureCard';

	return (
		<div className={`${className}`}>
			<Row>
				<Col>
					{anAbfahrten.sort(sortiereStundenbuchungNachDatum).map((a, i) => {
						return (
							<div className={`${className}__departureRow`}>
								<div className={`${className}__technician`}>{a.t}</div>
								<Row key={`t${i}`} className='mb-1'>
									<Col xs={6} sm={4} md={2} className='mb-2'>
										<div className={`${className}__label`}>Anreise am</div>
										<div className={`${className}__value`}>{a.aD}&nbsp;</div>
									</Col>
									<Col xs={6} sm={4} md={2} className='mb-2'>
										<div className={`${className}__label`}>Anreise von</div>
										<div className={`${className}__value`}>{!!a.aV || !!a.aB ? `${a.aV} - ${a.aB}` : '-'}</div>
									</Col>
									<Col xs={12} sm={4} md={2} className='mb-2'>
										<div className={`${className}__label`}>Anreise (km) </div>
										<div className={`${className}__value`}>{a.aK} km</div>
									</Col>
									<Col xs={6} sm={4} md={2} className='mb-2'>
										<div className={`${className}__label`}>Rückreise am</div>
										<div className={`${className}__value`}>{!!a.rD ? a.rD : '-'}&nbsp;</div>
									</Col>
									<Col xs={6} sm={4} md={2} className='mb-2'>
										<div className={`${className}__label`} style={{ whiteSpace: 'nowrap' }}>
											Rückreise von
										</div>
										<div className={`${className}__value`}>{!!a.rV || !!a.rB ? `${a.rV} - ${a.rB}` : '-'}</div>
									</Col>
									<Col xs={12} sm={4} md={2} className='mb-2'>
										<div className={`${className}__label`} style={{ whiteSpace: 'nowrap' }}>
											Rückreise (km){' '}
										</div>
										<div className={`${className}__value`}>{!!a.rK ? `${a.rK} km` : '-'}</div>
									</Col>
								</Row>
							</div>
						);
					})}
				</Col>
			</Row>

			{/* 
			<CardHeader style={{ backgroundColor: 'rgba(37, 111, 225, 0.13)', height: 'auto', paddingTop: '.25rem', paddingBottom: '.25rem' }}>
				<i className='header-icon lnr-car icon-gradient bg-plum-plate'> </i>
				<span className='ms-2'>Hin-/Rückreisen</span>
			</CardHeader>
			*/}
		</div>
	);
};

export default OrderDepartureCard;
